<mat-dialog-content>
  <div class="o-feature-card">
    <div class="o-feature-card__head">
      <span class="material-icons">groups</span>
      <div class="xis-title xis-reverse">
        <div class="minion--caps">Occupancy monitor</div>
        <h2 class="primer--bold type--ghost">{{ data.monitor.name }}</h2>
      </div>
    </div>
    <ul class="o-list is-compact is-light u-margin--bottom">
      <li class="o-list__item">
        <div class="o-list__item-icon">
          <span class="material-icons">groups</span>
        </div>
        <div class="o-list__item-text">Capacity</div>
        <div class="o-list__item-secondary">
          {{ data.monitor.capacity }}
        </div>
      </li>
      <ng-container>
        <li class="o-list__item" *ngIf="data.monitor.reportingStartDate">
          <div class="o-list__item-icon">
            <span class="material-icons">event</span>
          </div>
          <div class="o-list__item-text">Reporting start date</div>
          <div class="o-list__item-secondary">
            {{ data.monitor.reportingStartDate | date: 'dd/MM/yyyy' }}
          </div>
        </li>
        <li class="o-list__item" *ngIf="data.monitor.reportingEndDate">
          <div class="o-list__item-icon">
            <span class="material-icons">event</span>
          </div>
          <div class="o-list__item-text">Reporting end date</div>
          <div class="o-list__item-secondary">{{ data.monitor.reportingEndDate | date: 'dd/MM/yyyy' }}</div>
        </li>
      </ng-container>
    </ul>
    <div class="o-feature-card__action">
      <button class="o-button is-ghost" (click)="viewMonitor()">
        View monitor
        <mat-icon class="is-icon-right">arrow_forward</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="isDailyData; else weekly">
    Occupancy for the selected day is <b>{{ data.chartData.percantege | number: '1.1-2' }}%</b>. <br />
    Occupancy for the same day in the previous week was
    <b>{{ data.chartData.lastWeekPercantege | number: '1.1-2' }}%</b>.
  </div>
  <ng-template #weekly>
    Occupancy for the selected week is <b>{{ data.chartData.percantege | number: '1.1-2' }}%</b>. <br />
    Occupancy for the week befor the selected one was
    <b>{{ data.chartData.lastWeekPercantege | number: '1.1-2' }}%</b>.</ng-template
  >
  <div #chart></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Close</button>
</mat-dialog-actions>
