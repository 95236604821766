import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';

export const HomeGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const accountService = inject(AccountService);

  const defaultNavigation = accountService.userPreferances.default_navigation;

  switch (defaultNavigation) {
    case 'real-time-occupancy':
      if (accountService.isSupport) {
        router.navigate(['/real-time-occupancy']);
      }
      break;
    case 'infrastructure':
      router.navigate(['/infrastructure']);
      break;
    case 'reporting':
      router.navigate(['/reporting']);
      break;
    default:
      router.navigate(['/infrastructure']);
  }

  return true;
};
