<div class="o-modal-full-page">
  <div class="o-modal-full-page__header">
    <button (click)="close()" class="is-close">
      <mat-icon>clear</mat-icon>
    </button>
    <h2 class="is-title" *ngIf="!updateOrganisation">Add new organisation</h2>
    <h2 class="is-title" *ngIf="updateOrganisation">Edit organisation</h2>
    <button
      class="is-action"
      (click)="save()"
      [class.spinner]="saving"
      [disabled]="organisationForm.invalid || saving || (!updateOrganisation && adminForm.invalid)"
    >
      Save
    </button>
  </div>

  <div class="o-modal-full-page__body">
    <form class="main-content c-main-content" [formGroup]="organisationForm">
      <div class="u-padding--bottom">
        <div class="o-flex u-padding--bottom">
          <mat-icon class="u-grey-color">shield</mat-icon>
          <div class="o-heading--primer u-flush--bottom">
            <h3>Name</h3>
          </div>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Organisation name</mat-label>
          <input matInput type="text" placeholder="Organisation name" autocomplete="off" formControlName="name" />
        </mat-form-field>
      </div>
      <div class="u-padding--bottom">
        <div class="o-flex u-padding--bottom">
          <mat-icon class="u-grey-color">remove_moderator</mat-icon>
          <div class="o-heading--primer u-flush--bottom">
            <h3>Status</h3>
          </div>
        </div>
        <div class="c-states">
          <mat-radio-group formControlName="state">
            @for (state of states; track state) {
              <mat-radio-button class="c-states__state" [value]="state.value"
                >{{ state.description }}
              </mat-radio-button>
            }
          </mat-radio-group>
        </div>
      </div>
      <div class="u-padding--bottom">
        <div class="o-flex u-padding--bottom">
          <mat-icon class="u-grey-color">schedule_send</mat-icon>
          <div class="o-heading--primer u-flush--bottom">
            <h3>Processing Group</h3>
          </div>
        </div>
        <div class="c-processingGroups">
          <mat-radio-group formControlName="processingGroup">
            @for (group of processingGroups; track group) {
              <mat-radio-button class="c-processingGroups__group" [value]="group.value"
                >{{ group.description }}
              </mat-radio-button>
            }
          </mat-radio-group>
        </div>
      </div>
      <div class="u-padding--bottom" [ngClass]="{ 'u-border--bottom': !updateOrganisation }">
        <div class="o-flex u-padding--bottom u-padding--top">
          <mat-icon class="u-grey-color">confirmation_number</mat-icon>
          <div class="o-heading--primer u-flush--bottom">
            <h3>License</h3>
          </div>
        </div>
        <div class="c-licenses">
          <mat-checkbox
            *ngFor="let license of licenses"
            class="c-licenses__license"
            [checked]="organisationForm.get('licence').value.includes(license.value)"
            [value]="license.value"
            [disabled]="license.value === 'people_count'"
            (change)="onCheckChangeLicence($event)"
            >{{ license.description }}
          </mat-checkbox>
        </div>
      </div>

      <div class="u-padding--bottom" [ngClass]="{ 'u-border--bottom u-margin--bottom': !updateOrganisation }">
        <div>
          <div class="o-flex u-padding--bottom">
            <mat-icon class="u-grey-color">recent_actors</mat-icon>
            <div class="o-heading--primer u-flush--bottom">
              <h3>Total user seats</h3>
              <h5>Set the total user limit of members and admins</h5>
            </div>
          </div>
          <mat-form-field class="c-user-limit-input u-padding--bottom" appearance="outline">
            <mat-label>User limit</mat-label>
            <input matInput type="number" placeholder="User limit" autocomplete="off" formControlName="maxUserSeats" />
          </mat-form-field>
        </div>
        <div>
          <div class="o-flex u-padding--bottom u-padding--top c-total-user-text">
            <mat-icon class="u-grey-color">admin_panel_settings</mat-icon>
            <div class="o-heading--primer u-flush--bottom">
              <h3>Total admin seats</h3>
              <h5>Admin user allowance within the total user limit</h5>
            </div>
          </div>
          <mat-form-field class="c-user-limit-input u-padding--bottom" appearance="outline">
            <mat-label>Admin limit</mat-label>
            <input
              matInput
              type="number"
              placeholder="Admin limit"
              autocomplete="off"
              formControlName="maxAdminSeats"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="u-padding--bottom" *ngIf="updateOrganisation">
        <div class="o-flex u-padding--bottom">
          <mat-icon class="u-grey-color">fingerprint</mat-icon>
          <div class="o-heading--primer u-flush--bottom">
            <h3>ID</h3>
          </div>
        </div>
        <mat-form-field appearance="outline">
          <input
            matInput
            type="text"
            placeholder="Organisation name"
            autocomplete="off"
            [value]="organisation.id"
            [disabled]="true"
          />
          <mat-icon class="copy-icon" matSuffix [cdkCopyToClipboard]="organisation.id">content_copy</mat-icon>
        </mat-form-field>
      </div>

      <div class="u-padding--bottom" *ngFor="let oAuthClientLink of oAuthClientLinks">
        <div class="o-flex u-padding--bottom">
          <mat-icon class="u-grey-color">fingerprint</mat-icon>
          <div class="o-heading--primer u-flush--bottom">
            <h3>OAuth ClientID</h3>
          </div>
        </div>
        <mat-form-field appearance="outline">
          <input matInput type="text" autocomplete="off" [value]="oAuthClientLink.clientId" [disabled]="true" />
          <mat-icon class="copy-icon" matSuffix [cdkCopyToClipboard]="oAuthClientLink.clientId">content_copy</mat-icon>
        </mat-form-field>
      </div>
    </form>
    <ng-container *ngIf="!updateOrganisation">
      <form class="main-content" [formGroup]="adminForm">
        <div class="o-flex u-padding--bottom">
          <mat-icon class="u-grey-color">admin_panel_settings</mat-icon>
          <div class="o-heading--primer u-flush--bottom">
            <h3>Admin user</h3>
          </div>
        </div>
        <mat-form-field class="u-padding--bottom" appearance="outline">
          <mat-label>First name</mat-label>
          <input matInput type="text" placeholder="First name" autocomplete="off" formControlName="firstName" />
        </mat-form-field>
        <mat-form-field class="u-padding--bottom" appearance="outline">
          <mat-label>Last name</mat-label>
          <input matInput type="text" placeholder="Last name" autocomplete="off" formControlName="lastName" />
        </mat-form-field>
        <mat-form-field class="u-padding--bottom" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput type="text" placeholder="Email" autocomplete="off" formControlName="email" />
        </mat-form-field>
      </form>
    </ng-container>
  </div>
</div>
