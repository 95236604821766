<div class="o-modal-full-page has-no-header o-dialog-page">
  <div class="auth-overlay" *ngIf="reportsService.isLoadingPeopleCountOrderData | async; else notLoading">
    <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
      <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>

  <ng-template #notLoading>
    <div class="o-modal-full-page__body u-padding--top" #monitorCardsList (scroll)="onMonitorCardsListScroll()">
      <div class="c-modal-content">
        <div class="c-people-count-monitors-count">
          <div class="c-text-select-people-count">Select people count monitors to view</div>
          <div class="c-people-count-controls">
            <app-filter
              (search)="filterMonitors($event)"
              class="o-search__input c-search-people-count"
              placeholder="Filter by monitor or site names"
            ></app-filter>

            <div class="c-people-count-controls__right">
              <mat-checkbox [(ngModel)]="reportsService.useOpeningHoursPeopleCountMonitors"
                >Filter by opening hours</mat-checkbox
              >
              <mat-checkbox
                class="o-button is-muted is-contained c-button-clear"
                (click)="$event.stopPropagation()"
                (change)="masterToggle()"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
                <div class="c-text-monitor-name">Select All</div>
              </mat-checkbox>
              <button class="o-button is-muted is-contained c-button-clear" (click)="selection.clear()">
                <span class="material-icons is-icon-left"> close </span>
                CLEAR SELECTED
              </button>
            </div>
          </div>

          <div
            class="o-scroll-wrap"
            [ngClass]="{ 'has-left-scroll': showLeftScroll, 'has-right-scroll': showRightScroll }"
            *ngIf="filteredPeopleCountMonitorCards.length"
          >
            <div class="scroll-left scroll-icon" *ngIf="showLeftScroll" (click)="scroll('left')">
              <mat-icon>arrow_back</mat-icon>
            </div>
            <div class="c-monitor-details-cards" #monitorDetailsCardsList (scroll)="onMonitorDetailsCardsListScroll()">
              <app-monitor-details-card
                *ngFor="let peopleCountMonitor of filteredPeopleCountMonitorCards"
                [site]="reportsService.sitesMap[peopleCountMonitor.siteId]"
                [peopleCountMonitor]="peopleCountMonitor"
                [isPeopleCountDaily]="reportsService.isPeopleCountDaily"
                [dataStartDate]="
                  reportsService.isPeopleCountDaily
                    ? reportsService.peopleCountMonitorDataDailyTime
                    : reportsService.peopleCountMonitorDataWeeklyTime
                "
                [useOpeningHours]="reportsService.useOpeningHoursPeopleCountMonitors"
                [selected]="selection.isSelected(peopleCountMonitor.id)"
                (changeSelected)="selection.toggle(peopleCountMonitor.id)"
              ></app-monitor-details-card>
            </div>
            <div class="scroll-right scroll-icon" *ngIf="showRightScroll" (click)="scroll('right')">
              <mat-icon>arrow_forward</mat-icon>
            </div>
          </div>

          <div class="c-people-count-plots">
            <div class="c-people-count-plots-header">
              <div class="c-plots-header-description">
                <div class="c-text-people-count">People count</div>
                <div class="c-text-people-count-date">
                  {{
                    (reportsService.isPeopleCountDaily
                      ? reportsService.peopleCountMonitorDataDailyTime
                      : reportsService.peopleCountMonitorDataWeeklyTime
                    ) | date: 'EE dd MMM y'
                  }}
                  <ng-container *ngIf="!reportsService.isPeopleCountDaily">
                    -
                    {{ reportsService.peopleCountMonitorDataWeeklyTime.getTime() + 6.048e8 | date: 'EE dd MMM y' }}
                  </ng-container>
                </div>
              </div>
              <div class="c-plots-controls">
                <div class="c-plots-legends">
                  <div class="c-plots-legend">Selected week</div>
                  <div class="c-plots-legend">Previous week</div>
                </div>
                <div class="c-plots-time-options o-button-flush-group">
                  <button
                    (click)="dailyTimePicker.open()"
                    class="o-button is-small"
                    [ngClass]="reportsService.isPeopleCountDaily ? 'is-primary' : 'is-muted is-contained'"
                  >
                    <span class="material-icons is-icon-left" *ngIf="reportsService.isPeopleCountDaily"> event </span>
                    <div class="o-button__date-label">
                      Daily
                      <span *ngIf="reportsService.isPeopleCountDaily">
                        {{ reportsService.peopleCountMonitorDataDailyTime | date: 'dd/MM/yy' }}</span
                      >
                    </div>
                  </button>
                  <input
                    class="c-hide"
                    matInput
                    [(ngModel)]="reportsService.peopleCountMonitorDataDailyTime"
                    (dateChange)="toggleTimingPeopleCount(true)"
                    [matDatepicker]="dailyTimePicker"
                  />
                  <mat-datepicker #dailyTimePicker></mat-datepicker>
                  <button
                    (click)="weeklyTimePicker.open()"
                    class="o-button is-small"
                    [ngClass]="!reportsService.isPeopleCountDaily ? 'is-primary' : 'is-muted is-contained'"
                  >
                    <span class="material-icons is-icon-left" *ngIf="!reportsService.isPeopleCountDaily"> event </span>
                    <div class="o-button__date-label">
                      Weekly

                      <span *ngIf="!reportsService.isPeopleCountDaily">{{
                        reportsService.peopleCountMonitorDataWeeklyTime | date: 'dd/MM/yy'
                      }}</span>
                    </div>
                  </button>
                  <mat-date-range-input class="c-hide" [rangePicker]="weeklyTimePicker">
                    <input
                      matStartDate
                      [(ngModel)]="reportsService.peopleCountMonitorDataWeeklyTime"
                      (dateChange)="updatePeopleCountMonitorDataWeeklyTime(); weeklyTimePicker.close()"
                    />
                    <input
                      matEndDate
                      [(ngModel)]="peopleCountMonitorDataWeeklyTimeEndDate"
                      (dateChange)="updatePeopleCountMonitorDataWeeklyTime(); weeklyTimePicker.close()"
                    />
                  </mat-date-range-input>
                  <mat-date-range-picker #weeklyTimePicker [restoreFocus]="true"></mat-date-range-picker>
                </div>
              </div>
            </div>
            <div>
              <app-people-count-monitor-card
                *ngFor="let peopleCountMonitor of filteredPeopleCountMonitorCharts"
                [site]="reportsService.sitesMap[peopleCountMonitor.siteId]"
                [peopleCountMonitor]="peopleCountMonitor"
                [useOpeningHours]="reportsService.useOpeningHoursPeopleCountMonitors"
                [dataStartDate]="
                  reportsService.isPeopleCountDaily
                    ? reportsService.peopleCountMonitorDataDailyTime
                    : reportsService.peopleCountMonitorDataWeeklyTime
                "
              ></app-people-count-monitor-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div class="c-download">
  <a
    [routerLink]="['/reporting', 'report-csv-download']"
    [queryParams]="{
      type: 'people_count',
      ids: getSelectedIds(),
    }"
  >
    <button class="o-button is-primary is-small">
      <mat-icon class="is-icon-left">file_download</mat-icon>
      Download
    </button>
  </a>
</div>
