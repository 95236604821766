<div class="c-maps-container">
  <div class="c-map-control">
    <div class="c-map-control__time-control">
      <!-- day select -->
      <span class="is-time-picker">
        <button
          (click)="dailyTimePicker.open()"
          class="o-button is-small"
          [ngClass]="isDaily ? 'is-primary' : 'is-muted is-contained'"
        >
          <span class="material-icons is-icon-left" *ngIf="isDaily"> event </span>
          <div class="o-button__date-label">
            Daily
            <span *ngIf="isDaily"> {{ selectedDay | date: 'dd/MM/yy' }}</span>
            {{ selectedDateIsToday ? '- LIVE' : '' }}
          </div>
        </button>
        <input
          class="c-hide"
          matInput
          [(ngModel)]="selectedDay"
          (dateChange)="selectDay()"
          [matDatepicker]="dailyTimePicker"
        />
        <mat-datepicker #dailyTimePicker></mat-datepicker>
      </span>
      <!-- week select -->
      <span class="is-time-picker">
        <button
          (click)="weeklyTimePicker.open()"
          class="o-button is-small"
          [ngClass]="!isDaily ? 'is-primary' : 'is-muted is-contained'"
        >
          <span class="material-icons is-icon-left" *ngIf="!isDaily"> event </span>
          <div class="o-button__date-label">
            Weekly
            <span class="c-text-date" *ngIf="!isDaily">{{ selectedWeek | date: 'dd/MM/yy' }}</span>
          </div>
        </button>
        <mat-date-range-input class="c-hide" [rangePicker]="weeklyTimePicker">
          <input matStartDate [(ngModel)]="selectedWeek" (dateChange)="selectWeek(); weeklyTimePicker.close()" />
          <input matEndDate [(ngModel)]="selectedWeekEnd" (dateChange)="selectWeek(); weeklyTimePicker.close()" />
        </mat-date-range-input>
        <mat-date-range-picker #weeklyTimePicker [restoreFocus]="true"></mat-date-range-picker>
      </span>
      <mat-checkbox [(ngModel)]="useOpeningHours">Filter by opening hours</mat-checkbox>
    </div>
  </div>
  <div class="c-map-view" id="app" #app></div>
</div>
