<div class="auth-overlay" *ngIf="isLoading$ | async; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading cameras...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <nav class="navigation is-breadcrumb">
    <ul class="o-breadcrumb">
      <li class="o-breadcrumb__item">
        <a routerLink="/infrastructure" class="is-link"> <mat-icon class="is-icon">dashboard</mat-icon></a>
      </li>
      <ng-container *ngIf="cameraDetailsName; else noChildRoute">
        <li class="o-breadcrumb__item">
          <a [routerLink]="['/cameras']" class="is-link">Cameras</a>
        </li>
        <li class="o-breadcrumb__item">{{ cameraDetailsName }}</li>
      </ng-container>
      <ng-template #noChildRoute>
        <li class="o-breadcrumb__item">Cameras</li>
      </ng-template>
    </ul>
  </nav>
  <div class="o-view--2col">
    <div class="o-router-full-page-wrapper" *ngIf="(isLoading$ | async) === false">
      <router-outlet></router-outlet>
    </div>

    <div class="is-title" *ngIf="!showSideMenu">
      <div class="c-cameras__title">
        <span class="c-cameras__nav-toggle">
          <mat-icon (click)="openSideMenu()">dehaze</mat-icon>
        </span>
      </div>
    </div>
    <div class="is-aside c-cameras__side-menu" [ngClass]="{ 'has-left-menu': showSideMenu }" *ngIf="showSideMenu">
      <div class="o-heading--pica is-primary-heading">
        <h1>Registered Cameras</h1>
      </div>
      <div class="c-cameras__side-menu-options">
        <app-cameras-side-menu
          [incompletCameras]="incompletCameras"
          [issuesCameras]="issuesCameras"
          [sitesMap]="sitesMap"
          [issuesCamerasMonitors]="issuesCamerasMonitors"
          (setupCamera)="openCameraSetupPopup()"
          (continueSetup)="openCameraSetupPopup($event)"
        ></app-cameras-side-menu>
      </div>
    </div>
    <div class="is-main c-cameras__main-menu">
      <div class="c-camera-header-wrapper">
        <!--div class="c-cameras__header"-->
        <div class="o-heading--pica xis-primary-heading xc-heading is-title">
          <h2 class="c-title-cameras">Cameras</h2>
          <h3>{{ this.filteredCameras.length }} cameras</h3>
        </div>
        <!--/div-->
        <!--div class="c-cameras__filter"-->
        <div class="is-filter-full">
          <app-filter
            [searchString]="searchString"
            class="o-search__input xc-cameras-filter is-filter-full"
            (search)="filterCameras(undefined, $event)"
            placeholder="Filter Cameras"
          ></app-filter>
        </div>

        <div class="xc-control-buttons is-filter">
          <div class="is-filter-item" (click)="openCameraSetupPopup()">
            <mat-icon class="xc-icon-rounded xc-icon-add-photo xis-filter-item"> add_a_photo </mat-icon>
          </div>
          <div class="is-filter-item" (click)="openSortBy()">
            <mat-icon class="xc-icon-rounded x-icon-low-priority xs-filter-item"> low_priority </mat-icon>
          </div>
          <div class="is-filter-item" (click)="openFilter()">
            <mat-icon class="xc-icon-rounded xc-icon-filter-list xis-filter-item"> filter_list </mat-icon>
          </div>
        </div>

        <div class="xdisplay-contents is-sites">
          <mat-form-field class="site-filter">
            <mat-select
              [(value)]="selectedSite"
              (selectionChange)="filterCameras(selectedSite)"
              placeholder="All sites"
            >
              <mat-option *ngFor="let site of filteredSites" [value]="site">
                {{ site.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="is-decom">
          <mat-checkbox *ngIf="accountService.isSupport" [(ngModel)]="showDecommissioned" (change)="filterCameras()"
            >Show decommissioned</mat-checkbox
          >
        </div>
        <!--/div-->
      </div>

      <div class="c-camera-list" *ngIf="hasCameras; else hasNoCameras">
        <app-cameras-list [keyToSort]="sortBy" [cameras]="filteredCameras" [sitesMap]="sitesMap"></app-cameras-list>
      </div>
      <ng-template #hasNoCameras>
        <h1>No cameras were registered yet</h1>
      </ng-template>
    </div>
  </div>
</ng-template>
