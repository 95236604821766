<div class="o-modal-full-page has-secondary-header o-dialog-page">
  <div
    class="auth-overlay"
    *ngIf="
      (isLoadingCamera$ | async) || (isLoadingCameraAlerts$ | async) || (isLoadingCameraMonitors$ | async);
      else notLoadingCameraDetails
    "
  >
    <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
      <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>
  <ng-template #notLoadingCameraDetails>
    <div class="c-camera-case u-padding--bottom">
      <app-camera-card [camera]="camera"></app-camera-card>
      <div class="c-camera-details">
        <div class="c-camera-details__metrics">
          <div class="c-camera-details__view">
            <div class="c-camera-details__image">
              <ng-container *ngIf="camera.state === 'running'; else noImage">
                <div class="auth-overlay ultralight-overlay" *ngIf="isLoadingFrame && !cameraFrame; else notLoadingImg">
                  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
                    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
                  </div>
                </div>
                <ng-template #notLoadingImg>
                  <ng-container *ngIf="cameraFrame; else noImage">
                    <div class="has-image" [ngClass]="{ 'is-reloading': isLoadingFrame }">
                      <img [src]="cameraFrame" alt="camera view" />
                      <ng-container *ngIf="camera.type !== 'Passing traffic'; else passTraffCrossingLine">
                        <svg height="100%" width="100%">
                          <line x1="0%" y1="50%" x2="100%" y2="50%" stroke="rgb(255,0,0)" stroke-width="2" />
                        </svg>
                      </ng-container>
                      <ng-template #passTraffCrossingLine>
                        <svg height="100%" width="100%">
                          <line x1="50%" y1="0%" x2="50%" y2="100%" stroke="rgb(255,0,0)" stroke-width="2" />
                        </svg>
                      </ng-template>
                      <button
                        class="is-action o-button refresh-image u-hard--right"
                        (click)="refreshCamera()"
                        [disabled]="isLoadingFrame"
                      >
                        <mat-icon class="is-icon-right" [class.rotate]="isLoadingFrame">refresh</mat-icon>
                        Refresh
                      </button>
                    </div>
                  </ng-container>
                </ng-template>
              </ng-container>
            </div>
            <ng-template #noImage>
              <div
                class="no-image"
                [ngClass]="{
                  'has-no-error': camera.status === 'paused' || camera.isOnline === true,
                  'is-decommissioned': camera.status === 'decommissioned',
                }"
              >
                <div class="no-view-title">
                  <mat-icon class="is-icon">{{
                    camera.status === 'decommissioned'
                      ? 'delete_forever'
                      : camera.status === 'missing'
                        ? 'videocam_off'
                        : camera.status === 'paused'
                          ? 'photo_camera'
                          : 'no_photography'
                  }}</mat-icon>
                  <div class="is-text">
                    {{
                      camera.status === 'missing' ||
                      camera.status === 'lagging' ||
                      camera.status === 'online' ||
                      camera.status === 'decommissioned'
                        ? ''
                        : camera.status === 'paused'
                          ? 'Camera paused.'
                          : 'Camera offline.'
                    }}
                    {{ camera.status === 'decommissioned' ? 'Camera decommissioned.' : 'No view available.' }}
                  </div>
                </div>
                <div class="no-image">
                  <mat-icon class="is-icon">{{
                    camera.status === 'paused' ? 'pause_circle' : 'broken_image'
                  }}</mat-icon>
                  <button
                    *ngIf="camera.state === 'running'"
                    class="o-button refresh-image is-highlight u-hard--right"
                    (click)="refreshCamera()"
                    [disabled]="isLoadingFrame"
                  >
                    <mat-icon class="is-icon-right" [class.rotate]="isLoadingFrame">refresh</mat-icon>
                    Refresh
                  </button>
                </div>
                <a
                  *ngIf="camera.status !== 'decommissioned'"
                  href=" https://docs.hoxton.ai/knowledge/camera-troubleshooting"
                  target="_blank"
                  class="o-button"
                  >Camera troubleshooting <mat-icon class="is-icon-right">arrow_forward</mat-icon></a
                >
              </div>
            </ng-template>
            <ng-container *ngIf="camera.type !== 'Passing traffic'; else passTraffDirections">
              <div class="c-camera-details__directions">
                <div class="is-direction">
                  <mat-icon class="is-icon">arrow_upward</mat-icon>
                  <span class="is-alias">{{ camera.direction1Alias }}</span>
                </div>
                <div class="is-direction">
                  <div class="c-direction-icon">
                    <mat-icon class="is-icon">arrow_downward</mat-icon>
                  </div>
                  <span class="is-alias">{{ camera.direction2Alias }}</span>
                </div>
              </div>
            </ng-container>
            <ng-template #passTraffDirections>
              <div class="c-camera-details__directions">
                <div class="is-direction">
                  <mat-icon class="is-icon">arrow_back</mat-icon>
                  <span class="is-alias">{{ camera.direction2Alias }}</span>
                </div>
                <div class="is-direction">
                  <div class="c-direction-icon">
                    <mat-icon class="is-icon">arrow_forward</mat-icon>
                  </div>
                  <span class="is-alias">{{ camera.direction1Alias }}</span>
                </div>
              </div>
            </ng-template>
            <div class="c-camera-details__actions" *ngIf="camera.status !== 'decommissioned'">
              <button class="is-action o-button has-outline" (click)="editCamera()">
                <mat-icon class="is-icon-left">edit</mat-icon>
                manage camera
              </button>
            </div>
          </div>

          <div
            class="c-camera-metrics-summary"
            [ngClass]="{
              'has-issue': camera.status === 'offline' || camera.status === 'missing' || camera.status === 'lagging',
              'is-online': camera.status === 'online',
              'is-paused': camera.status === 'paused',
            }"
          >
            <div class="c-camera-metrics-summary__real-time">
              <div class="c-camera-metrics-summary__lead">
                <mat-icon class="is-status-icon">
                  {{
                    camera.status === 'decommissioned'
                      ? 'delete_forever'
                      : camera.status === 'online'
                        ? 'verified_user'
                        : camera.status === 'paused'
                          ? 'pause_circle'
                          : 'warning'
                  }}
                </mat-icon>
                <div class="is-title">
                  <div class="primer kirk u-flush--bottom is-status-legend">
                    {{ camera.status === 'missing' ? 'missing video' : camera.status }}
                  </div>
                  <div *ngIf="camera.status !== 'paused' && camera.status !== 'decommissioned'" class="is-last-video">
                    <mat-icon class="is-icon">videocam</mat-icon>
                    <span class="brevier--caps">Last video:</span>
                    {{ camera.lastVideoAt ? (camera.lastVideoAt | dateAgo) : 'N/A' }}
                  </div>
                </div>
                <div class="is-description">
                  <span [ngSwitch]="camera.status">
                    <div *ngSwitchCase="'decommissioned'">
                      <h3 class="primer is-bold u-flush--bottom">Camera not used.</h3>
                    </div>
                    <div *ngSwitchCase="'offline'">
                      <h3 class="primer is-bold u-flush--bottom">This camera is offline and not sending any data.</h3>
                      <p class="brevier">
                        Common reasons for this are lack of power or internet connection.
                        <a href=" https://docs.hoxton.ai/knowledge/camera-troubleshooting" target="_blank">
                          See our support page for more information.
                        </a>
                      </p>
                    </div>
                    <div *ngSwitchCase="'missing'">
                      <h3 class="primer is-bold u-flush--bottom">
                        This camera is online but missing video - all traffic data will be lost.
                      </h3>
                      <p class="brevier">
                        See our
                        <a href=" https://docs.hoxton.ai/knowledge/camera-troubleshooting" target="_blank">
                          support page for more information
                        </a>
                        about troubleshooting this issue.
                      </p>
                    </div>
                    <div *ngSwitchCase="'lagging'">
                      <h3 class="primer is-bold u-flush--bottom">Video is being uploaded but at a delayed rate.</h3>
                      <p class="brevier">
                        The longer this problem persists the greater the chance of video being lost. Lagging is usually
                        caused by a poor connection.
                        <a href=" https://docs.hoxton.ai/knowledge/camera-troubleshooting" target="_blank">
                          Learn more.
                        </a>
                      </p>
                    </div>
                    <div *ngSwitchCase="'online'">
                      <h3 class="primer is-bold">This camera is online and sending video.</h3>
                    </div>
                    <div *ngSwitchCase="'paused'">
                      <h3 class="primer is-bold u-flush--bottom">Camera is paused and not collecting any data.</h3>
                      <p class="brevier">It is online and ready to be restarted.</p>
                    </div>
                  </span>
                </div>
              </div>
              <div class="c-info-section u-island u-margin--bottom">
                <div class="primer is-bold u-flush--bottom">
                  Camera is used in
                  {{ occupancyMonitors.length + peopleCountMonitors.length + peelOffMonitors.length }}
                  {{
                    occupancyMonitors.length + peopleCountMonitors.length + peelOffMonitors.length
                      | pluraliseWord: 'monitor'
                  }}.
                </div>
                <div [ngSwitch]="camera.status" class="brevier u-flush--bottom">
                  <div *ngSwitchCase="'paused'">Reactivate camera to continue collecting data.</div>
                  <div *ngSwitchCase="'online'">Providing accurate count data to each monitor.</div>
                  <div *ngSwitchDefault>Count data unavailable or inaccurate until the camera issue is resolved.</div>
                </div>
              </div>

              <div class="c-performance-metrics">
                <div class="c-performance-metrics__uploads">
                  <div class="is-upload-speed">
                    <span class="is-label brevier--caps">Current upload speed</span>
                    <span class="is-metric type--monospace">{{
                      camera.networkTransmitRate !== undefined
                        ? (camera.networkTransmitRate / 60 | formatTransferSpeed)
                        : 'N/A'
                    }}</span>
                  </div>
                  <div class="is-recommended-speed">
                    <div class="is-label brevier--caps">
                      Recommended
                      <div class="is-info" (click)="openUserConfirmation('recommended')">
                        <mat-icon class="is-icon">info</mat-icon>
                      </div>
                    </div>
                    <div class="is-min-speed">
                      <span class="is-metric type--monospace">85KB/s </span>
                      <span class="brevier type--secondary">min</span>
                    </div>
                  </div>
                </div>
                <div class="c-performance-metrics__backlog">
                  <span class="is-label brevier--caps"
                    >Video backlog size
                    <div class="is-info" (click)="openUserConfirmation('video_backlog')">
                      <mat-icon class="is-icon">info</mat-icon>
                    </div></span
                  >
                  <span class="is-metric type--monospace">{{
                    camera.videoBacklog !== undefined ? (camera.videoBacklog | timeToString) : 'N/A'
                  }}</span>
                </div>
              </div>
            </div>
            <div class="c-camera-metrics-summary__historical">
              <div
                class="auth-overlay white-overlay"
                *ngIf="isLoadingCameraHistoricalData; else notLoadingCameraHistoricalData"
              >
                <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
                  <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
                </div>
              </div>

              <ng-template #notLoadingCameraHistoricalData>
                <app-camera-performance-summary
                  [sevenDaysSummary]="sevenDaysSummary"
                  [oneMonthSummary]="oneMonthSummary"
                  [camera]="camera"
                ></app-camera-performance-summary>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="c-camera-details__charts">
          <div class="camera-performance-chart" id="camera-performance-chart-days">
            <div class="camera-performance-chart__title">Camera performance - last 7 days</div>
            <div class="auth-overlay white-overlay" *ngIf="isLoadingCameraHistoricalData">
              <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
                <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
              </div>
            </div>
            <ngx-charts-bar-vertical-stacked
              *ngIf="!isLoadingCameraHistoricalData && camera.status !== 'paused' && camera.status !== 'decommissioned'"
              [animations]="false"
              [view]="daysView"
              [scheme]="cameraPerformanceChartColorScheme"
              [results]="cameraPerformanceLastWeekData"
              [xAxis]="true"
              [yAxis]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [xAxisTickFormatting]="dayFormatting"
              [yAxisTickFormatting]="percantageormatting"
              [yAxisTicks]="[0, 25, 50, 75, 100]"
            >
              <ng-template #tooltipTemplate let-model="model">
                <span [innerHTML]="getTooltipText(cameraPerformanceLastWeekData, model.series)"></span>
              </ng-template>
            </ngx-charts-bar-vertical-stacked>
            <div *ngIf="camera.status === 'paused' || camera.status === 'decommissioned'" class="no-data">
              <img src="/assets/images/chart-placeholder.png" alt="No data" class="is-media" />
              <ng-container *ngIf="camera.status === 'paused'">Unavailable while camera is paused</ng-container>
            </div>
          </div>
          <div class="camera-performance-chart" id="camera-performance-chart-weeks">
            <div class="camera-performance-chart__title">Camera performance - last 4 weeks</div>
            <div class="auth-overlay white-overlay" *ngIf="isLoadingCameraHistoricalData">
              <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
                <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
              </div>
            </div>
            <ngx-charts-bar-vertical-stacked
              *ngIf="!isLoadingCameraHistoricalData && camera.status !== 'paused' && camera.status !== 'decommissioned'"
              [animations]="false"
              [view]="weeksView"
              [scheme]="cameraPerformanceChartColorScheme"
              [results]="cameraPerformanceLastMonthData"
              [xAxis]="true"
              [yAxis]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [xAxisTickFormatting]="weekFormatting"
              [yAxisTickFormatting]="percantageormatting"
              [yAxisTicks]="[0, 25, 50, 75, 100]"
            >
              <ng-template #tooltipTemplate let-model="model">
                <span [innerHTML]="getTooltipText(cameraPerformanceLastMonthData, model.series)"></span>
              </ng-template>
            </ngx-charts-bar-vertical-stacked>
            <div *ngIf="camera.status === 'paused' || camera.status === 'decommissioned'" class="no-data">
              <img src="/assets/images/chart-placeholder.png" alt="No data" class="is-media" />
              <ng-container *ngIf="camera.status === 'paused'">Unavailable while camera is paused</ng-container>
            </div>
          </div>
        </div>
        <div class="c-camera-details__stats">
          <div class="section">
            <div class="section__head">
              <div class="o-heading--primer u-flush--bottom">
                <h2>Spec:</h2>
                <h3>Camera details</h3>
              </div>
            </div>
            <div class="section__body">
              <ul class="o-list">
                <li class="o-list__item">
                  <div class="o-list__item-icon">
                    <mat-icon>bookmark</mat-icon>
                  </div>
                  <div class="o-list__item-text">
                    <span class="is-secondary-text">Camera ID </span>
                    <app-uuid [fullId]="camera.id"></app-uuid>
                  </div>
                </li>
                <li class="o-list__item">
                  <div class="o-list__item-icon">
                    <mat-icon>camera_rear</mat-icon>
                  </div>
                  <div class="o-list__item-text">
                    <span class="is-secondary-text">Camera type </span>
                    <span class="is-primary-text">{{ camera.type }}</span>
                  </div>
                </li>
                <li class="o-list__item">
                  <div class="o-list__item-icon">
                    <mat-icon>tag</mat-icon>
                  </div>
                  <div class="o-list__item-text">
                    <span class="is-secondary-text">Serial no.</span>
                    <span class="is-primary-text">{{ camera.serialNumber ? camera.serialNumber : '-' }}</span>
                  </div>
                </li>
                <li class="o-list__item">
                  <div class="o-list__item-icon">
                    <mat-icon>event</mat-icon>
                  </div>
                  <div class="o-list__item-text">
                    <span class="is-secondary-text">Date added </span>
                    <span class="is-primary-text">{{ camera.claimedAt | dateAgo }}</span>
                  </div>
                </li>
                <li class="o-list__item" *ngIf="isSupport">
                  <div class="o-list__item-icon">
                    <mat-icon svgIcon="balena" class="mat-icon-svg"></mat-icon>
                  </div>
                  <div class="o-list__item-text">
                    <app-balena-summary [fullId]="camera.id"></app-balena-summary>
                  </div>
                </li>
                <li class="o-list__item" *ngIf="isSupport">
                  <div class="o-list__item-icon">
                    <mat-icon svgIcon="gcp_cloud_logging" class="mat-icon-svg"></mat-icon>
                  </div>
                  <div class="o-list__item-text">
                    <app-gcp-logs [fullId]="camera.id"></app-gcp-logs>
                  </div>
                </li>
                <li class="o-list__item" *ngIf="isSupport">
                  <div class="o-list__item-icon">
                    <mat-icon svgIcon="grafana" class="mat-icon-svg"></mat-icon>
                  </div>
                  <div class="o-list__item-text">
                    <app-processing-summary [fullId]="camera.id"></app-processing-summary>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="section">
            <div class="section__head">
              <div class="o-heading--primer u-flush--bottom">
                <h2>Alerts:</h2>
                <h3>
                  This camera has
                  {{ offlineAlerts.length + summaryAlerts.length }}
                  {{ offlineAlerts.length + summaryAlerts.length | pluraliseWord: 'alert' }}
                  configured
                </h3>
              </div>
            </div>
            <div class="section__body">
              <ul class="o-list c-camera-details__alerts-list">
                <ng-container *ngFor="let offlineAlert of offlineAlerts">
                  <li class="o-list__item is-link" (click)="viewAlerts(offlineAlert.id)">
                    <div class="o-list__item-icon">
                      <mat-icon>no_photography</mat-icon>
                    </div>
                    <div class="o-list__item-text">
                      <h3 class="is-secondary-text">Offline alerts</h3>
                      <h2 class="is-primary-text">{{ offlineAlert.name }}</h2>
                    </div>
                    <div class="o-list__item-secondary-icon">
                      <mat-icon> chevron_right </mat-icon>
                    </div>
                  </li>
                </ng-container>
                <li class="o-list__item is-link" (click)="viewAlerts()" *ngIf="!offlineAlerts.length">
                  <div class="o-list__item-icon">
                    <mat-icon>no_photography</mat-icon>
                  </div>
                  <div class="o-list__item-text">
                    <h3 class="is-secondary-text">Offline alerts</h3>
                    <h2 class="is-primary-text">No alerts configured</h2>
                  </div>
                  <div class="o-list__item-secondary-icon">
                    <mat-icon> chevron_right </mat-icon>
                  </div>
                </li>
                <ng-container *ngFor="let summaryAlert of summaryAlerts">
                  <li class="o-list__item is-link" (click)="viewSummaries(summaryAlert.id)">
                    <div class="o-list__item-icon">
                      <mat-icon>note_add</mat-icon>
                    </div>
                    <div class="o-list__item-text">
                      <h3 class="is-secondary-text">Health summary</h3>
                      <h2 class="is-primary-text">{{ summaryAlert.name }}</h2>
                    </div>
                    <div class="o-list__item-secondary-icon">
                      <mat-icon> chevron_right </mat-icon>
                    </div>
                  </li>
                </ng-container>
                <li class="o-list__item is-link" (click)="viewSummaries()" *ngIf="!summaryAlerts.length">
                  <div class="o-list__item-icon">
                    <mat-icon>note_add</mat-icon>
                  </div>
                  <div class="o-list__item-text">
                    <h3 class="is-secondary-text">Health summary</h3>
                    <h2 class="is-primary-text">No summary configured</h2>
                  </div>
                  <div class="o-list__item-secondary-icon">
                    <mat-icon> chevron_right </mat-icon>
                  </div>
                </li>
              </ul>
            </div>
            <div class="section__actions">
              <a (click)="viewAlerts()" class="o-button">
                View all camera alerts
                <mat-icon class="is-icon-right">arrow_forward</mat-icon>
              </a>
            </div>
          </div>
          <div class="section">
            <div class="section__head">
              <div class="o-heading--primer u-flush--bottom">
                <h2>Monitors:</h2>
                <h3>
                  This camera is used in
                  {{ occupancyMonitors.length + peopleCountMonitors.length + peelOffMonitors.length }}
                  {{
                    occupancyMonitors.length + peopleCountMonitors.length + peelOffMonitors.length
                      | pluraliseWord: 'monitor'
                  }}
                </h3>
              </div>
            </div>
            <div class="section__body">
              <ul class="o-list c-camera-details__alerts-list">
                <ng-container *ngFor="let occupancyMonitor of occupancyMonitors">
                  <li class="o-list__item is-link" (click)="viewOccupancyMonitor(occupancyMonitor)">
                    <div class="o-list__item-icon">
                      <mat-icon>groups</mat-icon>
                    </div>
                    <div class="o-list__item-text">
                      <h3 class="is-secondary-text">Occupancy monitor</h3>
                      <h2 class="is-primary-text">{{ occupancyMonitor.name }}</h2>
                    </div>
                    <div class="o-list__item-secondary-icon">
                      <mat-icon> chevron_right </mat-icon>
                    </div>
                  </li>
                </ng-container>
                <ng-container *ngFor="let peopleCountMonitor of peopleCountMonitors">
                  <li class="o-list__item is-link" (click)="viewPeopleCountMonitor(peopleCountMonitor)">
                    <div class="o-list__item-icon">
                      <mat-icon>transfer_within_a_station</mat-icon>
                    </div>
                    <div class="o-list__item-text">
                      <h3 class="is-secondary-text">People count monitor</h3>
                      <h2 class="is-primary-text">{{ peopleCountMonitor.name }}</h2>
                    </div>
                    <div class="o-list__item-secondary-icon">
                      <mat-icon> chevron_right </mat-icon>
                    </div>
                  </li>
                </ng-container>
                <ng-container *ngFor="let peelOffMonitor of peelOffMonitors">
                  <li class="o-list__item is-link" (click)="viewPeelOffMonitor(peelOffMonitor)">
                    <div class="o-list__item-icon">
                      <mat-icon>fork_left</mat-icon>
                    </div>
                    <div class="o-list__item-text">
                      <h3 class="is-secondary-text">Peel off monitor</h3>
                      <h2 class="is-primary-text">{{ peelOffMonitor.name }}</h2>
                    </div>
                    <div class="o-list__item-secondary-icon">
                      <mat-icon> chevron_right </mat-icon>
                    </div>
                  </li>
                </ng-container>
                <li class="o-list__item is-link" (click)="viewMonitors('occupancy')" *ngIf="!occupancyMonitors.length">
                  <div class="o-list__item-icon">
                    <mat-icon>groups</mat-icon>
                  </div>
                  <div class="o-list__item-text">
                    <h3 class="is-secondary-text">Occupancy monitor</h3>
                    <h2 class="is-primary-text">No monitor configured</h2>
                  </div>
                  <div class="o-list__item-secondary-icon">
                    <mat-icon> chevron_right </mat-icon>
                  </div>
                </li>
                <li
                  class="o-list__item is-link"
                  (click)="viewMonitors('people_count')"
                  *ngIf="!peopleCountMonitors.length"
                >
                  <div class="o-list__item-icon">
                    <mat-icon>transfer_within_a_station</mat-icon>
                  </div>
                  <div class="o-list__item-text">
                    <h3 class="is-secondary-text">People count monitor</h3>
                    <h2 class="is-primary-text">No monitor configured</h2>
                  </div>
                  <div class="o-list__item-secondary-icon">
                    <mat-icon> chevron_right </mat-icon>
                  </div>
                </li>
              </ul>
            </div>
            <div class="section__actions">
              <a (click)="viewMonitors()" class="o-button">
                View all monitors
                <mat-icon class="is-icon-right">arrow_forward</mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
