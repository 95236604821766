import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';

import { SitesComponent } from './views/sites/sites.component';
import { PageCardComponent } from './components/general/page-card/page-card.component';
import { AddEditSiteComponent } from './components/sites/add-edit-site/add-edit-site.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { SiteCardComponent } from './components/sites/site-card/site-card.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SiteDetailsComponent } from './views/site-details/site-details.component';
import { NavigationComponent } from './components/general/navigation/navigation.component';
import { MonitorCardComponent } from './components/monitors/monitor-card/monitor-card.component';
import { InternalErrorComponent } from './views/internal-error/internal-error.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { ApiModule, Configuration, ConfigurationParameters } from './api';
import { NavigationRightSideComponent } from './components/general/navigation-right-side/navigation-right-side.component';
import { UsersComponent } from './views/users/users.component';
import { SnackbarDeleteComponent } from './components/general/snackbar-delete/snackbar-delete.component';
import { NotificationsComponent } from './views/notifications/notifications.component';
import { NotificationsSideMenuComponent } from './views/notifications/components/notifications-side-menu/notifications-side-menu.component';
import { NotificationGroupsComponent } from './views/notifications/routes/notification-groups/notification-groups.component';
import { NotificationGroupComponent } from './views/notifications/routes/notification-group/notification-group.component';
import { AddEditNotificationGroupComponent } from './components/notifications/add-edit-notification-group/add-edit-notification-group.component';
import { UserConfirmationComponent } from './components/general/user-confirmation/user-confirmation.component';
import { AddEditUserComponent } from './components/users/add-edit-user/add-edit-user.component';
import { EditCameraComponent } from './components/cameras/edit-camera/edit-camera.component';
import { NoCameraAlertsComponent } from './views/notifications/routes/no-camera-alerts/no-camera-alerts.component';
import { UuidComponent } from './components/general/uuid/uuid.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { SiteFilterComponent } from './components/general/site-filter/site-filter.component';
import { CamerasListComponent } from './components/cameras/cameras-list/cameras-list.component';
import { CamerasComponent } from './views/cameras/cameras.component';
import { DecimalPipe, NgOptimizedImage } from '@angular/common';
import { HomeComponent } from './views/home/home.component';
import { OrganisationFilterComponent } from './components/general/organisation-filter/organisation-filter.component';
import { OrganisationsComponent } from './views/organisations/organisations.component';
import { OrganisationCardComponent } from './components/organisations/organisation-card/organisation-card.component';
import { CamerasSideMenuComponent } from './views/cameras/components/cameras-side-menu/cameras-side-menu.component';
import { AddEditOrganistaionComponent } from './components/organisations/add-edit-organistaion/add-edit-organistaion.component';
import { FilterComponent } from './components/general/filter/filter.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CustomDateAdapter } from './model/customDateAdapter';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SwitchMultiCasePipe } from './pipes/switch-multi-case.pipe';
import { CookieService } from 'ngx-cookie-service';
import { TimeGrainDisplayValuePipe } from './pipes/time-grain-display-value.pipe';
import { BalenaSummaryComponent } from './components/general/balena-summary/balena-summary.component';
import { GcpLogsComponent } from './components/general/gcp-logs/gcp-logs.component';
import { NoSitesCardComponent } from './components/sites/no-sites-card/no-sites-card.component';
import { OccupancyReportMetricsDisplayValuePipe } from './pipes/metrics-display-value.pipe';
import { AddPeopleCountMonitorComponent } from './components/monitors/add-people-count-monitor/add-people-count-monitor.component';
import { ChooseMonitorModeStepComponent } from './components/steps-for-stepper/choose-monitor-mode-step/choose-monitor-mode-step.component';
import { GiveMonitorNameStepComponent } from './components/steps-for-stepper/give-monitor-name-step/give-monitor-name-step.component';
import { SelectCamerasStepComponent } from './components/steps-for-stepper/select-cameras-step/select-cameras-step.component';
import { SelectMonitorDirectionStepComponent } from './components/steps-for-stepper/select-monitor-direction-step/select-monitor-direction-step.component';
import { SelectCameraDirectionStepComponent } from './components/steps-for-stepper/select-camera-direction-step/select-camera-direction-step.component';
import { AddCameraStepComponent } from './components/steps-for-stepper/add-camera-step/add-camera-step.component';
import { SetCameraDirectionsComponent } from './components/steps-for-stepper/set-camera-directions/set-camera-directions.component';
import { ViewCameraDirectionsComponent } from './components/steps-for-stepper/view-camera-directions/view-camera-directions.component';
import { StepComponent } from './components/steps-for-stepper/step/step.component';
import { AddOccupancyMonitorComponent } from './components/monitors/add-occupancy-monitor/add-occupancy-monitor.component';
import { AddOccupancyMonitorDetailsComponent } from './components/steps-for-stepper/add-occupancy-monitor-details/add-occupancy-monitor-details.component';
import { OccupancyMonitorConfirmationComponent } from './components/steps-for-stepper/occupancy-monitor-confirmation/occupancy-monitor-confirmation.component';
import { CopyLinkComponent } from './components/general/copy-link/copy-link.component';
import { PeopleCountMonitorDetailsComponent } from './views/monitors/routes/people-count-monitor-details/people-count-monitor-details.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SiteCamerasComponent } from './views/site-details/routes/site-cameras/site-cameras.component';
import { SiteInfoComponent } from './views/site-details/routes/site-info/site-info.component';
import { OccupancyMonitorDetailsComponent } from './views/monitors/routes/occupancy-monitor-details/occupancy-monitor-details.component';
import { MinutesToTimeStringPipe } from './pipes/minutes-to-time-string.pipe';
import { EditPeopleCountMonitorComponent } from './components/monitors/edit-people-count-monitor/edit-people-count-monitor.component';
import { EditOccupancyMonitorComponent } from './components/monitors/edit-occupancy-monitor/edit-occupancy-monitor.component';
import { UpdateOccupancyMonitorDetailsComponent } from './components/steps-for-stepper/update-occupancy-monitor-details/update-occupancy-monitor-details.component';
import { PluraliseWordPipe } from './pipes/pluralise-word.pipe';
import { DateForPipe } from './pipes/date-for.pipe';
import { AddCameraCreateMonitorComponent } from './components/steps-for-stepper/add-camera-create-monitor/add-camera-create-monitor.component';
import { MonitorAlertsComponent } from './views/notifications/routes/monitor-alerts/monitor-alerts.component';
import { OccupancyAlertDetailsComponent } from './components/notifications/steps-for-stepper/occupancy-alert-details/occupancy-alert-details.component';
import { AddMonitorAlertComponent } from './components/notifications/add-monitor-alert/add-monitor-alert.component';
import { OccupancyAlertSelectMonitorComponent } from './components/notifications/steps-for-stepper/occupancy-alert-select-monitor/occupancy-alert-select-monitor.component';
import { EditOccupancyAlertComponent } from './components/notifications/edit-occupancy-alert/edit-occupancy-alert.component';
import { MonitorsComponent } from './views/monitors/monitors.component';
import { SelectASiteComponent } from './components/steps-for-stepper/select-a-site/select-a-site.component';
import { NoAccessComponent } from './views/no-access/no-access.component';
import { MonitorsListDisplayComponent } from './views/site-details/components/monitors-list-display/monitors-list-display.component';
import { HighlightedInfoComponent } from './components/general/highlighted-info/highlighted-info.component';
import { LimitReachedInformationComponent } from './components/general/limit-reached-information/limit-reached-information.component';
import { MinMaxPipe } from './pipes/min-max.pipe';
import { CameraAlertsOfflineComponent } from './views/notifications/routes/camera-alerts-offline/camera-alerts-offline.component';
import { AddEditCameraAlertsOfflineComponent } from './components/notifications/add-edit-camera-alerts-offline/add-edit-camera-alerts-offline.component';
import { CameraAlertAndSummaryNameAndSitesComponent } from './components/notifications/steps-for-stepper/camera-alert-and-summary-name-and-sites/camera-alert-and-summary-name-and-sites.component';
import { CameraAlertAndSummaryCamerasComponent } from './components/notifications/steps-for-stepper/camera-alert-and-summary-cameras/camera-alert-and-summary-cameras.component';
import { CameraAlertSettingsComponent } from './components/notifications/steps-for-stepper/camera-alert-settings/camera-alert-settings.component';
import { CameraAlertAndSummaryDetailsReviewComponent } from './components/notifications/steps-for-stepper/camera-alert-and-summary-details-review/camera-alert-and-summary-details-review.component';
import { AlertDurationPipe } from './pipes/alert-duration.pipe';
import { AlertInformationComponent } from './components/general/alert-information/alert-information.component';
import { FindOutMoreModalDialogComponent } from './components/notifications/find-out-more-modal-dialog/find-out-more-modal-dialog.component';
import { CamerasInformationComponent } from './components/general/cameras-information/cameras-information.component';
import { PausedCameraInfoComponent } from './components/general/paused-camera-info/paused-camera-info.component';
import { AddCameraToResourceDialogComponent } from './views/notifications/components/add-camera-to-resource-dialog/add-camera-to-resource-dialog.component';
import { DownloadPeopleCountModalDataComponent } from './views/reporting/components/download-people-count-data/download-people-count-modal-data.component';
import { DownloadOccupancyDataComponent } from './views/reporting/components/download-occupancy-data/download-occupancy-data.component';
import { DataExploreDialogComponent } from './views/reporting/components/data-explore-dialog/data-explore-dialog.component';
import { DownloadReportDialogComponent } from './views/reporting/components/download-report-dialog/download-report-dialog.component';
import { DecommissionConfirmationComponent } from './components/cameras/decommission-confirmation/decommission-confirmation.component';
import { RealTimeDataCardComponent } from './views/real-time-data/component/real-time-data-card/real-time-data-card.component';
import { TimePickDialogComponent } from './views/reporting/components/time-pick-dialog/time-pick-dialog.component';
import { NoCameraSummariesComponent } from './views/notifications/routes/no-camera-summaries/no-camera-summaries.component';
import { CameraSummariesComponent } from './views/notifications/routes/camera-summaries/camera-summaries.component';
import { AddEditCameraSummaryComponent } from './components/notifications/add-edit-camera-summary/add-edit-camera-summary.component';
import { CameraSummarySettingsComponent } from './components/notifications/steps-for-stepper/camera-summary-settings/camera-summary-settings.component';
import { NewCameraSummariesComponent } from './components/notifications/new-camera-summaries/new-camera-summaries.component';
import { CameraAlertAndSummaryAddTheOtherResourceComponent } from './components/steps-for-stepper/camera-alert-and-summary-add-the-other-resource/camera-alert-and-summary-add-the-other-resource.component';
import { CameraDetailsComponent } from './components/cameras/camera-details/camera-details.component';
import { IsArePipe } from './pipes/is-are.pipe';
import { OccupancyAlertSummrayComponent } from './components/notifications/steps-for-stepper/occupancy-alert-summray/occupancy-alert-summray.component';
import { ComparisonDialogComponent } from './views/reporting/components/comparison-dialog/comparison-dialog.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { PeopleCountMonitorCardComponent } from './views/reporting/components/people-count-monitor-card/people-count-monitor-card.component';
import { MonitorDetailsCardComponent } from './views/real-time-data/component/monitor-details-card/monitor-details-card.component';
import { TimeToStringPipe } from './pipes/time-to-string.pipe';
import { FormatTransferSpeedPipe } from './pipes/format-transfer-speed.pipe';
import { CameraCardComponent } from './components/cameras/camera-card/camera-card.component';
import { FilterDialogComponent } from './components/general/filter-dialog/filter-dialog.component';
import { SortByComponent } from './components/general/sort-by/sort-by.component';
import { EditNotificationGroupDialogComponent } from './views/notifications/components/edit-notification-group-dialog/edit-notification-group-dialog.component';
import { SelectRecipientsComponent } from './components/notifications/select-recipients/select-recipients.component';
import { AbsPipe } from './pipes/abs.pipe';
import { PeopleCountAllMonitorsDialogComponent } from './views/reporting/components/people-count-all-monitors-dialog/people-count-all-monitors-dialog.component';
import { CameraSetupDialogComponent } from './components/cameras/camera-setup-dialog/camera-setup-dialog.component';
import { CameraSetupSidePanelComponent } from './components/cameras/camera-setup-side-panel/camera-setup-side-panel.component';
import { CustomSnackbarComponent } from './components/notifications/custom-snackbar/custom-snackbar.component';
import { PeopleCountMonitorCardDialogComponent } from './views/reporting/components/people-count-monitor-card-dialog/people-count-monitor-card-dialog.component';
import { CopyTextComponent } from './components/general/copy-text/copy-text.component';
import { SelectAlertsAndSumarriesComponent } from './views/cameras/components/select-alerts-and-sumarries/select-alerts-and-sumarries.component';
import { RealTimeDataComponent } from './views/real-time-data/real-time-data.component';
import { ReportingComponent } from './views/reporting/reporting.component';
import { QuickstartComponent } from './views/home/components/quickstart/quickstart.component';
import { AddFirstSiteComponent } from './views/home/components/add-first-site/add-first-site.component';
import { AddFirstCameraComponent } from './views/home/components/add-first-camera/add-first-camera.component';
import { AddFirstMonitorComponent } from './views/home/components/add-first-monitor/add-first-monitor.component';
import { InfrastructureAsideComponent } from './views/home/components/infrastructure-aside/infrastructure-aside.component';
import { FormatLicensePipe } from './pipes/format-license.pipe';
import { MetricsCardComponent } from './views/home/components/metrics-card/metrics-card.component';
import { NoSitePermissionComponent } from './views/home/components/no-site-permission/no-site-permission.component';
import { ExpansionPanelComponent } from './views/home/components/expansion-panel/expansion-panel.component';
import { CapacityManagementBriefComponent } from './views/reporting/components/capacity-management-brief/capacity-management-brief.component';
import { CapacityManagementDialogComponent } from './views/reporting/components/capacity-management-dialog/capacity-management-dialog.component';
import { RealTimeDataCardDialogComponent } from './views/real-time-data/component/real-time-data-card-dialog/real-time-data-card-dialog.component';
import { CameraPerformanceSummaryComponent } from './components/cameras/camera-performance-summary/camera-performance-summary.component';
import { MonitorAlertTypeComponent } from './components/notifications/steps-for-stepper/monitor-alert-type/monitor-alert-type.component';
import { NoSiteToSelectComponent } from './components/general/no-site-to-select/no-site-to-select.component';
import { AddFirstMonitorsComponent } from './views/monitors/components/add-first-monitors/add-first-monitors.component';
import { CustomerEngagementComponent } from './views/customer-engagement/customer-engagement.component';
import { NgPipesModule } from 'ngx-pipes';
import { AddEditPeelOffMonitorComponent } from './components/monitors/add-edit-peel-off-monitor/add-edit-peel-off-monitor.component';
import { PeelOffMonitorDetailsComponent } from './views/monitors/routes/peel-off-monitor-details/peel-off-monitor-details.component';
import { DownloadPeelOffDataComponent } from './views/reporting/components/download-peel-off-data/download-peel-off-data.component';
import { SitesOpeningHoursComponent } from './components/sites/sites-opening-hours/sites-opening-hours.component';
import { FormatOpeningHoursPipe } from 'src/app/pipes/format-opening-hours.pipe';
import { SiteSetupSidePanelComponent } from './components/sites/site-setup-side-panel/site-setup-side-panel.component';
import { MonitorsInfoComponent } from './views/monitors/routes/monitors-info/monitors-info.component';
import { MonitorSetupSidePanelComponent } from 'src/app/components/monitors/monitor-setup-side-panel/monitor-setup-side-panel.component';
import { DownloadDwellTimeDataComponent } from './views/reporting/components/download-dwell-time-data/download-dwell-time-data.component';
import { BooleanToStringPipe } from './pipes/boolean-to-string.pipe';
import { AddDwellTimeReportComponent } from './views/reporting/components/add-dwell-time-report/add-dwell-time-report.component';
import { DwellTimeReportSetupSidePanelComponent } from './views/reporting/components/dwell-time-report-setup-side-panel/dwell-time-report-setup-side-panel.component';
import { DwellTimeReportReviewComponent } from './views/reporting/components/dwell-time-report-review/dwell-time-report-review.component';
import { ProcessingSummaryComponent } from 'src/app/components/general/processing-summary/processing-summary.component';
import { SettingsComponent } from './views/settings/settings.component';
import { UsersTabComponent } from './views/settings/components/users-tab/users-tab.component';
import { OpeningHoursTabComponent } from './views/settings/components/opening-hours-tab/opening-hours-tab.component';
import { OverviewTabComponent } from './views/settings/components/overview-tab/overview-tab.component';
import { OAuthClientTabComponent } from './views/settings/components/oauth-client-tab/oauth-client-tab.component';
import { OAuthClientCardComponent } from './views/settings/components/oauth-client-card/oauth-client-card.component';
import { OAuthClientModalComponent } from './views/settings/components/oauth-client-modal/oauth-client-modal.component';
import { HttpInterceptorService } from './interceptors/http.interceptor';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { NewFeatureComponent } from './components/general/new-feature/new-feature.component';
import { MapsComponent } from './views/reporting/components/maps/maps.component';
import { MapsSpaceViewComponent } from './views/reporting/components/maps-space-view/maps-space-view.component';
import { DownloadFeedbacksComponent } from './views/reporting/components/download-feedbacks/download-feedbacks.component';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.apiUrl,
  };
  return new Configuration(params);
}

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

const MatModules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatTableModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSnackBarModule,
  MatExpansionModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatTooltipModule,
  MatTabsModule,
  MatListModule,
];

@NgModule({
  declarations: [
    AppComponent,
    SitesComponent,
    PageCardComponent,
    AddEditSiteComponent,
    NotFoundComponent,
    SiteCardComponent,
    SiteDetailsComponent,
    NavigationComponent,
    MonitorCardComponent,
    InternalErrorComponent,
    NavigationRightSideComponent,
    UsersComponent,
    AddEditUserComponent,
    SnackbarDeleteComponent,
    NotificationsComponent,
    NotificationsSideMenuComponent,
    NotificationGroupsComponent,
    NotificationGroupComponent,
    AddEditNotificationGroupComponent,
    UserConfirmationComponent,
    EditCameraComponent,
    NoCameraAlertsComponent,
    UuidComponent,
    SiteFilterComponent,
    CamerasListComponent,
    CamerasComponent,
    HomeComponent,
    OrganisationFilterComponent,
    OrganisationsComponent,
    OrganisationCardComponent,
    CamerasSideMenuComponent,
    AddEditOrganistaionComponent,
    FilterComponent,
    SwitchMultiCasePipe,
    TimeGrainDisplayValuePipe,
    BalenaSummaryComponent,
    GcpLogsComponent,
    NoSitesCardComponent,
    OccupancyReportMetricsDisplayValuePipe,
    AddPeopleCountMonitorComponent,
    StepComponent,
    ChooseMonitorModeStepComponent,
    GiveMonitorNameStepComponent,
    SelectCamerasStepComponent,
    SelectMonitorDirectionStepComponent,
    SelectCameraDirectionStepComponent,
    AddCameraStepComponent,
    SetCameraDirectionsComponent,
    ViewCameraDirectionsComponent,
    StepComponent,
    AddOccupancyMonitorComponent,
    AddOccupancyMonitorDetailsComponent,
    SelectCamerasStepComponent,
    OccupancyMonitorConfirmationComponent,
    CopyLinkComponent,
    PeopleCountMonitorDetailsComponent,
    SiteCamerasComponent,
    SiteInfoComponent,
    MonitorsInfoComponent,
    OccupancyMonitorDetailsComponent,
    PeelOffMonitorDetailsComponent,
    MinutesToTimeStringPipe,
    EditPeopleCountMonitorComponent,
    EditOccupancyMonitorComponent,
    UpdateOccupancyMonitorDetailsComponent,
    PluraliseWordPipe,
    DateForPipe,
    AddCameraCreateMonitorComponent,
    MonitorAlertsComponent,
    OccupancyAlertDetailsComponent,
    AddMonitorAlertComponent,
    OccupancyAlertSelectMonitorComponent,
    EditOccupancyAlertComponent,
    MonitorsComponent,
    SelectASiteComponent,
    NoAccessComponent,
    MonitorsListDisplayComponent,
    HighlightedInfoComponent,
    LimitReachedInformationComponent,
    MinMaxPipe,
    CameraAlertsOfflineComponent,
    AddEditCameraAlertsOfflineComponent,
    CameraAlertAndSummaryNameAndSitesComponent,
    CameraAlertAndSummaryCamerasComponent,
    CameraAlertSettingsComponent,
    CameraAlertAndSummaryDetailsReviewComponent,
    AlertDurationPipe,
    FindOutMoreModalDialogComponent,
    AlertInformationComponent,
    CamerasInformationComponent,
    PausedCameraInfoComponent,
    AddCameraToResourceDialogComponent,
    DownloadPeopleCountModalDataComponent,
    DownloadOccupancyDataComponent,
    DataExploreDialogComponent,
    DownloadReportDialogComponent,
    DecommissionConfirmationComponent,
    RealTimeDataCardComponent,
    CapacityManagementBriefComponent,
    TimePickDialogComponent,
    RealTimeDataCardDialogComponent,
    NoCameraSummariesComponent,
    CameraSummariesComponent,
    AddEditCameraSummaryComponent,
    CameraSummarySettingsComponent,
    NewCameraSummariesComponent,
    CameraAlertAndSummaryAddTheOtherResourceComponent,
    CameraDetailsComponent,
    IsArePipe,
    OccupancyAlertSummrayComponent,
    CapacityManagementDialogComponent,
    ComparisonDialogComponent,
    DateAgoPipe,
    BooleanToStringPipe,
    PeopleCountMonitorCardComponent,
    MonitorDetailsCardComponent,
    TimeToStringPipe,
    FormatTransferSpeedPipe,
    CameraCardComponent,
    FilterDialogComponent,
    SortByComponent,
    EditNotificationGroupDialogComponent,
    SelectRecipientsComponent,
    AbsPipe,
    PeopleCountAllMonitorsDialogComponent,
    CameraSetupDialogComponent,
    CameraSetupSidePanelComponent,
    SiteSetupSidePanelComponent,
    CustomSnackbarComponent,
    PeopleCountMonitorCardDialogComponent,
    CopyTextComponent,
    NewFeatureComponent,
    SelectAlertsAndSumarriesComponent,
    RealTimeDataComponent,
    ReportingComponent,
    QuickstartComponent,
    AddFirstSiteComponent,
    AddFirstCameraComponent,
    AddFirstMonitorComponent,
    InfrastructureAsideComponent,
    FormatLicensePipe,
    MetricsCardComponent,
    NoSitePermissionComponent,
    ExpansionPanelComponent,
    CameraPerformanceSummaryComponent,
    MonitorAlertTypeComponent,
    NoSiteToSelectComponent,
    AddFirstMonitorsComponent,
    CustomerEngagementComponent,
    OAuthClientModalComponent,
    AddEditPeelOffMonitorComponent,
    DownloadPeelOffDataComponent,
    SitesOpeningHoursComponent,
    FormatOpeningHoursPipe,
    MonitorSetupSidePanelComponent,
    DownloadDwellTimeDataComponent,
    AddDwellTimeReportComponent,
    DwellTimeReportSetupSidePanelComponent,
    DwellTimeReportReviewComponent,
    ProcessingSummaryComponent,
    SettingsComponent,
    UsersTabComponent,
    OpeningHoursTabComponent,
    OAuthClientTabComponent,
    OverviewTabComponent,
    OAuthClientCardComponent,
    MapsComponent,
    MapsSpaceViewComponent,
    DownloadFeedbacksComponent,
  ],
  imports: [
    ...MatModules,
    NgPipesModule,
    ApiModule.forRoot(apiConfigFactory),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      authorizationParams: {
        audience: environment.auth0Audience,
        redirect_uri: window.location.origin,
      },
      httpInterceptor: {
        allowedList: [{ uri: `${environment.apiUrl}/*` }, { uri: `${environment.reportingUrl}*` }],
      },
      cookieDomain: environment.cookieDomain,
    }),
    ClipboardModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    NgOptimizedImage,
  ],
  providers: [
    CookieService,
    MatNativeDateModule,
    DecimalPipe,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { ...new MatDialogConfig(), autoFocus: false } },
    { provide: 'googleTagManagerId', useValue: 'GTM-NS8FD3M' },
    {
      provide: LOCALE_ID,
      useValue: navigator.language,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
