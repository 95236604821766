import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, finalize, forkJoin, Subject, takeUntil } from 'rxjs';
import {
  Camera,
  CameraService,
  OccupancyMonitorCameraPosition,
  OccupancyMonitorCameraPositionService,
  OccupancyMonitorService,
  PeelOffMonitorCameraPosition,
  PeelOffMonitorCameraPositionService,
  PeelOffMonitorService,
  PeopleCountMonitorCameraPosition,
  PeopleCountMonitorCameraPositionService,
  PeopleCountMonitorService,
  Site,
  SiteService,
} from 'src/app/api';
import { AccountService } from 'src/app/services/account.service';
import { CamerasService } from 'src/app/services/cameras.service';
import { MonitorsService } from 'src/app/services/monitors.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-monitors',
  templateUrl: './monitors.component.html',
  styleUrls: ['./monitors.component.scss'],
})
export class MonitorsComponent implements OnInit, OnDestroy {
  isLoading$ = new BehaviorSubject(true);

  siteId;
  activeMonitor = '';
  activeChildRoute = '';
  monitorType = '';
  childRoutes = {
    'people-count-monitor/:id': 'People Count Monitors',
    'peel-off-monitor/:id': 'Peel Off Monitors',
    'occupancy-monitor/:id': 'Occupancy Monitors',
    '': {
      people_count: 'People Count Monitors',
      occupancy: 'Occupancy Monitors',
      peel_off: 'Peel Off Monitors',
    },
  };

  private ngUnsubscribe = new Subject();

  constructor(
    public monitorsService: MonitorsService,
    public accountService: AccountService,
    private occupancyMonitorService: OccupancyMonitorService,
    private peopleCountMonitorService: PeopleCountMonitorService,
    private peelOffMonitorService: PeelOffMonitorService,
    private ref: ChangeDetectorRef,
    private occupancyMonitorCameraPositionService: OccupancyMonitorCameraPositionService,
    private peopleCountMonitorCameraPositionService: PeopleCountMonitorCameraPositionService,
    private peelOffMonitorCameraPositionService: PeelOffMonitorCameraPositionService,
    private cameraService: CameraService,
    private camerasService: CamerasService,
    private siteService: SiteService,
    private route: ActivatedRoute,
    private notifyService: NotifyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (params) => {
        this.siteId = params?.siteId;
      },
    });

    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((queryParams) => {
      const type = queryParams['type'];
      if (this.monitorsService.monitorsType[type] != undefined) {
        this.monitorsService.selectedTabIndex = this.monitorsService.monitorsType[type];
      }
      this.ref.detectChanges();
    });

    this.getData();
    this.isLoading$.pipe(filter((value) => value === false)).subscribe(() => {
      this.checkActiveChildRoute();
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        this.checkActiveChildRoute();
      });
    });
  }

  private checkActiveChildRoute(): void {
    const path = this.activatedRoute.firstChild?.snapshot.routeConfig.path;
    if (path) {
      const id = this.activatedRoute.firstChild?.snapshot.params.id;
      this.activeChildRoute = '';
      switch (path) {
        case 'people-count-monitor/:id': {
          this.activeChildRoute = this.monitorsService.getPeopleCountMonitor(id)?.name;
          this.monitorType = 'people_count';
          break;
        }
        case 'peel-off-monitor/:id': {
          this.activeChildRoute = this.monitorsService.getPeelOffMonitor(id)?.name;
          this.monitorType = 'peel_off';
          break;
        }
        case 'occupancy-monitor/:id': {
          this.activeChildRoute = this.monitorsService.getOccupancyMonitor(id)?.name;
          this.monitorType = 'occupancy';
          break;
        }
      }
      this.activeMonitor = this.childRoutes[path];
    } else {
      this.activeChildRoute = '';
      this.activeMonitor =
        this.childRoutes[path][this.monitorsService.monitorsTypeValue[this.monitorsService.selectedTabIndex]];
    }
    this.ref.detectChanges();
  }

  getData(): void {
    forkJoin([
      this.cameraService.listCameras(true, undefined, undefined, ['running', 'paused']),
      this.siteService.listSites(undefined, undefined, 'active'),
      this.occupancyMonitorService.listOccupancyMonitors(undefined, this.siteId, undefined, 'active'),
      this.peopleCountMonitorService.listPeopleCountMonitors(undefined, this.siteId, undefined, 'active'),
      this.peelOffMonitorService.listPeelOffMonitors(undefined, this.siteId, undefined, 'active'),
      this.occupancyMonitorCameraPositionService.listOccupancyMonitorCameraPositions(
        undefined,
        this.siteId,
        undefined,
        undefined,
        'active',
      ),
      this.peopleCountMonitorCameraPositionService.listPeopleCountMonitorCameraPositions(
        undefined,
        this.siteId,
        undefined,
        undefined,
        'active',
      ),
      this.peelOffMonitorCameraPositionService.listPeelOffMonitorCameraPositions(
        undefined,
        this.siteId,
        undefined,
        undefined,
        'active',
      ),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoading$.next(false);
        }),
      )
      .subscribe({
        next: ([
          cameras,
          sites,
          occupancyMonitors,
          peopleCountMonitors,
          peelOffMonitors,
          occupancyMonitorCameraPositions,
          peopleCountMonitorCameraPositions,
          peelOffMonitorCameraPositions,
        ]) => {
          this.monitorsService.sites = sites.sort((a, b) => a.name.localeCompare(b.name));
          const sitesMap = {};
          this.monitorsService.sites.forEach((site: Site) => {
            sitesMap[site.id] = site;
          });
          this.monitorsService.site = sitesMap[this.siteId];

          const camerasMapByPositionId = {};

          this.monitorsService.cameras = cameras
            .filter((camera) => (this.siteId ? camera.siteId === this.siteId : true))
            .map((c) => this.camerasService.getCameraStatus(c, sitesMap[c.siteId]));

          this.monitorsService.cameras.forEach((camera: Camera) => {
            camerasMapByPositionId[camera.cameraPositionId] = camera;
          });

          occupancyMonitors?.forEach((monitor) => {
            this.monitorsService.occupancyMonitorCameras[monitor.id] = [];
            this.monitorsService.occupancyMonitorCamerasPositions[monitor.id] = {};
          });
          peopleCountMonitors?.forEach((monitor) => {
            this.monitorsService.peopleCountMonitorCameras[monitor.id] = [];
            this.monitorsService.peopleCountMonitorCamerasPositions[monitor.id] = {};
          });
          peelOffMonitors?.forEach((monitor) => {
            this.monitorsService.peelOffMonitorCameras[monitor.id] = [];
            this.monitorsService.peelOffMonitorCamerasPositions[monitor.id] = {};
          });

          occupancyMonitorCameraPositions?.forEach((occupancyMonitorCameraPosition: OccupancyMonitorCameraPosition) => {
            const camera: Camera = camerasMapByPositionId[occupancyMonitorCameraPosition.cameraPositionId];

            if (
              camera &&
              this.monitorsService.occupancyMonitorCameras[occupancyMonitorCameraPosition.occupancyMonitorId]
            ) {
              this.monitorsService.occupancyMonitorCameras[occupancyMonitorCameraPosition.occupancyMonitorId].push(
                camera,
              );
              this.monitorsService.occupancyMonitorCamerasPositions[occupancyMonitorCameraPosition.occupancyMonitorId][
                occupancyMonitorCameraPosition.cameraPositionId
              ] = occupancyMonitorCameraPosition;
            }
          });

          peopleCountMonitorCameraPositions?.forEach(
            (peopleCountMonitorCameraPosition: PeopleCountMonitorCameraPosition) => {
              const camera: Camera = camerasMapByPositionId[peopleCountMonitorCameraPosition.cameraPositionId];

              if (
                camera &&
                this.monitorsService.peopleCountMonitorCameras[peopleCountMonitorCameraPosition.peopleCountMonitorId]
              ) {
                this.monitorsService.peopleCountMonitorCameras[
                  peopleCountMonitorCameraPosition.peopleCountMonitorId
                ].push(camera);
                this.monitorsService.peopleCountMonitorCamerasPositions[
                  peopleCountMonitorCameraPosition.peopleCountMonitorId
                ][peopleCountMonitorCameraPosition.cameraPositionId] = peopleCountMonitorCameraPosition;
              }
            },
          );

          peelOffMonitorCameraPositions?.forEach((peelOffMonitorCameraPosition: PeelOffMonitorCameraPosition) => {
            const camera: Camera = camerasMapByPositionId[peelOffMonitorCameraPosition.cameraPositionId];

            if (camera && this.monitorsService.peelOffMonitorCameras[peelOffMonitorCameraPosition.peelOffMonitorId]) {
              this.monitorsService.peelOffMonitorCameras[peelOffMonitorCameraPosition.peelOffMonitorId].push(camera);
              this.monitorsService.peelOffMonitorCamerasPositions[peelOffMonitorCameraPosition.peelOffMonitorId][
                peelOffMonitorCameraPosition.cameraPositionId
              ] = peelOffMonitorCameraPosition;

              if (peelOffMonitorCameraPosition.countType === 'base') {
                this.monitorsService.peelOffMonitorsWithBase.add(peelOffMonitorCameraPosition.peelOffMonitorId);
              } else {
                this.monitorsService.peelOffMonitorsWithPeelOff.add(peelOffMonitorCameraPosition.peelOffMonitorId);
              }
            }
          });

          this.monitorsService.occupancyMonitors = (occupancyMonitors ?? [])
            .filter((monitor) => sitesMap[monitor.siteId])
            .map((om) =>
              this.monitorsService.getOccupancyMonitorStatus(
                om,
                this.monitorsService.occupancyMonitorCameras[om.id],
                sitesMap[om.siteId],
              ),
            );

          this.monitorsService.peopleCountMonitors = (peopleCountMonitors ?? [])
            .filter((monitor) => sitesMap[monitor.siteId])
            .map((pcm) =>
              this.monitorsService.getPeopleCountMonitorStatus(
                pcm,
                this.monitorsService.peopleCountMonitorCameras[pcm.id],
                sitesMap[pcm.siteId],
              ),
            );
          this.monitorsService.peelOffMonitors = (peelOffMonitors ?? [])
            .filter((monitor) => sitesMap[monitor.siteId])
            .map((pom) =>
              this.monitorsService.getPeelOffMonitorStatus(
                pom,
                this.monitorsService.peelOffMonitorCameras[pom.id],
                sitesMap[pom.siteId],
              ),
            );
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
