import { formatDate } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, finalize, Subject, take, takeUntil } from 'rxjs';
import {
  Camera,
  CameraService,
  Frame,
  PeelOffMonitor,
  PeelOffMonitorCameraPosition,
  ReportService,
  Site,
} from 'src/app/api';
import { AddEditPeelOffMonitorComponent } from 'src/app/components/monitors/add-edit-peel-off-monitor/add-edit-peel-off-monitor.component';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { AccountService } from 'src/app/services/account.service';
import { NotifyService } from 'src/app/services/notify.service';
import * as ApexCharts from 'apexcharts';
import { MonitorsService } from 'src/app/services/monitors.service';
@Component({
  selector: 'app-peel-off-monitor-details',
  templateUrl: './peel-off-monitor-details.component.html',
  styleUrls: ['./peel-off-monitor-details.component.scss'],
})
export class PeelOffMonitorDetailsComponent implements OnInit, OnDestroy {
  monitorId = '';

  site: Site;
  monitor: PeelOffMonitor;
  monitorCameras = [];
  entranceCameras = [];
  passTraffCameras = [];
  camerasPositionsMap: {
    [_: string]: PeelOffMonitorCameraPosition;
  } = {};
  cameraFrame = {};

  isLoadingCameraFrame = new Map<string, BehaviorSubject<boolean>>();
  isLoading$ = new BehaviorSubject<boolean>(true);
  isLoadingChart$ = new BehaviorSubject<boolean>(true);
  useOpeningHours = false;
  xaxis;
  dateFrom;
  dateTo;
  passingCountWeekTotal: { [key in 'true' | 'false']: number } = { true: 0, false: 0 };
  entranceCountWeekTotal: { [key in 'true' | 'false']: number } = { true: 0, false: 0 };
  passingCountTodayTotal: { [key in 'true' | 'false']: number } = { true: 0, false: 0 };
  entranceCountTodayTotal: { [key in 'true' | 'false']: number } = { true: 0, false: 0 };
  weekConversion: { [key in 'true' | 'false']: number } = { true: 0, false: 0 };
  todayConversion: { [key in 'true' | 'false']: number } = { true: 0, false: 0 };
  todayConversionChange: { [key in 'true' | 'false']: number } = { true: 0, false: 0 };
  chartDataRate: { [key in 'true' | 'false']: [] } = { true: undefined, false: undefined };
  chartDataPassingCount: { [key in 'true' | 'false']: [] } = { true: undefined, false: undefined };
  chartDataEntranceCount: { [key in 'true' | 'false']: [] } = { true: undefined, false: undefined };
  viewHeight: number;
  private chart: ElementRef;

  @ViewChild('chart') set content(content: ElementRef) {
    if (content && !this.chart) {
      this.chart = content;
      this.viewHeight = this.chart.nativeElement.offsetHeight;
      this.setChartData();
    }
  }

  private ngUnsubscribe = new Subject();

  constructor(
    public accountService: AccountService,
    private cameraService: CameraService,
    private route: ActivatedRoute,
    private notifyService: NotifyService,
    private matDialog: MatDialog,
    private router: Router,
    private reportService: ReportService,
    public monitorsService: MonitorsService,
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading$.next(false);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (params) => {
          this.monitorId = params?.id;
          this.monitor = this.monitorsService.getPeelOffMonitor(this.monitorId);

          if (this.monitor === undefined) {
            this.router.navigate(['/not-found']);
            return;
          }
          this.site = this.monitorsService.sites.find((s) => s.id === this.monitor.siteId);
          this.monitorCameras = this.monitorsService.peelOffMonitorCameras[this.monitorId];
          this.monitorCameras.forEach((camera) => {
            this.getCameraFrame(camera);
          });
          this.camerasPositionsMap = this.monitorsService.peelOffMonitorCamerasPositions[this.monitorId];
          this.passTraffCameras = this.monitorCameras.filter(
            (camera: CameraStatus) => this.camerasPositionsMap[camera.cameraPositionId].countType === 'base',
          );
          this.entranceCameras = this.monitorCameras.filter(
            (camera: CameraStatus) => this.camerasPositionsMap[camera.cameraPositionId].countType === 'peel_off',
          );

          if (params?.edit) {
            this.openEditPeelOffMonitorDialog(true);
            this.isLoading$.next(false);
          }
        },
      });
  }

  getCameraFrame(camera: Camera): void {
    if (camera.state === 'running' && camera.isOnline) {
      if (!this.isLoadingCameraFrame.has(camera.id)) {
        this.isLoadingCameraFrame.set(camera.id, new BehaviorSubject<boolean>(true));
      } else {
        this.isLoadingCameraFrame.get(camera.id).next(true);
      }

      this.cameraService
        .createFrameRequest({ cameraId: camera.id })
        .pipe(
          finalize(() => {
            this.isLoadingCameraFrame.get(camera.id)?.next(false);
          }),
        )
        .subscribe({
          next: (frame: Frame) => {
            this.cameraFrame[camera.id] = 'data:image/jpeg;base64,' + frame.image;
          },
          error: (error) => {
            this.isLoadingCameraFrame.delete(camera.id);
            this.notifyService.error(error);
          },
        });
    }
  }

  getReportData() {
    this.dateFrom = moment().subtract(7, 'days').format('DD/MM/YYYY');
    this.dateTo = moment().subtract(1, 'day').format('DD/MM/YYYY');
    this.xaxis = [];
    this.reportService
      .createPeelOffReport({
        peelOffMonitorIds: [this.monitorId],
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
        timeGrain: '24h',
        isOpen: this.useOpeningHours,
      })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.drawChart();
          this.isLoadingChart$.next(false);
        }),
      )
      .subscribe({
        next: (reports) => {
          const rate = [];
          const passingCount = [];
          const entranceCount = [];
          let lastWeekConversion = 0;
          this.passingCountWeekTotal[String(this.useOpeningHours)] = 0;
          this.entranceCountWeekTotal[String(this.useOpeningHours)] = 0;
          reports.forEach((report, index) => {
            rate.push(Math.trunc(report['rate'] * 10000) / 100);
            passingCount.push(report['base']);
            entranceCount.push(report['peelOff']);
            this.xaxis.push(moment(report['time'].split('.')[0]).format());
            if (index === 0) {
              lastWeekConversion = Math.trunc(report['rate'] * 10000) / 100;
            }
            if (index === 7) {
              this.todayConversion[String(this.useOpeningHours)] = Math.trunc(report['rate'] * 10000) / 100;
              this.passingCountTodayTotal[String(this.useOpeningHours)] = report['base'];
              this.entranceCountTodayTotal[String(this.useOpeningHours)] = report['peelOff'];
              return;
            }

            this.passingCountWeekTotal[String(this.useOpeningHours)] += report['base'];
            this.entranceCountWeekTotal[String(this.useOpeningHours)] += report['peelOff'];
          });
          this.chartDataRate[String(this.useOpeningHours)] = rate;
          this.chartDataPassingCount[String(this.useOpeningHours)] = passingCount;
          this.chartDataEntranceCount[String(this.useOpeningHours)] = entranceCount;
          this.weekConversion[String(this.useOpeningHours)] =
            Math.trunc((rate.reduce((acc, cur) => acc + cur, 0) / rate.length) * 100) / 100;
          this.todayConversionChange[String(this.useOpeningHours)] =
            this.todayConversion[String(this.useOpeningHours)] - lastWeekConversion;
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  private drawChart(): void {
    const chartOptions = this.getChartOptions();
    const chart = new ApexCharts(this.chart.nativeElement, chartOptions);
    chart.render();
  }

  private removeAllChildNodes(element: HTMLElement) {
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  private getChartOptions(): ApexCharts.ApexOptions {
    return {
      chart: {
        toolbar: { show: false },
        width: '98%',
        height: this.viewHeight,
        zoom: { enabled: false },
      },
      series: [
        {
          name: 'Rate',
          type: 'line',
          data: this.chartDataRate[String(this.useOpeningHours)],
        },
        {
          name: 'Passing',
          type: 'column',
          data: this.chartDataPassingCount[String(this.useOpeningHours)],
        },
        {
          name: 'Entrance',
          type: 'column',
          data: this.chartDataEntranceCount[String(this.useOpeningHours)],
        },
      ],
      dataLabels: {
        formatter: function (val) {
          return val ? String(val) : '';
        },
      },
      colors: [
        function ({ value, seriesIndex, dataPointIndex, w }) {
          switch (seriesIndex) {
            case 0: {
              return 'var(--color-turquoise)';
            }
            case 1: {
              return dataPointIndex == 7 ? 'var(--color-violet--light)' : 'var(--color-violet)';
            }
            case 2: {
              return dataPointIndex == 7 ? 'var(--color-mikado--light-2)' : 'var(--color-mikado)';
            }
          }
          return '';
        },
      ],

      xaxis: {
        categories: this.xaxis,
        labels: {
          formatter: function (value, timestamp, opts) {
            if (!value) {
              return '';
            }
            return moment(value).isSame(new Date(), 'day') ? 'Today' : formatDate(new Date(value), 'EEE', 'en_US');
          },
        },
      },
      yaxis: [
        {
          seriesName: 'Rate',
          labels: {
            formatter: (value) => value.toFixed(2) + '%',
          },
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: 'Rate',
          },
        },
        {
          seriesName: 'Passing',
          labels: {
            formatter: (value) => String(value),
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: 'Passing / Entrance',
          },
        },
        {
          seriesName: 'Passing',
          labels: {
            formatter: (value) => String(value),
          },
          show: false,
        },
      ],
      grid: {
        show: true,
      },
      stroke: { width: 2, curve: 'monotoneCubic' },
      markers: { size: 4 },
      legend: { show: false },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          borderRadiusApplication: 'end',
          columnWidth: '90%',
        },
      },
    };
  }

  setChartData() {
    if (!this.chart) {
      return;
    }
    this.removeAllChildNodes(this.chart.nativeElement);
    this.isLoadingChart$.next(true);
    if (!this.chartDataRate[String(this.useOpeningHours)]) {
      this.getReportData();
    } else {
      this.drawChart();
      this.isLoadingChart$.next(false);
    }
  }

  openEditPeelOffMonitorDialog(isRedirect?: boolean): void {
    const dialogRef = this.matDialog.open(AddEditPeelOffMonitorComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        cameras: this.monitorsService.cameras,
        sites: [this.site],
        peelOffMonitor: this.monitor,
        camerasPositionsMap: this.camerasPositionsMap,
        isRedirect: isRedirect,
        stepsTitle: 'Edit Peel Off Monitor',
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  yAxisTickFormatting(value: number): string {
    return value.toFixed(1) + '%';
  }

  xAxisTickFormatting(date: string): string {
    return moment(date).format('DD ddd');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }

  protected readonly String = String;
}
