import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-copy-text',
  templateUrl: './copy-text.component.html',
  styleUrls: ['./copy-text.component.scss'],
})
export class CopyTextComponent {
  @ViewChild('tooltip', { static: false }) tooltip: MatTooltip;
  @Input() copyText: string;
  @Input() displayText: string;
  @Input() disabled: boolean;
  @Input() tooltipMessage: string;

  constructor(
    private clipboard: Clipboard,
    private ref: ChangeDetectorRef,
  ) {}

  copyLink(): void {
    if (this.disabled) return;
    this.ref.detectChanges();
    this.tooltip.show();
    const oldTooltipMessage = this.tooltipMessage;
    this.tooltipMessage = 'Link copied';
    this.clipboard.copy(this.copyText);

    setTimeout(() => {
      this.tooltipMessage = oldTooltipMessage;
      this.ref.detectChanges();
    }, 1000);
  }
}
