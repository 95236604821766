import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subject, finalize, forkJoin, take, takeUntil } from 'rxjs';
import { Alert, CameraService, LiveOccupancy, OccupancyMonitor, ReportService, Site } from 'src/app/api';
import { EditOccupancyMonitorComponent } from 'src/app/components/monitors/edit-occupancy-monitor/edit-occupancy-monitor.component';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { AccountService } from 'src/app/services/account.service';
import { NotifyService } from 'src/app/services/notify.service';
import * as ApexCharts from 'apexcharts';
import { MonitorsService } from 'src/app/services/monitors.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-occupancy-monitor-details',
  templateUrl: './occupancy-monitor-details.component.html',
  styleUrls: ['./occupancy-monitor-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OccupancyMonitorDetailsComponent implements OnInit, OnDestroy {
  monitorId = '';
  graphData = [];

  currentDate;
  aWeekAgoDate;
  site: Site;
  monitor: OccupancyMonitor;
  occupancyAlerts: Alert[] = [];
  liveOccupancy: LiveOccupancy;

  monitorCameras = [];
  camerasPositions = {};
  cameraFrame = {};

  isLoadingCameraFrame = {};
  isLoading$ = new BehaviorSubject<boolean>(true);
  isLoadingChart$ = new BehaviorSubject<boolean>(true);
  useOpeningHours = false;
  chartData: { [key in 'true' | 'false']: [] } = { true: undefined, false: undefined };
  xaxis;

  viewHeight: number;
  private chart: ElementRef;
  @ViewChild('chart') set content(content: ElementRef) {
    if (content && !this.chart) {
      this.chart = content;
      this.viewHeight = this.chart.nativeElement.offsetHeight;
      this.setChartData();
    }
  }

  private todayChart: ElementRef;
  @ViewChild('todayChart') set todayContent(content: ElementRef) {
    if (content && !this.todayChart) {
      this.todayChart = content;
      this.setChartData();
    }
  }

  dateFrom;
  dateTo;

  private ngUnsubscribe = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private cameraService: CameraService,
    private notifyService: NotifyService,
    private ref: ChangeDetectorRef,
    public accountService: AccountService,
    public monitorsService: MonitorsService,
    private reportService: ReportService,
  ) {}

  ngOnInit(): void {
    this.currentDate = new Date();
    this.aWeekAgoDate = new Date().setDate(this.currentDate.getDate() - 6);
    this.route.params.pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe({
      next: (params) => {
        this.monitorId = params?.id;
        this.monitor = this.monitorsService.getOccupancyMonitor(this.monitorId);
        if (this.monitor === undefined) {
          this.router.navigate(['/not-found']);
          return;
        }
        this.site = this.monitorsService.sites.find((s) => s.id === this.monitor.siteId);
        this.monitorCameras = this.monitorsService.occupancyMonitorCameras[this.monitorId];
        this.monitorCameras.forEach((camera) => {
          camera.isPassingTraffic = camera.type === 'Passing traffic';

          this.getCameraFrame(camera);
        });
        this.camerasPositions = this.monitorsService.occupancyMonitorCamerasPositions[this.monitorId];
        // this.occupancyAlerts = this.monitorsService.monitorOccupancyAlerts[this.monitorId];
        this.isLoading$.next(false);

        if (params?.edit) {
          this.openEditOccupancyMonitorDialog(true);
        }
      },
      error: (error) => {
        this.notifyService.error(error);
        this.router.navigate(['internal-error']);
      },
    });
  }

  setChartData() {
    if (!this.chart || !this.todayChart) {
      return;
    }

    this.removeAllChildNodes(this.todayChart.nativeElement);
    this.removeAllChildNodes(this.chart.nativeElement);

    this.isLoadingChart$.next(true);
    if (!this.chartData[String(this.useOpeningHours)]) {
      this.getReportData();
    } else {
      this.drawCharts();
      this.isLoadingChart$.next(false);
    }
  }

  getReportData() {
    this.dateFrom = moment().subtract(7, 'days').format('DD/MM/YYYY');
    this.dateTo = moment().subtract(1, 'day').format('DD/MM/YYYY');
    this.xaxis = [];
    forkJoin([
      this.reportService.createOccupancyReport({
        occupancyMonitorIds: [this.monitorId],
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().add(1, 'day').format('YYYY-MM-DD'),
        timeGrain: '24h',
        metrics: ['occupancy_max', 'occupancy_avg'],
        isOpen: this.useOpeningHours,
      }),
      this.reportService.createLiveOccupancyReport({
        occupancyMonitorIds: [this.monitorId],
      }),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoadingChart$.next(false);
          this.drawCharts();
        }),
      )
      .subscribe({
        next: (reports) => {
          if (reports[0]) {
            const { occupancyMax, occupancyAvg, xaxis } = reports[0].reduce(
              (acc, row) => {
                acc.occupancyMax.push(row.occupancyMax ?? 0);
                acc.occupancyAvg.push(row.occupancyAvg ?? 0);
                acc.xaxis.push(moment(row.time.split('.')[0]).format());
                return acc;
              },
              { occupancyMax: [], occupancyAvg: [], xaxis: [] },
            );

            this.xaxis = xaxis;
            this.chartData[String(this.useOpeningHours)] = { occupancyMax, occupancyAvg };
          }
          this.liveOccupancy = reports[1]?.[0] || null;
        },

        error: (error) => {
          console.log(error);
        },
      });
  }

  private drawCharts(): void {
    const todayChartOptions = this.getTodayChartOptions();
    const todayChart = new ApexCharts(this.todayChart.nativeElement, todayChartOptions);
    todayChart.render();
    const chartOptions = this.getChartOptions();
    const chart = new ApexCharts(this.chart.nativeElement, chartOptions);
    chart.render();
  }

  private getChartOptions(): ApexCharts.ApexOptions {
    return {
      chart: {
        toolbar: { show: false },
        width: '98%',
        height: this.viewHeight,
        zoom: { enabled: false },
      },
      series: [
        {
          name: 'Max occupancy',
          type: 'line',
          data: this.chartData[String(this.useOpeningHours)]['occupancyMax'],
        },
        {
          name: 'Avg occupancy',
          type: 'column',
          data: this.chartData[String(this.useOpeningHours)]['occupancyAvg'],
        },
      ],
      colors: [
        function ({ value, seriesIndex, dataPointIndex, w }) {
          switch (seriesIndex) {
            case 0: {
              return 'var(--color-turquoise)';
            }
            case 1: {
              return dataPointIndex == 7 ? 'var(--color-violet--light)' : 'var(--color-violet)';
            }
          }
          return '';
        },
      ],
      xaxis: {
        categories: this.xaxis,
        labels: {
          formatter: function (value, timestamp, opts) {
            if (!value) {
              return '';
            }
            return moment(value).isSame(new Date(), 'day') ? 'Today' : formatDate(new Date(value), 'EEE', 'en_US');
          },
        },
      },

      yaxis: [
        {
          seriesName: 'Avg occupancy',
          labels: {
            formatter: (value) => String(value),
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: 'Max occupancy / Avg occupancy ',
          },
        },
      ],
      grid: {
        show: true,
      },
      stroke: { width: 2, curve: 'monotoneCubic' },
      markers: { size: 4 },
      legend: { show: false },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          borderRadiusApplication: 'end',
          columnWidth: '90%',
        },
      },
    };
  }

  private getTodayChartOptions(): ApexCharts.ApexOptions {
    const occupancy = this.liveOccupancy && this.liveOccupancy.occupancy ? this.liveOccupancy.occupancy : 0;
    const occupancyPercentage = this.monitor.capacity ? Number((occupancy / this.monitor.capacity) * 100) : 0;
    const series = [occupancyPercentage];

    const colors =
      occupancyPercentage < 75
        ? ['var(--color-secondary)']
        : occupancyPercentage < 90
          ? ['var(--color-orange)']
          : ['var(--color-red)'];
    colors.push('#c9ccd2');

    return {
      series,
      chart: {
        type: 'radialBar',
        offsetY: -10,
      },
      colors,
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: '11px',
              color: '#4d5050',
              offsetY: 80,
            },
            value: {
              fontSize: '33px',
              color: '#373d3f',
              formatter: function (val) {
                return String(occupancy);
              },
            },
          },
        },
      },
      stroke: {
        width: 0,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      labels: [this.atString()],
    };
  }

  private removeAllChildNodes(element: HTMLElement) {
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  getCameraFrame(camera: CameraStatus): void {
    if (camera.state === 'running' && camera.isOnline) {
      this.isLoadingCameraFrame[camera.id] = true;
      this.ref.detectChanges();

      this.cameraService
        .createFrameRequest({ cameraId: camera.id })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (frame) => {
            this.cameraFrame[camera.id] = 'data:image/jpeg;base64,' + frame.image;
            this.isLoadingCameraFrame[camera.id] = false;
            this.ref.detectChanges();
          },
          error: (error) => {
            delete this.isLoadingCameraFrame[camera.id];
            this.ref.detectChanges();
            this.notifyService.error(error);
          },
        });
    }
  }

  openEditOccupancyMonitorDialog(isRedirect?: boolean): void {
    const dialogRef = this.matDialog.open(EditOccupancyMonitorComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        cameras: this.monitorsService.cameras.filter((c) => c.serialNumber && c.type === 'Overhead'),
        occupancyAlerts: this.occupancyAlerts,
        monitor: this.monitor,
        monitorCameras: this.monitorCameras,
        cameraPositions: this.camerasPositions,
        isRedirect: isRedirect,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  atString(): string {
    return this.liveOccupancy ? moment(this.liveOccupancy.time.split('.')[0]).format('lll') : '';
  }

  isDateToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
