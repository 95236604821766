import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, filter, finalize, forkJoin, of, takeUntil } from 'rxjs';
import {
  CameraService,
  DwellTimeReportService,
  OccupancyMonitor,
  OccupancyMonitorCameraPosition,
  OccupancyMonitorCameraPositionService,
  OccupancyMonitorService,
  PeelOffMonitor,
  PeelOffMonitorCameraPosition,
  PeelOffMonitorCameraPositionService,
  PeelOffMonitorService,
  PeopleCountMonitor,
  PeopleCountMonitorCameraPosition,
  PeopleCountMonitorCameraPositionService,
  PeopleCountMonitorService,
  SiteService,
} from 'src/app/api';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { OccupancyMonitorStatus } from 'src/app/model/monitorStatus';
import { AccountService } from 'src/app/services/account.service';
import { CamerasService } from 'src/app/services/cameras.service';
import { MonitorsService } from 'src/app/services/monitors.service';
import { NotifyService } from 'src/app/services/notify.service';
import { ReportsService } from 'src/app/services/reports.service';

const loadNextMonitorsQuantity = 10;

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
})
export class ReportingComponent implements OnInit, OnDestroy {
  filteredCapacityManagementMonitors: OccupancyMonitorStatus[];
  selectedCapacityManagementMonitor: OccupancyMonitorStatus;
  capacityManagementSearchString = '';
  capacityManagementData: {
    [_: string]: {
      [key in 'true' | 'false']: { isLoading$: BehaviorSubject<boolean>; data: any[]; lastUpdate: Date };
    };
  } = {};
  activedCapacityManagementMonitorsLimit = loadNextMonitorsQuantity;

  isLoading$ = new BehaviorSubject(true);
  hasActiveChildRoute$ = new BehaviorSubject(false);

  peopleCountMonitorDataWeeklyTimeEndDate;

  childRoutes = {
    'people-count': { '': 'People Count Reporting' },
    'capacity-management/:id': { '': 'Occupancy Distribution' },
    'dwell-time-reports/:id': { '': 'Review Dwell Time Report' },
    'report-csv-download': {
      people_count: 'People Count Downloads',
      occupancy: 'Occupancy Downloads',
      peel_off: 'Peel Off Downloads',
      dwell_time: 'Dwell Time Downloads',
      feedbacks: 'Feedbacks',
    },
    'data-explorer': {
      'current-occupancy': 'Real Time Occupancy',
      'time-series': 'Time Series',
      'time-comparison': 'Time Comparison',
      seasonality: 'Seasonality',
      maps: 'Maps',
    },
  };
  activeChildRoute = '';

  hasSites = false;
  @ViewChild('capacityManagementList') capacityManagementList: ElementRef;

  private ngUnsubscribe = new Subject();

  constructor(
    public reportsService: ReportsService,
    public accountService: AccountService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private siteService: SiteService,
    private cameraService: CameraService,
    private occupancyMonitorService: OccupancyMonitorService,
    private peopleCountMonitorService: PeopleCountMonitorService,
    private occupancyMonitorCameraPositionService: OccupancyMonitorCameraPositionService,
    private peopleCountMonitorCameraPositionService: PeopleCountMonitorCameraPositionService,
    private camerasService: CamerasService,
    private monitorsService: MonitorsService,
    private notifyService: NotifyService,
    private peelOffMonitorService: PeelOffMonitorService,
    private peelOffMonitorCameraPositionService: PeelOffMonitorCameraPositionService,
    private dwellTimeReportService: DwellTimeReportService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getData();

    this.checkActiveChildRoute();
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.checkActiveChildRoute();
    });
  }

  private checkActiveChildRoute(): void {
    const hasChild = this.activatedRoute.firstChild !== null;
    this.hasActiveChildRoute$.next(hasChild);
    const path = this.activatedRoute.firstChild?.snapshot.routeConfig.path;
    if (path) {
      const tab = this.activatedRoute.firstChild?.snapshot.queryParams.tab;
      this.activeChildRoute = tab ? this.childRoutes[path][tab] : this.childRoutes[path][''];
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }

  getData(): void {
    this.isLoading$.next(true);
    this.ref.detectChanges();

    if (this.accountService.dataExplorerLicence) {
      let appMetadata = this.accountService.user['app_metadata'];
      if (appMetadata['tableau_username']) {
        this.reportsService.hasDataExplorer = true;
      }
    }

    const requests: Observable<any>[] = [
      this.siteService.listSites(),
      this.cameraService.listCameras(true, undefined, undefined, ['running', 'paused']),
      this.peopleCountMonitorService.listPeopleCountMonitors(undefined, undefined, undefined, 'active'),
      this.peopleCountMonitorCameraPositionService.listPeopleCountMonitorCameraPositions(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
    ];

    if (this.accountService.occupancyLicence) {
      requests.push(
        this.occupancyMonitorService.listOccupancyMonitors(undefined, undefined, undefined, 'active'),
        this.occupancyMonitorCameraPositionService.listOccupancyMonitorCameraPositions(
          undefined,
          undefined,
          undefined,
          undefined,
          'active',
        ),
      );
    } else {
      requests.push(of(undefined), of(undefined));
    }

    requests.push(
      this.peelOffMonitorService.listPeelOffMonitors(undefined, undefined, undefined, 'active'),
      this.peelOffMonitorCameraPositionService.listPeelOffMonitorCameraPositions(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
    );

    if (this.accountService.occupancyLicence) {
      requests.push(this.dwellTimeReportService.listDwellTimeReports());
    } else {
      requests.push(of(undefined));
    }

    forkJoin(requests)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {
          this.isLoading$.next(false);
          this.ref.detectChanges();
        }),
      )
      .subscribe({
        // [0-sites, 1-cameras,
        // 2-peopleCountMonitors, 3-peopleCountMonitorCameraPositions,
        // 4-occupancyMonitors, 5-occupancyMonitorCameraPositions,
        // 6-peelOffMonitor, 7-peelOffMonitorCameraPosition,
        // 8-dwellTimeRequestResponse]
        next: (respone: any[]) => {
          const sitesMap = {};
          const sites = respone[0];
          sites.forEach((site) => {
            sitesMap[site.id] = site;
          });
          this.hasSites = !!sites.length;

          let cameras = respone[1];
          cameras = cameras
            .map((c) => this.camerasService.getCameraStatus(c))
            .sort((c1, c2) => {
              if (!c1.name) {
                c1.name = '-';
              }
              if (!c2.name) {
                c2.name = '-';
              }
              if (c1.siteId === c2.siteId) {
                return c1.name.toLowerCase().localeCompare(c2.name.toLowerCase());
              } else {
                return sitesMap[c1.siteId].name.toLowerCase().localeCompare(sitesMap[c2.siteId].name.toLowerCase());
              }
            });

          const camerasMapByCameraPositionId: { [_: string]: CameraStatus } = {};
          cameras.forEach((camera) => {
            camerasMapByCameraPositionId[camera.cameraPositionId] = camera;
          });

          // process people count monitors
          let peopleCountMonitors: PeopleCountMonitor[];
          let peopleCountMonitorCameraPositions: PeopleCountMonitorCameraPosition[];
          let peopleCountMonitorsMap: { [_: string]: PeopleCountMonitor } = {};

          const peopleCountMonitorCameraPositionsMap: { [_: string]: PeopleCountMonitorCameraPosition[] } = {};
          peopleCountMonitors = respone[2];
          peopleCountMonitorCameraPositions = respone[3];

          peopleCountMonitors.forEach((peopleCountMonitors) => {
            peopleCountMonitorsMap[peopleCountMonitors.id] = peopleCountMonitors;
            peopleCountMonitorCameraPositionsMap[peopleCountMonitors.id] = [];
          });

          peopleCountMonitorCameraPositions.forEach((peopleCountMonitorCameraPosition) => {
            if (
              peopleCountMonitorCameraPositionsMap[peopleCountMonitorCameraPosition.peopleCountMonitorId] !== undefined
            ) {
              peopleCountMonitorCameraPositionsMap[peopleCountMonitorCameraPosition.peopleCountMonitorId].push(
                peopleCountMonitorCameraPosition,
              );
            }
          });
          peopleCountMonitors = peopleCountMonitors.map((pcm) =>
            this.monitorsService.getPeopleCountMonitorStatus(
              pcm,
              peopleCountMonitorCameraPositionsMap[pcm.id]
                .map((pcmcp) => camerasMapByCameraPositionId[pcmcp.cameraPositionId])
                .filter(Boolean),
              sitesMap[pcm.siteId],
            ),
          );
          // process occupancy monitors
          let occupancyMonitors: OccupancyMonitor[];
          let occupancyMonitorCameraPositions: OccupancyMonitorCameraPosition[];
          let occupancyMonitorsMap: { [_: string]: OccupancyMonitor } = {};
          const occupancyMonitorCameraPositionsMap: { [_: string]: OccupancyMonitorCameraPosition[] } = {};
          if (this.accountService.occupancyLicence) {
            occupancyMonitors = respone[4];
            occupancyMonitorCameraPositions = respone[5];

            occupancyMonitors.forEach((occupancyMonitor) => {
              occupancyMonitorsMap[occupancyMonitor.id] = occupancyMonitor;
              occupancyMonitorCameraPositionsMap[occupancyMonitor.id] = [];
            });

            occupancyMonitorCameraPositions.forEach((occupancyMonitorCameraPosition) => {
              if (occupancyMonitorCameraPositionsMap[occupancyMonitorCameraPosition.occupancyMonitorId] !== undefined) {
                occupancyMonitorCameraPositionsMap[occupancyMonitorCameraPosition.occupancyMonitorId].push(
                  occupancyMonitorCameraPosition,
                );
              }
            });
            occupancyMonitors = occupancyMonitors.map((om) =>
              this.monitorsService.getOccupancyMonitorStatus(
                om,
                occupancyMonitorCameraPositionsMap[om.id]
                  .map((omcp) => camerasMapByCameraPositionId[omcp.cameraPositionId])
                  .filter(Boolean),
                sitesMap[om.siteId],
              ),
            );
          }

          // process peel off monitors
          let peelOffMonitors: PeelOffMonitor[];
          let peelOffMonitorCameraPositions: PeelOffMonitorCameraPosition[];
          let peelOffMonitorsMap: { [_: string]: PeelOffMonitor } = {};
          const peelOffMonitorCameraPositionsMap: { [_: string]: PeelOffMonitorCameraPosition[] } = {};

          peelOffMonitors = respone[6];
          peelOffMonitorCameraPositions = respone[7];

          peelOffMonitors.forEach((peelOffMonitor) => {
            peelOffMonitorsMap[peelOffMonitor.id] = peelOffMonitor;
            peelOffMonitorCameraPositionsMap[peelOffMonitor.id] = [];
          });

          peelOffMonitorCameraPositions.forEach((peelOffMonitorCameraPosition) => {
            if (peelOffMonitorCameraPositionsMap[peelOffMonitorCameraPosition.peelOffMonitorId] !== undefined) {
              peelOffMonitorCameraPositionsMap[peelOffMonitorCameraPosition.peelOffMonitorId].push(
                peelOffMonitorCameraPosition,
              );
            }
          });
          peelOffMonitors = peelOffMonitors.map((pom) =>
            this.monitorsService.getPeelOffMonitorStatus(
              pom,
              peelOffMonitorCameraPositionsMap[pom.id]
                .map((pomcp) => camerasMapByCameraPositionId[pomcp.cameraPositionId])
                .filter(Boolean),
              sitesMap[pom.siteId],
            ),
          );

          this.reportsService.reportDownloadMenuItems = [];

          if (this.accountService.peopleCountLicence) {
            this.reportsService.reportDownloadMenuItems.push('people_count');
          }
          if (this.accountService.occupancyLicence) {
            this.reportsService.reportDownloadMenuItems.push('occupancy');

            this.reportsService.dataExplorerMenuItems = [
              'current-occupancy',
              'time-series',
              'time-comparison',
              'seasonality',
            ];
          } else {
            this.reportsService.dataExplorerMenuItems = ['time-series', 'time-comparison', 'seasonality'];
          }

          this.reportsService.reportDownloadMenuItems.push('peel_off');

          if (
            this.accountService.occupancyLicence ||
            this.accountService.dwellTimeLicence ||
            this.accountService.isSupport
          ) {
            this.reportsService.reportDownloadMenuItems.push('dwell_time');
            this.reportsService.dwellTimeRequestResponse = respone[8];
          }

          if (this.accountService.isSupport) {
            this.reportsService.dataExplorerMenuItems.push('maps');
            this.reportsService.reportDownloadMenuItems.push('feedbacks');
          }

          this.reportsService.cameras = cameras;
          this.reportsService.sitesMap = sitesMap;
          this.reportsService.camerasMapByCameraPositionId = camerasMapByCameraPositionId;
          this.reportsService.peopleCountMonitors = peopleCountMonitors.sort((objA, objB) => {
            if (sitesMap[objA.siteId].name < sitesMap[objB.siteId].name) return -1;
            if (sitesMap[objA.siteId].name > sitesMap[objB.siteId].name) return 1;
            return 0;
          });
          this.reportsService.peopleCountMonitorCameraPositionsMap = peopleCountMonitorCameraPositionsMap;
          this.reportsService.peopleCountMonitorsMap = peopleCountMonitorsMap;

          if (this.accountService.occupancyLicence) {
            this.reportsService.occupancyMonitors = occupancyMonitors.sort((objA, objB) => {
              if (sitesMap[objA.siteId].name < sitesMap[objB.siteId].name) return -1;
              if (sitesMap[objA.siteId].name > sitesMap[objB.siteId].name) return 1;
              return 0;
            });

            this.reportsService.occupancyMonitorCameraPositionsMap = occupancyMonitorCameraPositionsMap;
            this.reportsService.occupancyMonitorsMap = occupancyMonitorsMap;
          }

          this.reportsService.peelOffMonitors = peelOffMonitors.sort((objA, objB) => {
            if (sitesMap[objA.siteId].name < sitesMap[objB.siteId].name) return -1;
            if (sitesMap[objA.siteId].name > sitesMap[objB.siteId].name) return 1;
            return 0;
          });

          this.reportsService.peelOffMonitorCameraPositionsMap = peelOffMonitorCameraPositionsMap;
          this.reportsService.peelOffMonitorsMap = peelOffMonitorsMap;

          this.reportsService.peopleCountMonitorDataDailyTime = new Date();
          this.reportsService.getPeopleCountMonitorOrderData();

          if (this.accountService.occupancyLicence) {
            this.filterCapacityManagementMonitors();
          }
        },
        error: (error) => {
          this.notifyService.error(error);
          this.router.navigate(['internal-error']);
        },
      });
  }

  onCapacityManagementScroll(): void {
    const element = this.capacityManagementList.nativeElement;

    const isScrolledToBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) <= 1;
    const isAllCamerasLoaded =
      this.activedCapacityManagementMonitorsLimit >= this.reportsService.occupancyMonitors.length;

    if (isScrolledToBottom && !isAllCamerasLoaded) {
      this.activedCapacityManagementMonitorsLimit += loadNextMonitorsQuantity;
      this.filterCapacityManagementMonitors();
    }
  }

  filterCapacityManagementMonitors(): void {
    let ss = '';
    if (this.capacityManagementSearchString) {
      ss = this.capacityManagementSearchString.toLocaleLowerCase();
    }
    this.filteredCapacityManagementMonitors = this.reportsService.occupancyMonitors
      .filter((occupancyMonitor: OccupancyMonitor) => {
        return (
          occupancyMonitor.name.toLowerCase().includes(ss) ||
          this.reportsService.sitesMap[occupancyMonitor.siteId].name.includes(ss)
        );
      })
      .slice(0, this.activedCapacityManagementMonitorsLimit);
    this.filteredCapacityManagementMonitors.forEach((occupancyMonitor) => {
      if (
        !this.capacityManagementData[occupancyMonitor.id] ||
        !this.capacityManagementData[occupancyMonitor.id][
          String(this.reportsService.useOpeningHoursCapacityManagementMonitors)
        ]
      ) {
        this.reportsService.getCapacityManagementData(occupancyMonitor).then((capacityManagementData) => {
          this.capacityManagementData[occupancyMonitor.id] = capacityManagementData;
          this.ref.detectChanges();
        });
      }
    });

    if (this.filteredCapacityManagementMonitors.length) {
      this.selectedCapacityManagementMonitor = this.filteredCapacityManagementMonitors[0];
    }
  }

  filterByOpeningHours() {
    this.reportsService.useOpeningHoursCapacityManagementMonitors =
      !this.reportsService.useOpeningHoursCapacityManagementMonitors;
    this.filterCapacityManagementMonitors();
  }

  updatePeopleCountMonitorDataWeeklyTime() {
    this.peopleCountMonitorDataWeeklyTimeEndDate = new Date(
      this.reportsService.peopleCountMonitorDataWeeklyTime.getTime() + 6 * 24 * 60 * 60 * 1000,
    );
    this.toggleTimingPeopleCount(false);
    this.ref.detectChanges();
  }

  toggleTimingPeopleCount(value: boolean): void {
    this.reportsService.isPeopleCountDaily = value;
    this.reportsService.getPeopleCountMonitorOrderData();
  }
}
