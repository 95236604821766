import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { SiteDetailsService } from 'src/app/services/site-details.service';

@Component({
  selector: 'app-site-cameras',
  templateUrl: './site-cameras.component.html',
  styleUrls: ['./site-cameras.component.scss'],
})
export class SiteCamerasComponent implements OnInit, OnDestroy {
  monitorId = '';

  cameras = [];
  inUseCameras = [];
  pendingCameras = [];
  sitesMap = {};

  private ngUnsubscribe = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private siteDetailsService: SiteDetailsService,
  ) {}

  ngOnInit(): void {
    this.cameras = this.siteDetailsService.cameras;
    this.inUseCameras = this.siteDetailsService.inUseCameras;
    this.pendingCameras = this.siteDetailsService.pendingCameras;
    this.sitesMap[this.siteDetailsService.site.id] = this.siteDetailsService.site;

    this.route.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (params) => {
        this.monitorId = params?.monitorId;
      },
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
