import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { formatDate, formatNumber } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';
import { Site } from 'src/app/api';
import { GlobalMethods } from 'src/app/global-methods';
import { ReportsService } from 'src/app/services/reports.service';
import { PeopleCountMonitorCardDialogComponent } from 'src/app/views/reporting/components/people-count-monitor-card-dialog/people-count-monitor-card-dialog.component';
import { PeopleCountMonitorStatus } from 'src/app/model/monitorStatus';
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from 'src/app/constants';

type peopleCountsEnum = 'direction_1' | 'direction_2' | 'total';
const peopleCountsEnum: { [key: string]: peopleCountsEnum } = {
  direction1: 'direction_1',
  direction2: 'direction_2',
  total: 'total',
};

@Component({
  selector: 'app-people-count-monitor-card',
  templateUrl: './people-count-monitor-card.component.html',
  styleUrls: ['./people-count-monitor-card.component.scss'],
})
export class PeopleCountMonitorCardComponent implements OnChanges, OnDestroy, OnInit {
  @Input() site: Site;
  @Input() peopleCountMonitor: PeopleCountMonitorStatus;
  @Input() dataStartDate: Date = new Date();
  @Input() disableShowingPopup = false;
  @Input() showChartForMobile = false;
  @Input() useOpeningHours = false;

  isLoading$ = new BehaviorSubject<boolean>(true);
  isMobile: boolean;
  peopleCountVerticalChartColorScheme: Color = {
    name: 'SchemePieChart',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#FDC00B', '#FFE8AF'],
  };

  chartResults: any[] = [];
  peopleCountsEnum = peopleCountsEnum;
  activeTimeCounts;

  activeDirection: peopleCountsEnum = this.peopleCountsEnum.direction1;
  activeDirectionDataTotal: number;
  activeDirectionDataTotalLastWeek: number;
  activeDirectionDataPercentage: number;

  directionPropToDirectionNameMap: { [key: string]: string };

  view: [number, number] = [2000, 200];
  private chartArea: ElementRef;
  @ViewChild('chartArea') set content(content: ElementRef) {
    if (content) {
      this.chartArea = content;
    }
  }

  showLeftScroll;
  showRightScroll;

  readonly breakpoint$ = this.breakpointObserver.observe([TABLET_BREAKPOINT]);
  private ngUnsubscribe = new Subject();

  constructor(
    public reportsService: ReportsService,
    private breakpointObserver: BreakpointObserver,
    private matDialog: MatDialog,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.breakpoint$.subscribe(() => this.breakpointChanged());
    this.directionPropToDirectionNameMap = {
      [peopleCountsEnum.direction1]: this.peopleCountMonitor.direction1Alias,
      [peopleCountsEnum.direction2]: this.peopleCountMonitor.direction2Alias,
      [peopleCountsEnum.total]: `${this.peopleCountMonitor.direction1Alias} & ${this.peopleCountMonitor.direction2Alias}`,
    };
  }

  ngOnChanges(): void {
    this.isLoading$.next(true);
    this.ngUnsubscribe.next(''); // kill existing subscriptions that are stil waiting for isLoading
    this.reportsService
      .getPeopleCountMonitorData(this.peopleCountMonitor, this.dataStartDate)
      .then((peopleCountMonitorData) => {
        this.activeTimeCounts = peopleCountMonitorData;
        this.activeTimeCounts.isLoading$
          .pipe(
            filter((isLoading) => !isLoading),
            takeUntil(this.ngUnsubscribe),
          )
          .subscribe((_) => {
            this.updateDirection(this.activeDirection);
            this.isLoading$.next(false);
            this.ref.detectChanges();
            this.setViewSize();
          });
      });
  }

  private breakpointChanged(): void {
    this.breakpointObserver.observe([MOBILE_BREAKPOINT]).subscribe({
      next: (state: BreakpointState) => {
        this.isMobile = state.matches;
      },
    });
  }

  setViewSize() {
    const chartAreaWidth = this.chartArea.nativeElement.offsetWidth;
    let chartWidth = this.reportsService.isPeopleCountDaily ? 1800 : 900;

    this.view = [Math.max(chartWidth, chartAreaWidth), 160];

    if (this.reportsService.isPeopleCountDaily) {
      this.chartArea.nativeElement.scrollTo({
        left: 75 * new Date().getHours() - chartAreaWidth + 75 * 8,
        behavior: 'smooth',
      });
    }
    this.computeScrollIcons();
  }

  computeScrollIcons(): void {
    const element = this.chartArea.nativeElement;
    this.showLeftScroll = element.scrollLeft > 0;
    this.showRightScroll = Math.abs(element.scrollWidth - element.scrollLeft - element.clientWidth) > 2;
  }

  scrollRight(): void {
    this.chartArea.nativeElement.scrollTo({
      left: this.chartArea.nativeElement.scrollLeft + this.chartArea.nativeElement.clientWidth * 0.4,
      behavior: 'smooth',
    });
  }

  scrollLeft(): void {
    this.chartArea.nativeElement.scrollTo({
      left: this.chartArea.nativeElement.scrollLeft - this.chartArea.nativeElement.clientWidth * 0.4,
      behavior: 'smooth',
    });
  }

  formatDate24h(date: string): string {
    return GlobalMethods.formatTime24h(date);
  }

  formatDateDayName(date: string): string {
    return formatDate(date, 'EE dd', 'en-us');
  }

  formatDateDayMonthYear(date: string): string {
    return new Date(date).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric' });
  }

  dataLabelFormatting(x): string {
    if (x === 0) return '-';
    return formatNumber(x, 'en-us');
  }

  updateDirection(peopleCounts: peopleCountsEnum): void {
    this.activeDirection = peopleCounts;

    this.chartResults = this.activeTimeCounts[peopleCounts];
    this.activeDirectionDataTotal = 0;
    this.activeDirectionDataTotalLastWeek = 0;
    this.chartResults.find((result) => {
      this.activeDirectionDataTotal += Number(result.series[0].value) || 0;
      this.activeDirectionDataTotalLastWeek += Number(result.series[1].value) || 0;
    });
    this.activeDirectionDataPercentage = this.computePercantage(
      this.activeDirectionDataTotal,
      this.activeDirectionDataTotalLastWeek,
    );
  }

  computePercantage(toCompute: number, ofTotal: number): number {
    return Math.min(Math.trunc(((toCompute - ofTotal) * 1000) / ofTotal) / 10, 100);
  }

  computeTemplatePercantage(series): number {
    return this.computePercantage(this.getResultsSeries(series)[0].value, this.getResultsSeries(series)[1].value);
  }

  getResultsSeries(seriesName: string): any[] {
    return this.activeTimeCounts[this.activeDirection].find((data) => data.name === seriesName).series;
  }

  openDialog(): void {
    if (!this.isMobile || this.disableShowingPopup) {
      return;
    }

    this.matDialog.open(PeopleCountMonitorCardDialogComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: { peopleCountMonitor: this.peopleCountMonitor },
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
