import { Injectable } from '@angular/core';
import {
  OccupancyMonitor,
  OccupancyMonitorCameraPosition,
  PeelOffMonitor,
  PeelOffMonitorCameraPosition,
  PeopleCountMonitor,
  PeopleCountMonitorCameraPosition,
  Site,
} from 'src/app/api';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { OccupancyMonitorStatus, PeelOffMonitorStatus, PeopleCountMonitorStatus } from 'src/app/model/monitorStatus';

@Injectable({
  providedIn: 'root',
})
export class MonitorsService {
  monitorsType = {
    people_count: 0,
    peel_off: 1,
    occupancy: 2,
  };
  monitorsTypeValue = {
    0: 'people_count',
    1: 'peel_off',
    2: 'occupancy',
  };
  selectedTabIndex = 0;

  site: Site;
  cameras: CameraStatus[] = [];
  occupancyMonitors: OccupancyMonitorStatus[] = [];
  peopleCountMonitors: PeopleCountMonitorStatus[] = [];
  peelOffMonitors: PeelOffMonitorStatus[] = [];
  mustContainString = '';
  filteredOccupancyMonitors: OccupancyMonitorStatus[];
  filteredPeopleCountMonitors: PeopleCountMonitorStatus[];
  filteredPeelOffMonitors: PeelOffMonitorStatus[] = [];

  peopleCountMonitorCameras: { [_: string]: CameraStatus[] } = {};
  peelOffMonitorCameras: { [_: string]: CameraStatus[] } = {};
  occupancyMonitorCameras: { [_: string]: CameraStatus[] } = {};
  occupancyMonitorCamerasPositions: {
    [_: string]: {
      [_: string]: OccupancyMonitorCameraPosition;
    };
  } = {};
  peopleCountMonitorCamerasPositions: {
    [_: string]: {
      [_: string]: PeopleCountMonitorCameraPosition;
    };
  } = {};
  peelOffMonitorCamerasPositions: {
    [_: string]: {
      [_: string]: PeelOffMonitorCameraPosition;
    };
  } = {};

  peelOffMonitorsWithBase = new Set<string>();
  peelOffMonitorsWithPeelOff = new Set<string>();

  sites: Site[] = [];

  getOccupancyMonitor(id: string): OccupancyMonitorStatus {
    return this.occupancyMonitors.find((om) => om.id === id);
  }

  getPeopleCountMonitor(id: string): PeopleCountMonitorStatus {
    return this.peopleCountMonitors.find((pcm) => pcm.id === id);
  }

  getPeelOffMonitor(id: string): PeelOffMonitorStatus {
    return this.peelOffMonitors.find((pom) => pom.id === id);
  }

  public getOccupancyMonitorStatus(
    occupancyMonitor: OccupancyMonitor,
    cameras?: CameraStatus[],
    site?: Site,
  ): OccupancyMonitorStatus {
    const om: OccupancyMonitorStatus = occupancyMonitor;
    if (!om.capacity) {
      om.capacity = undefined;
    }

    if (site) {
      om.siteName = site.name;
      om.organisationId = site.organisationId;
    }

    om.status = 'online';

    cameras?.forEach((c) => {
      if (c.status === 'lagging' || c.status === 'missing' || c.status === 'offline') {
        om.status = 'issue';
      }
    });

    return om;
  }

  public getPeopleCountMonitorStatus(
    peopleCountMonitor: PeopleCountMonitor,
    cameras?: CameraStatus[],
    site?: Site,
  ): PeopleCountMonitorStatus {
    const pcm: PeopleCountMonitorStatus = peopleCountMonitor;

    if (site) {
      pcm.siteName = site.name;
      pcm.organisationId = site.organisationId;
    }

    pcm.status = 'online';

    cameras?.forEach((c) => {
      if (c.status === 'lagging' || c.status === 'missing' || c.status === 'offline') {
        pcm.status = 'issue';
      }
    });

    return pcm;
  }

  public getPeelOffMonitorStatus(
    peelOffMonitor: PeelOffMonitor,
    cameras?: CameraStatus[],
    site?: Site,
  ): PeelOffMonitorStatus {
    const pom: PeelOffMonitorStatus = peelOffMonitor;

    if (site) {
      pom.siteName = site.name;
      pom.organisationId = site.organisationId;
    }

    pom.status = 'online';

    cameras?.forEach((c) => {
      if (c.status === 'lagging' || c.status === 'missing' || c.status === 'offline') {
        pom.status = 'issue';
      }
    });

    return pom;
  }
}
