import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Alert,
  AlertCameraPositionMapping,
  OccupancyMonitor,
  PeopleCountMonitor,
  Site,
  SiteService,
} from 'src/app/api';
import { SiteDetailsService } from 'src/app/services/site-details.service';
import { AddOccupancyMonitorComponent } from 'src/app/components/monitors/add-occupancy-monitor/add-occupancy-monitor.component';
import { AddPeopleCountMonitorComponent } from 'src/app/components/monitors/add-people-count-monitor/add-people-count-monitor.component';
import { AccountService } from 'src/app/services/account.service';
import { GlobalMethods } from 'src/app/global-methods';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { CamerasService } from 'src/app/services/cameras.service';
import { CameraSetupDialogComponent } from 'src/app/components/cameras/camera-setup-dialog/camera-setup-dialog.component';
import { AddEditPeelOffMonitorComponent } from 'src/app/components/monitors/add-edit-peel-off-monitor/add-edit-peel-off-monitor.component';
import { AddEditSiteComponent } from 'src/app/components/sites/add-edit-site/add-edit-site.component';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { Subject, takeUntil } from 'rxjs';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-site-info',
  templateUrl: './site-info.component.html',
  styleUrls: ['./site-info.component.scss'],
})
export class SiteInfoComponent implements OnInit, OnDestroy {
  siteId;
  site: Site;
  sites: Site[] = [];
  cameras: CameraStatus[] = [];
  camerasMapByPositionId: { [_: string]: CameraStatus } = {};
  inUseCameras = [];
  pendingCameras = [];
  occupancyMonitors = [];
  peopleCountMonitors = [];
  peelOffMonitors = [];
  occupancyMonitorsCameras: { [_: string]: CameraStatus[] } = {};
  peopleCountMonitorsCameras: { [_: string]: CameraStatus[] } = {};
  peelOffMonitorsCameras: { [_: string]: CameraStatus[] } = {};
  offlineAlerts: Alert[] = [];
  summaryAlerts: Alert[] = [];
  offlineAlertCameraPositions: AlertCameraPositionMapping[] = [];
  summaryAlertCameraPositions: AlertCameraPositionMapping[] = [];

  hasOccupancyLicence: boolean;

  isSupport: boolean;
  supportEmail: string = 'mailto:support@hoxtonanalytics.com';

  issueCameras: CameraStatus[] = [];
  pausedCameras: CameraStatus[] = [];

  daysOfWeek: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  private ngUnsubscribe = new Subject();

  constructor(
    public accountService: AccountService,
    public siteDetailsService: SiteDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private camerasService: CamerasService,
    private siteService: SiteService,
    private notifyService: NotifyService,
  ) {}

  ngOnInit(): void {
    this.siteId = this.siteDetailsService.site.id;
    this.sites = this.siteDetailsService.sites;
    this.site = this.siteDetailsService.site;
    this.cameras = this.siteDetailsService.cameras;
    this.inUseCameras = this.siteDetailsService.inUseCameras;
    this.pendingCameras = this.siteDetailsService.pendingCameras;
    this.camerasMapByPositionId = this.siteDetailsService.camerasMapByPositionId;
    this.occupancyMonitors = this.siteDetailsService.occupancyMonitors;
    this.peopleCountMonitors = this.siteDetailsService.peopleCountMonitors;
    this.peelOffMonitors = this.siteDetailsService.peelOffMonitors;
    this.occupancyMonitorsCameras = this.siteDetailsService.occupancyMonitorsCameras;
    this.peopleCountMonitorsCameras = this.siteDetailsService.peopleCountMonitorsCameras;
    this.peelOffMonitorsCameras = this.siteDetailsService.peelOffMonitorsCameras;
    this.offlineAlerts = this.siteDetailsService.offlineAlerts;
    this.summaryAlerts = this.siteDetailsService.summaryAlerts;
    this.offlineAlertCameraPositions = this.siteDetailsService.offlineAlertCameraPositions;
    this.summaryAlertCameraPositions = this.siteDetailsService.summaryAlertCameraPositions;

    this.hasOccupancyLicence = this.accountService.occupancyLicence;
    this.isSupport = this.accountService.isSupport;

    this.cameras.forEach((camera: CameraStatus) => {
      if (this.camerasService.hasIssue(camera)) {
        this.issueCameras.push(camera);
      } else if (camera.status === 'paused') {
        this.pausedCameras.push(camera);
      }
    });
  }

  getMonitorProperties(monitor: OccupancyMonitor | PeopleCountMonitor) {
    if ('capacity' in monitor) {
      return { capacity: monitor.capacity };
    } else {
      return {};
    }
  }

  openCameraSetupPopup(): void {
    const dialogRef = this.matDialog.open(CameraSetupDialogComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        sites: [...this.sites, this.siteDetailsService.site],
        siteId: this.siteId,
        offlineAlerts: this.offlineAlerts,
        summaryAlerts: this.summaryAlerts,
        offlineAlertCameraPositions: this.offlineAlertCameraPositions,
        summaryAlertCameraPositions: this.summaryAlertCameraPositions,
        cameras: this.siteDetailsService.allCameras,
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  openNewOccupancyMonitorDialog(): void {
    const dialogRef = this.matDialog.open(AddOccupancyMonitorComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        cameras: this.cameras.filter((c) => c.serialNumber && GlobalMethods.isOverheadCamera(c.serialNumber)),
        siteId: this.siteId,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  openNewPeopleCountMonitorDialog(): void {
    const dialogRef = this.matDialog.open(AddPeopleCountMonitorComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        cameras: this.cameras,
        siteId: this.siteId,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  openNewPeelOffMonitorDialog(): void {
    const dialogRef = this.matDialog.open(AddEditPeelOffMonitorComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      disableClose: true,
      data: {
        cameras: this.cameras,
        siteId: this.siteId,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  editSite(): void {
    const dialogRef = this.matDialog.open(AddEditSiteComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: { site: this.siteDetailsService.site },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  deleteSite(): void {
    const message = `You are about to delete ${this.siteDetailsService.site.name}.

    Are you sure you want to continue?`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'Delete' },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.siteService
            .deleteSite(this.siteDetailsService.site.id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (_) => {
                window.location.reload();
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }

  viewCameras(cameraId?: string): void {
    const routerLink = 'cameras' + (cameraId ? `/${cameraId}` : '');
    this.router.navigate([routerLink], { relativeTo: this.route });
  }

  viewMonitors(type): void {
    this.router.navigate([`monitors/${this.siteId}`], { queryParams: { type } });
  }

  viewSite(siteId: string): void {
    const routerLink = 'sites' + `/${siteId}`;
    this.router.navigate([routerLink]);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
