import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Site } from 'src/app/api';
import { PeopleCountMonitorStatus } from 'src/app/model/monitorStatus';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-people-count-monitor-card-dialog',
  templateUrl: './people-count-monitor-card-dialog.component.html',
  styleUrls: ['./people-count-monitor-card-dialog.component.scss'],
})
export class PeopleCountMonitorCardDialogComponent implements OnInit {
  site: Site;
  peopleCountMonitor: PeopleCountMonitorStatus;

  peopleCountMonitorDataWeeklyTimeEndDate;
  mobileBreakPoint = '(max-width: 767px)';
  readonly breakpoint$ = this.breakpointObserver.observe([this.mobileBreakPoint]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PeopleCountMonitorCardDialogComponent>,
    private breakpointObserver: BreakpointObserver,
    public reportsService: ReportsService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.peopleCountMonitor = this.data.peopleCountMonitor;

    if (this.reportsService.peopleCountMonitorDataWeeklyTime)
      this.peopleCountMonitorDataWeeklyTimeEndDate ==
        new Date(this.reportsService.peopleCountMonitorDataWeeklyTime.getTime() + 6 * 24 * 60 * 60 * 1000);

    this.breakpoint$.subscribe(() => this.breakpointChanged());
    setTimeout(() => window.dispatchEvent(new Event('resize')));
  }

  updatePeopleCountMonitorDataWeeklyTime() {
    this.peopleCountMonitorDataWeeklyTimeEndDate = new Date(
      this.reportsService.peopleCountMonitorDataWeeklyTime.getTime() + 6 * 24 * 60 * 60 * 1000,
    );

    this.reportsService.isPeopleCountDaily = false;
    this.ref.detectChanges();
  }

  private breakpointChanged(): void {
    if (!this.breakpointObserver.isMatched(this.mobileBreakPoint)) {
      this.close();
    }
  }

  close(reload?: boolean): void {
    if (reload) {
      this.dialogRef.close('reload');
    } else {
      this.dialogRef.close();
    }
  }
}
