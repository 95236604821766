import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject, filter, finalize, forkJoin, takeUntil } from 'rxjs';
import { ReportService, OccupancyMonitorService } from 'src/app/api';
import { MapsSpaceViewComponent } from '../maps-space-view/maps-space-view.component';
const options = {
  key: 'mik_yeBk0Vf0nNJtpesfu560e07e5',
  secret: 'mis_2g9ST8ZcSFb5R9fPnsvYhrX3RyRwPtDGbMGweCYKEq385431022',
  mapId: '65c0ff7430b94e3fabd5bb8c',
};
const iconUp =
  '<svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="45" fill="green" />  <polygon points="50,20 20,60 35,60 35,80 65,80 65,60 80,60" fill="white" /></svg>';
const iconDown =
  '<svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">  <circle cx="50" cy="50" r="45" fill="red" />  <polygon points="50,80 20,40 35,40 35,20 65,20 65,40 80,40" fill="white" /></svg>';
const occupancyData = [
  { spaceID: 's_fa005f3d5fada368', monitorID: '0d39e23a-69c0-461b-9918-b334ee91ca99' },
  { spaceID: 's_310f7df34a3c6853', monitorID: '6c81c0ac-fe14-11ec-8aa8-5f804f4253c8' },
  { spaceID: 's_5d2f5e3a61ba4fe4', monitorID: '72a7f489-bf25-4212-bd7b-72ac10873ea7' },
  { spaceID: 's_75d2b2934fe2495f', monitorID: '7dd6b968-f7c7-49f0-b71c-7f3d037899fd' },
  { spaceID: 's_b6adb793f5040daf', monitorID: 'b4ea7bcc-0135-11ed-a04f-4fc2b25eefb0' },
  { spaceID: 's_c33647c792604ae1', monitorID: 'ceb21604-982d-407f-a9c1-e0d8234b375d' },
  { spaceID: 's_c4bbb9b22a85cf30', monitorID: '56127770-0968-4448-a0c5-2de070c4dfa3' },
  { spaceID: 's_58172f1247075c72', monitorID: '9f7fe629-9740-45d6-b516-21f214b3b169' },
  { spaceID: 's_21b3ffc46583729a', monitorID: '538e6c71-d8eb-494b-a5a7-467fb83ab27f' },
  { spaceID: 's_4f386ba681ebb61d', monitorID: '12c3bac8-faaf-4a17-bec0-ed2692e320e8' },
  { spaceID: 's_8b3aac9b4b9dc9ef', monitorID: '0ccedd74-be97-493d-973f-ea08faa8bc05' },
  { spaceID: 's_b08905633a9d2000', monitorID: '73fef766-8e88-4dbf-9832-e9771742a5f1' },
  { spaceID: 's_ba1781eaf91646e9', monitorID: '59035717-b5ee-4466-b29a-fdc6816614df' },
  { spaceID: 's_a4fc6983c7d2bbe7', monitorID: '7ca5acb6-8123-4c97-8a03-76eadb76538c' },
  { spaceID: 's_144702bf49e8c794', monitorID: 'd1404753-3a1e-4b7b-aa4e-abcbe78f0737' },
  { spaceID: 's_0b35e438d8b666f6', monitorID: '3d9f1bfa-fe16-11ec-8aa8-5f804f4253c8' },
  { spaceID: 's_037e1a9fa2cb8331', monitorID: '153a403a-0136-11ed-a04f-4fc2b25eefb0' },
];
@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrl: './maps.component.scss',
})
export class MapsComponent implements OnInit, OnDestroy {
  mapData: any;
  mapView: any;
  selectedDay: Date;
  selectedWeek: Date;
  selectedWeekEnd: Date;
  selectedDateIsToday = true;
  useOpeningHours = false;
  isDaily = true;
  monitorsMap = {};
  monitorIDs = [];
  monitorsOccupancyDataMap = {};

  isLoadingMonitorData$ = new BehaviorSubject(true);

  @ViewChild('app', { static: false }) mapEl!: ElementRef<HTMLElement>;
  private popupElement: HTMLElement | null = null;
  private ngUnsubscribe = new Subject();

  constructor(
    private reportService: ReportService,
    private occupancyMonitorService: OccupancyMonitorService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.selectedDay = new Date();
    this.getMonitorData();
    this.fetchMap();
  }

  isToday(date): boolean {
    const today = new Date();
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    );
  }

  async fetchMap(): Promise<void> {
    try {
      const mappedin = await import('@mappedin/mappedin-js');

      this.mapData = await mappedin.getMapData(options);
      this.mapView = await mappedin.show3dMap(this.mapEl.nativeElement, this.mapData);
      this.mapView.on('click', (event) => {
        const clickedSpace = event.spaces?.[0];

        if (clickedSpace) {
          this.openSpacePopup(clickedSpace);
        }
      });
      this.isLoadingMonitorData$.pipe(filter((value) => value === false)).subscribe(() => {
        this.getMapDate();
      });
    } catch (error) {
      console.error('Error loading Mappedin map:', error);
    }
  }

  getMonitorData(): void {
    this.monitorIDs = [];
    this.monitorsMap = {};
    this.occupancyMonitorService
      .listOccupancyMonitors('d2fefee0-c594-498e-b518-7d893749c88e', undefined, undefined, 'active')
      .pipe(
        finalize(() => {
          this.isLoadingMonitorData$.next(false);
        }),
      )
      .subscribe({
        next: (monitors) => {
          const monitorCapacities: Map<string, number> = new Map(monitors.map((m) => [m.id, m.capacity]));
          const monitorName: Map<string, string> = new Map(monitors.map((m) => [m.id, m.name]));

          occupancyData.forEach(({ spaceID, monitorID }) => {
            this.monitorIDs.push(monitorID);
            this.monitorsMap[monitorID] = {
              spaceID,
              id: monitorID,
              capacity: monitorCapacities.get(monitorID),
              name: monitorName.get(monitorID),
            };
          });
        },
        error: (err) => console.error('Error fetching occupancy data:', err),
      });
  }

  selectDay() {
    this.isDaily = true;
    this.selectedDateIsToday = this.isToday(this.selectedDay);
    this.getMapDate();
  }

  selectWeek() {
    this.isDaily = false;
    this.selectedDateIsToday = false;
    this.selectedWeekEnd = new Date(this.selectedWeek.getTime() + 6 * 24 * 60 * 60 * 1000);
    this.getMapDate();
  }

  getMapDate() {
    if (!this.mapData) return;

    const requests: Observable<any>[] = [];
    if (this.isDaily) {
      if (this.selectedDateIsToday) {
        requests.push(this.reportService.createLiveOccupancyReport({ occupancyMonitorIds: this.monitorIDs }));
      } else {
        requests.push(
          this.reportService.createOccupancyReport({
            occupancyMonitorIds: this.monitorIDs,
            startDate: moment(this.selectedDay).format('YYYY-MM-DD'),
            endDate: moment(this.selectedDay).add(1, 'day').format('YYYY-MM-DD'),
            timeGrain: '24h',
            metrics: ['occupancy_avg'],
            isOpen: this.useOpeningHours,
          }),
        );
      }

      requests.push(
        this.reportService.createOccupancyReport({
          occupancyMonitorIds: this.monitorIDs,
          startDate: moment(this.selectedDay).subtract(7, 'days').format('YYYY-MM-DD'),
          endDate: moment(this.selectedDay).subtract(6, 'days').format('YYYY-MM-DD'),
          timeGrain: '24h',
          metrics: ['occupancy_avg'],
          isOpen: this.useOpeningHours,
        }),
      );
    } else {
      requests.push(
        this.reportService.createOccupancyReport({
          occupancyMonitorIds: this.monitorIDs,
          startDate: moment(this.selectedWeek).format('YYYY-MM-DD'),
          endDate: moment(this.selectedWeekEnd).add(1, 'day').format('YYYY-MM-DD'),
          timeGrain: '24h',
          metrics: ['occupancy_avg', 'occupancy_max'],
          isOpen: this.useOpeningHours,
        }),
        this.reportService.createOccupancyReport({
          occupancyMonitorIds: this.monitorIDs,
          startDate: moment(this.selectedWeek).subtract(7, 'days').format('YYYY-MM-DD'),
          endDate: moment(this.selectedWeekEnd).subtract(6, 'days').format('YYYY-MM-DD'),
          timeGrain: '24h',
          metrics: ['occupancy_avg', 'occupancy_max'],
          isOpen: this.useOpeningHours,
        }),
      );
    }
    forkJoin(requests)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => {}),
      )
      .subscribe({
        next: (reports) => {
          this.monitorsOccupancyDataMap = {};
          var monitorOccupancy: Map<string, number>;
          var monitorOccupancyPreviousWeek: Map<string, number>;
          if (this.isDaily) {
            monitorOccupancy = new Map(
              reports[0].map((r) => {
                const capacity = this.monitorsMap[r.occupancyMonitorId].capacity;
                const occupancy = this.selectedDateIsToday ? r.occupancy : r.occupancyAvg;
                const percantege = capacity && occupancy ? Number((occupancy / capacity) * 100) : 0;
                this.monitorsOccupancyDataMap[r.occupancyMonitorId] = {
                  time: this.selectedDay,
                  occupancy,
                  percantege,
                  lastWeekPercantege: 0,
                };
                return [r.occupancyMonitorId, percantege];
              }),
            );

            monitorOccupancyPreviousWeek = new Map(
              reports[1].map((r) => {
                const capacity = this.monitorsMap[r.occupancyMonitorId].capacity;
                const occupancy = r.occupancyAvg;
                const percantege = capacity && occupancy ? Number((occupancy / capacity) * 100) : 0;
                this.monitorsOccupancyDataMap[r.occupancyMonitorId].lastWeekPercantege = percantege;
                return [r.occupancyMonitorId, percantege];
              }),
            );
          } else {
            let monitorSums: { [key: string]: { sum: number; count: number } } = {};
            const monitorData = {};
            reports[0].forEach((entry) => {
              const monitorId = entry.occupancyMonitorId;
              if (!monitorSums[monitorId]) {
                monitorSums[monitorId] = { sum: 0, count: 0 };
                monitorData[monitorId] = { occupancyMax: [], occupancyAvg: [], xaxis: [] };
              }
              monitorSums[monitorId].sum += entry.occupancyAvg;
              monitorSums[monitorId].count += 1;
              monitorData[monitorId].occupancyMax.push(entry.occupancyMax ?? 0);
              monitorData[monitorId].occupancyAvg.push(entry.occupancyAvg ?? 0);
              monitorData[monitorId].xaxis.push(moment(entry.time.split('.')[0]).format());
            });

            monitorOccupancy = new Map();
            Object.keys(monitorSums).forEach((monitorId) => {
              const capacity = this.monitorsMap[monitorId].capacity;
              const occupancy = Math.round(monitorSums[monitorId].sum / monitorSums[monitorId].count);
              const percantege = capacity && occupancy ? Number((occupancy / capacity) * 100) : 0;
              monitorOccupancy.set(monitorId, percantege);

              this.monitorsOccupancyDataMap[monitorId] = {
                time: this.selectedWeek,
                weekOccupancy: monitorData[monitorId],
                percantege,
                lastWeekPercantege: 0,
              };
            });

            monitorSums = {};

            reports[1].forEach((entry) => {
              const monitorId = entry.occupancyMonitorId;
              if (!monitorSums[monitorId]) {
                monitorSums[monitorId] = { sum: 0, count: 0 };
              }
              monitorSums[monitorId].sum += entry.occupancyAvg;
              monitorSums[monitorId].count += 1;
            });

            monitorOccupancyPreviousWeek = new Map();
            Object.keys(monitorSums).forEach((monitorId) => {
              const capacity = this.monitorsMap[monitorId].capacity;
              const occupancy = Math.round(monitorSums[monitorId].sum / monitorSums[monitorId].count);
              const percantege = capacity && occupancy ? Number((occupancy / capacity) * 100) : 0;
              monitorOccupancyPreviousWeek.set(monitorId, percantege);
              this.monitorsOccupancyDataMap[monitorId].lastWeekPercantege = percantege;
            });
          }

          this.mapView.Labels.removeAll();

          this.monitorIDs.forEach((monitorId) => {
            const space = this.mapData.getById('space', this.monitorsMap[monitorId].spaceID);
            if (space && monitorOccupancy.get(monitorId) !== undefined) {
              const occupancy = monitorOccupancy.get(monitorId);
              let color: string;
              if (occupancy < 75) color = '#18c37f';
              else if (occupancy < 90) color = '#ff854d';
              else color = '#ec4152';

              this.mapView.updateState(space, {
                color: color,
                interactive: true,
                hoverColor: color,
              });
              this.mapView.Labels.add(space, `${this.monitorsMap[monitorId].name} (${Math.round(occupancy)}%)`, {
                appearance: {
                  marker: {
                    foregroundColor: { active: '#FFFFFF', inactive: '#FFFFFF' },
                    icon:
                      monitorOccupancyPreviousWeek.get(monitorId) < monitorOccupancy.get(monitorId) ? iconUp : iconDown,
                    iconSize: 36,
                  },
                  text: {
                    foregroundColor: '#000000',
                    backgroundColor: '#FFFFFF',
                    size: 14,
                    maxWidth: 200,
                  },
                },
              });
            }
          });
          this.ref.detectChanges();
        },

        error: (error) => {
          console.log(error);
        },
      });
  }

  openSpacePopup(space) {
    const monitorId = occupancyData.find((entry) => entry.spaceID === space.id).monitorID;
    const monitorReportingDate = {};
    if (this.isDaily) {
      monitorReportingDate['reportingStartDate'] = this.selectedDay;
    } else {
      monitorReportingDate['reportingStartDate'] = this.selectedWeek;
      monitorReportingDate['reportingEndDate'] = this.selectedWeekEnd;
    }
    this.dialog.open(MapsSpaceViewComponent, {
      width: '400px',
      data: {
        monitor: { ...this.monitorsMap[monitorId], ...monitorReportingDate },
        chartData: this.monitorsOccupancyDataMap[monitorId],
        image: 'https://example.com/sample-image.jpg',
      },
    });
  }

  closePopup() {
    if (this.popupElement) {
      this.popupElement.remove();
      this.popupElement = null;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
