import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { forkJoin, switchMap, Observable, finalize, takeUntil, Subject } from 'rxjs';
import {
  AlertOccupancyMonitorMappingService,
  OccupancyMonitor,
  OccupancyMonitorCameraPositionService,
  OccupancyMonitorService,
  PeelOffMonitorCameraPositionService,
  PeelOffMonitorService,
  PeopleCountMonitor,
  PeopleCountMonitorCameraPositionService,
  PeopleCountMonitorService,
} from 'src/app/api';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { AddEditPeelOffMonitorComponent } from 'src/app/components/monitors/add-edit-peel-off-monitor/add-edit-peel-off-monitor.component';
import { AddOccupancyMonitorComponent } from 'src/app/components/monitors/add-occupancy-monitor/add-occupancy-monitor.component';
import { AddPeopleCountMonitorComponent } from 'src/app/components/monitors/add-people-count-monitor/add-people-count-monitor.component';
import { compare } from 'src/app/functions/sort';
import { GlobalMethods } from 'src/app/global-methods';
import { CameraStatus } from 'src/app/model/cameraStatus';
import {
  OccupancyMonitorStatus,
  MonitorStatus,
  PeopleCountMonitorStatus,
  PeelOffMonitorStatus,
} from 'src/app/model/monitorStatus';
import { AccountService } from 'src/app/services/account.service';
import { MonitorsService } from 'src/app/services/monitors.service';
import { NotifyService } from 'src/app/services/notify.service';

const noPeopleCountMonitorText = `If you want to know the total count of people who have come into your site, or a particular space on your site, you can use a people count monitor.

The counting is bi-directional so you can report on individual or combined traffic directions.`;
const noOccupancyMonitorText = `If you want to know how many people are in your site, or in a particular space in your site - you will want to set up an occupancy monitor.
  
You can set a capacity for the monitor (this is typically the maximum number of people the space can hold) and create an occupancy alert to tell team members when a space is approaching, or has reached, its capacity.`;
const noPeelOffMonitorText = `A peel off monitor is a perfect tool for stores who want a deeper understanding of their sales traffic.

Peel off monitors count both traffic passing and customers entering your store. This allows you to understand footfall and relative peel-off conversion rates between stores as well as measuring effectiveness of marketing campaigns and window displays.`;

@Component({
  selector: 'app-monitors-info',
  templateUrl: './monitors-info.component.html',
  styleUrl: './monitors-info.component.scss',
})
export class MonitorsInfoComponent implements OnInit, OnDestroy {
  noPeopleCountMonitorText = noPeopleCountMonitorText;
  noOccupancyMonitorText = noOccupancyMonitorText;
  noPeelOffMonitorText = noPeelOffMonitorText;
  organisationName: string;
  disabledOccupancyMonitorCard: { [_: string]: boolean } = {};
  disabledPeopleCountMonitorCard: { [_: string]: boolean } = {};
  disabledPeelOffMonitorCard: { [_: string]: boolean } = {};
  occupancyMonitorsWithIssues = 0;
  peopleCountMonitorsWithIssues = 0;
  peelOffMonitorsWithIssues = 0;
  keyToSort: keyof PeelOffMonitorStatus = 'name';

  private ngUnsubscribe = new Subject();

  constructor(
    public monitorsService: MonitorsService,
    private occupancyMonitorService: OccupancyMonitorService,
    private peopleCountMonitorService: PeopleCountMonitorService,
    private peelOffMonitorService: PeelOffMonitorService,
    public accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private notifyService: NotifyService,
    private alertOccupancyMonitorMappingService: AlertOccupancyMonitorMappingService,
    private ref: ChangeDetectorRef,
    private occupancyMonitorCameraPositionService: OccupancyMonitorCameraPositionService,
    private peopleCountMonitorCameraPositionService: PeopleCountMonitorCameraPositionService,
    private peelOffMonitorCameraPositionService: PeelOffMonitorCameraPositionService,
  ) {}
  ngOnInit(): void {
    const { organisation, isSupport } = this.accountService;
    if (!isSupport) this.organisationName = organisation?.name;

    this.monitorsService.occupancyMonitors.forEach((om) => {
      if (om.status === 'issue') {
        this.occupancyMonitorsWithIssues += 1;
      }
    });

    this.monitorsService.peopleCountMonitors.forEach((pcm) => {
      if (pcm.status === 'issue') {
        this.peopleCountMonitorsWithIssues += 1;
      }
    });

    this.monitorsService.peelOffMonitors.forEach((pom) => {
      if (pom.status === 'issue') {
        this.peelOffMonitorsWithIssues += 1;
      }
    });

    const filterMonitorsFromParams = this.getQueryParam('filterMonitors') ? this.getQueryParam('filterMonitors') : '';

    this.monitorsService.mustContainString = filterMonitorsFromParams;
    this.filterMonitors(this.monitorsService.mustContainString);
  }

  getCamerasWithIssue(cameras: CameraStatus[]): number {
    return cameras.filter((c) => c.status === 'lagging' || c.status === 'missing' || c.status === 'offline').length;
  }

  groupIssueMonitorsBySite(): any[] {
    const monitorSites: { [_: string]: any[] } = {};

    this.monitorsService.occupancyMonitors.forEach((om) => {
      if (om.status === 'issue') {
        if (monitorSites[om.siteId]) {
          monitorSites[om.siteId].push(om);
        } else {
          monitorSites[om.siteId] = [om];
        }
      }
    });
    this.monitorsService.peopleCountMonitors.forEach((pcm) => {
      if (pcm.status === 'issue') {
        if (monitorSites[pcm.siteId]) {
          monitorSites[pcm.siteId].push(pcm);
        } else {
          monitorSites[pcm.siteId] = [pcm];
        }
      }
    });
    this.monitorsService.peelOffMonitors.forEach((pom) => {
      if (pom.status === 'issue') {
        if (monitorSites[pom.siteId]) {
          monitorSites[pom.siteId].push(pom);
        } else {
          monitorSites[pom.siteId] = [pom];
        }
      }
    });
    return Object.values(monitorSites);
  }

  goToMonitorPage(
    monitor: OccupancyMonitorStatus | PeopleCountMonitorStatus | PeelOffMonitorStatus,
    edit?: boolean,
  ): void {
    let path;
    if (monitor.hasOwnProperty('capacity')) {
      path = `monitors/occupancy-monitor/${monitor.id}`;
    } else if (monitor.hasOwnProperty('direction1Alias')) {
      path = `monitors/people-count-monitor/${monitor.id}`;
    } else {
      path = `monitors/peel-off-monitor/${monitor.id}`;
    }
    if (edit) {
      path += '/edit';
    }
    this.router.navigate([path]);
  }

  openNewMonitorDialog(): void {
    const selectedType = this.monitorsService.monitorsTypeValue[this.monitorsService.selectedTabIndex];
    this.openMonitorDialog(selectedType);
  }

  private openMonitorDialog(type: 'people_count' | 'peel_off' | 'occupancy'): void {
    let component: any;
    let additionalData: any = {};

    switch (type) {
      case 'people_count':
        component = AddPeopleCountMonitorComponent;
        break;
      case 'peel_off':
        component = AddEditPeelOffMonitorComponent;
        additionalData = { stepsTitle: 'Create Peel Off Monitor', disableClose: true };
        break;
      case 'occupancy':
        component = AddOccupancyMonitorComponent;
        additionalData = {
          cameras: this.monitorsService.cameras.filter(
            (c) => c.serialNumber && GlobalMethods.isOverheadCamera(c.serialNumber),
          ),
        };
        break;
    }

    const dialogRef = this.matDialog.open(component, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      disableClose: additionalData.disableClose || false,
      data: {
        cameras: this.monitorsService.cameras,
        sites: this.monitorsService.sites,
        ...additionalData,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  getQueryParam(queryParam: string): string {
    const filterQueryParam: string = this.route.snapshot.queryParams[queryParam];

    return filterQueryParam;
  }

  updateParams(filterMonitors: string, customParams?: Params) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { filterMonitors, ...customParams },
      queryParamsHandling: 'merge',
    });
  }

  onTabChanged(type) {
    switch (type) {
      case 'people_count': {
        this.updateParams(this.monitorsService.mustContainString, { type });
        break;
      }
      case 'peel_off': {
        this.updateParams(this.monitorsService.mustContainString, { type });
        break;
      }
      case 'occupancy': {
        this.updateParams(this.monitorsService.mustContainString, { type });
        break;
      }
    }
  }

  sortMonitors(): void {
    this.sortMonitorList(this.monitorsService.filteredOccupancyMonitors);
    this.sortMonitorList(this.monitorsService.filteredPeopleCountMonitors);
    this.sortMonitorList(this.monitorsService.filteredPeelOffMonitors);
  }

  private sortMonitorList(monitors): void {
    if (!monitors || monitors.length === 0) return;

    monitors.sort((m1, m2) => {
      if (this.keyToSort === 'status') {
        const statusComparison =
          Object.values(MonitorStatus.StatusEnum).indexOf(m2.status) -
          Object.values(MonitorStatus.StatusEnum).indexOf(m1.status);
        return statusComparison !== 0
          ? statusComparison
          : compare(m1, m2, { keyToSort: 'name', direction: 'ascending' });
      } else {
        return compare(m1, m2, {
          keyToSort: this.keyToSort,
          direction: 'ascending',
          thenBy: { keyToSort: 'name', direction: 'ascending' },
        });
      }
    });
  }

  filterMonitors(searchString: string): void {
    this.updateParams(searchString);
    this.monitorsService.mustContainString = searchString.toLowerCase();

    this.applyFilter(this.monitorsService.peopleCountMonitors, 'filteredPeopleCountMonitors');
    this.applyFilter(this.monitorsService.occupancyMonitors, 'filteredOccupancyMonitors');
    this.applyFilter(this.monitorsService.peelOffMonitors, 'filteredPeelOffMonitors');

    this.sortMonitors();
  }

  private applyFilter(monitors, filteredKey): void {
    this.monitorsService[filteredKey] = monitors.filter((monitor) => {
      const searchTerm = this.monitorsService.mustContainString;
      return (
        monitor.name.toLowerCase().includes(searchTerm) ||
        monitor.id.toLowerCase().includes(searchTerm) ||
        this.accountService.organisationsMap[monitor.organisationId]?.name.toLowerCase().includes(searchTerm) ||
        monitor.siteName.toLowerCase().includes(searchTerm)
      );
    });
  }

  deleteOccupancyMonitor(occupancyMonitor: OccupancyMonitor): void {
    const message = `You are about to delete ${occupancyMonitor.name}.

    Are you sure you want to continue?`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'Delete' },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.disabledOccupancyMonitorCard[occupancyMonitor.id] = true;
          this.ref.detectChanges();
          forkJoin([
            this.occupancyMonitorCameraPositionService.listOccupancyMonitorCameraPositions(
              undefined,
              undefined,
              undefined,
              occupancyMonitor.id,
              'active',
            ),
            this.alertOccupancyMonitorMappingService.listAlertOccupancyMonitorMappings(
              occupancyMonitor.id,
              undefined,
              undefined,
              undefined,
              'active',
            ),
          ])
            .pipe(
              switchMap(([occupancyMonitorCameraPositions, alertOccupancyMonitorMappings]) => {
                const requests: Observable<any>[] = [];
                occupancyMonitorCameraPositions.forEach((occupancyMonitorCameraPosition) => {
                  requests.push(
                    this.occupancyMonitorCameraPositionService.deleteOccupancyMonitorCameraPosition(
                      occupancyMonitorCameraPosition.id,
                    ),
                  );
                });
                alertOccupancyMonitorMappings.forEach((alertOccupancyMonitorMapping) => {
                  requests.push(
                    this.alertOccupancyMonitorMappingService.deleteAlertOccupancyMonitorMapping(
                      alertOccupancyMonitorMapping.id,
                    ),
                  );
                });
                if (!requests.length) {
                  return this.occupancyMonitorService.deleteOccupancyMonitor(occupancyMonitor.id);
                }
                return forkJoin(requests).pipe(
                  switchMap((_) => this.occupancyMonitorService.deleteOccupancyMonitor(occupancyMonitor.id)),
                );
              }),
              finalize(() => {
                delete this.disabledOccupancyMonitorCard[occupancyMonitor.id];
                this.ref.detectChanges();
              }),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
              next: (_) => {
                window.location.reload();
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }

  deletePeopleCountMonitor(peopleCountMonitor: PeopleCountMonitor): void {
    const message = `You are about to delete ${peopleCountMonitor.name}.

    Are you sure you want to continue?`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'Delete' },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.disabledPeopleCountMonitorCard[peopleCountMonitor.id] = true;
          this.ref.detectChanges();
          forkJoin([
            this.peopleCountMonitorCameraPositionService.listPeopleCountMonitorCameraPositions(
              undefined,
              undefined,
              undefined,
              peopleCountMonitor.id,
              'active',
            ),
          ])
            .pipe(
              switchMap(([peopleCountMonitorCameraPositions]) => {
                const requests: Observable<any>[] = [];
                peopleCountMonitorCameraPositions.forEach((peopleCountMonitorCameraPosition) => {
                  requests.push(
                    this.peopleCountMonitorCameraPositionService.deletePeopleCountMonitorCameraPosition(
                      peopleCountMonitorCameraPosition.id,
                    ),
                  );
                });
                if (!requests.length) {
                  return this.peopleCountMonitorService.deletePeopleCountMonitor(peopleCountMonitor.id);
                }
                return forkJoin(requests).pipe(
                  switchMap((_) => this.peopleCountMonitorService.deletePeopleCountMonitor(peopleCountMonitor.id)),
                );
              }),
              finalize(() => {
                delete this.disabledPeopleCountMonitorCard[peopleCountMonitor.id];
                this.ref.detectChanges();
              }),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
              next: (_) => {
                window.location.reload();
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }

  deletePeelOfftMonitor(peelOffMonitor) {
    const message = `You are about to delete ${peelOffMonitor.name}.

    Are you sure you want to continue?`;

    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'Delete' },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          this.disabledPeelOffMonitorCard[peelOffMonitor.id] = true;
          this.ref.detectChanges();
          forkJoin([
            this.peelOffMonitorCameraPositionService.listPeelOffMonitorCameraPositions(
              undefined,
              undefined,
              undefined,
              peelOffMonitor.id,
              'active',
            ),
          ])
            .pipe(
              switchMap(([peelOffMonitorCameraPositions]) => {
                const requests: Observable<any>[] = [];
                peelOffMonitorCameraPositions.forEach((peelOffMonitorCameraPosition) => {
                  requests.push(
                    this.peelOffMonitorCameraPositionService.deletePeelOffMonitorCameraPosition(
                      peelOffMonitorCameraPosition.id,
                    ),
                  );
                });
                if (!requests.length) {
                  return this.peelOffMonitorService.deletePeelOffMonitor(peelOffMonitor.id);
                }
                return forkJoin(requests).pipe(
                  switchMap((_) => this.peelOffMonitorService.deletePeelOffMonitor(peelOffMonitor.id)),
                );
              }),
              finalize(() => {
                delete this.disabledPeelOffMonitorCard[peelOffMonitor.id];
                this.ref.detectChanges();
              }),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
              next: (_) => {
                window.location.reload();
              },
              error: (error) => {
                this.notifyService.error(error);
              },
            });
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
