import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { compare } from 'src/app/functions/sort';
import { AccountService } from 'src/app/services/account.service';
import { CameraStatus } from 'src/app/model/cameraStatus';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

const initialActiveCamerasLimit = 20;
const loadNextCamerasQuantity = 10;

@Component({
  selector: 'app-cameras-list',
  templateUrl: './cameras-list.component.html',
  styleUrls: ['./cameras-list.component.scss'],
})
export class CamerasListComponent implements OnInit, OnDestroy, OnChanges {
  isSupport: boolean = false;
  organisationsMap = {};
  activeCameras: CameraStatus[];
  activeCamerasLimit = initialActiveCamerasLimit;
  viewCameraId = '';

  @Input() keyToSort: keyof CameraStatus;
  @Input() cameras: CameraStatus[];
  @Input() sitesMap = {};

  @ViewChild('camerasList') camerasList: ElementRef;

  private ngUnsubscribe = new Subject();

  constructor(
    private accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef,
  ) {
    this.isSupport = this.accountService.isSupport;
    this.organisationsMap = this.accountService.organisationsMap;
  }

  ngOnInit(): void {
    this.sortCameras(this.cameras, this.keyToSort, 'ascending');
    this.activeCameras = this.cameras.slice(0, this.activeCamerasLimit);
  }

  ngOnChanges(): void {
    this.sortCameras(this.cameras, this.keyToSort, 'ascending');
    this.activeCameras = this.cameras.slice(0, this.activeCamerasLimit);
  }

  onPageScroll(): void {
    const element = this.camerasList.nativeElement;

    const isScrolledToBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) <= 2;
    const isAllCamerasLoaded = this.activeCamerasLimit >= this.cameras.length;

    if (isScrolledToBottom && !isAllCamerasLoaded) {
      this.activeCamerasLimit += loadNextCamerasQuantity;
      this.activeCameras = this.cameras.slice(0, this.activeCamerasLimit);
    }
  }

  onSortClick(keyToSort: keyof CameraStatus, direction: 'ascending' | 'descending' = 'ascending'): void {
    const isAllCamerasLoaded = this.activeCamerasLimit >= this.cameras.length;

    if (isAllCamerasLoaded) {
      this.sortCameras(this.activeCameras, keyToSort, direction);
    } else {
      this.activeCamerasLimit = initialActiveCamerasLimit;
      this.sortCameras(this.cameras, keyToSort, direction);
      this.activeCameras = this.cameras.slice(0, this.activeCamerasLimit);
    }
  }

  sortCameras(
    cameras: CameraStatus[],
    keyToSort: keyof CameraStatus,
    direction: 'ascending' | 'descending' = 'ascending',
  ): void {
    this.keyToSort = keyToSort;

    switch (this.keyToSort) {
      case 'status': {
        cameras = cameras.sort((c1: CameraStatus, c2: CameraStatus) => {
          if (c1.status === c2.status) {
            return compare(c1, c2, {
              keyToSort: 'name',
              direction,
            });
          } else {
            const status1 =
              c1.status === 'decommissioned' ? -1 : Object.values(CameraStatus.StatusEnum).indexOf(c1.status);
            const status2 =
              c2.status === 'decommissioned' ? -1 : Object.values(CameraStatus.StatusEnum).indexOf(c2.status);
            return status1 - status2;
          }
        });
        break;
      }
      case 'videoBacklog': {
        cameras = cameras.sort((c1: CameraStatus, c2: CameraStatus) => {
          if (c1.videoBacklog === c2.videoBacklog) {
            return compare(c1, c2, {
              keyToSort: 'name',
              direction,
            });
          } else {
            return c1.videoBacklog - c2.videoBacklog;
          }
        });
        break;
      }
      case 'organisationId': {
        cameras = cameras.sort((c1: CameraStatus, c2: CameraStatus) => {
          if (this.organisationsMap[c1.organisationId].name === this.organisationsMap[c2.organisationId].name) {
            return compare(c1, c2, {
              keyToSort: 'name',
              direction,
            });
          } else {
            if (
              this.organisationsMap[c1.organisationId].name.toLowerCase() >
              this.organisationsMap[c2.organisationId].name.toLowerCase()
            ) {
              return 1;
            } else {
              return -1;
            }
          }
        });
        break;
      }
      default: {
        cameras = cameras.sort((c1: CameraStatus, c2: CameraStatus) => {
          return compare(c1, c2, {
            keyToSort: this.keyToSort,
            direction,
            thenBy: { keyToSort: 'name', direction },
          });
        });
      }
    }
    this.ref.detectChanges();
  }

  navigateToCamera(camera: CameraStatus) {
    const routerLink = 'cameras/details' + `/${camera.id}`;
    this.router.navigate([routerLink]);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
