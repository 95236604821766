import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatLicense',
})
export class FormatLicensePipe implements PipeTransform {
  licenses = {
    data_explorer: 'Data Explorer',
    occupancy_monitor: 'Occupancy monitor',
    people_count: 'People count',
    real_time: 'Real Time',
    dwell_time: 'Dwell Time',
  };

  transform(license: string): unknown {
    return this.licenses[license] ? this.licenses[license] : license;
  }
}
