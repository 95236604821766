import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  currentRoute = '';
  mobileNavigationDialogRef;
  routeMap = {
    infrastructure: { name: 'INFRASTRUCTURE', icon: 'widgets' },
    reporting: { name: 'REPORTING', icon: 'query_stats' },
    'real-time-occupancy': { name: 'REAL TIME OCCUPANCY', icon: 'stream' },
    settings: { name: 'SETTINGS', icon: 'settings' },
  };
  defaultNavigation;

  @ViewChild('mobileNavigation') mobileNavigation: TemplateRef<any>;
  constructor(
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    public accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.currentRoute = this.route.snapshot.routeConfig.path;
    this.defaultNavigation = this.accountService.userPreferances.default_navigation;
  }

  selectNavigation(): void {
    this.mobileNavigationDialogRef = this.matDialog.open(this.mobileNavigation, {
      height: '100vh',
      width: '90vw',
      maxWidth: '90vw',
      position: { left: '0' },
    });

    this.mobileNavigationDialogRef.afterClosed().subscribe({
      next: (response) => {},
    });
  }

  setDefaultNavigation(matSelectChange: MatSelectChange) {
    this.accountService.updateUserMetadata({ default_navigation: matSelectChange.value });
  }

  closeMobileNavigation(): void {
    this.mobileNavigationDialogRef?.close();
  }
}
