<div class="auth-overlay" *ngIf="isLoading$ | async; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <div class="o-view-2 c-container">
    <app-navigation></app-navigation>

    <div class="c-grid-container">
      <div class="c-section-organisation-info">
        <div class="c-section-organisation-info-title">
          <mat-icon> shield </mat-icon>
          {{ accountService.organisation.name }}
        </div>
        <div class="c-section-organisation-info-content">
          <div
            class="c-info-item has-tab"
            [ngClass]="{ 'is-selected': selectedMenuValueMapping[selectedMenuIndex] === 'overview' }"
            (click)="navigateToTab('overview')"
          >
            <mat-icon>collections_bookmark</mat-icon>
            <div class="c-info-item-content">
              <div class="c-title-info-item">Overview</div>
            </div>
          </div>
          <div
            class="c-info-item has-tab"
            [ngClass]="{ 'is-selected': selectedMenuValueMapping[selectedMenuIndex] === 'users' }"
            *ngIf="users"
            (click)="navigateToTab('users')"
          >
            <mat-icon>person</mat-icon>
            <div class="c-info-item-content">
              <div class="c-title-info-item">Users</div>
              <div>
                Admin:
                <b>{{ adminUsers.length }}</b>
                <ng-container *ngIf="accountService.organisation.maxAdminSeats">
                  up to {{ accountService.organisation.maxAdminSeats }}
                </ng-container>
              </div>
              <div>
                Member: <b>{{ memberUsers.length }}</b>
                <ng-container *ngIf="accountService.organisation.maxUserSeats">
                  up to {{ accountService.organisation.maxUserSeats - 1 }}
                </ng-container>
              </div>
            </div>
          </div>
          <div
            class="c-info-item has-tab"
            [ngClass]="{ 'is-selected': selectedMenuValueMapping[selectedMenuIndex] === 'opening_hours' }"
            *ngIf="sites.length"
            (click)="navigateToTab('opening_hours')"
          >
            <mat-icon>schedule</mat-icon>
            <div class="c-info-item-content">
              <div class="c-title-info-item">Opening hours</div>
              <div class="c-title-action-item">
                {{
                  sitesWithOpeningHours === 0
                    ? 'None set.'
                    : sitesWithOpeningHours === sites.length
                      ? 'Set for all sites'
                      : 'Set for ' + sitesWithOpeningHours + ' ' + (sitesWithOpeningHours | pluraliseWord: 'site')
                }}
              </div>
            </div>
          </div>
          <div
            class="c-info-item has-tab"
            [ngClass]="{ 'is-selected': selectedMenuValueMapping[selectedMenuIndex] === 'oauth_client' }"
            (click)="navigateToTab('oauth_client')"
          >
            <mat-icon>fingerprint</mat-icon>
            <div class="c-info-item-content">
              <div class="c-title-info-item">OAuth Clients</div>
            </div>
          </div>
        </div>
      </div>

      <div class="c-selected-tab">
        <ng-container [ngSwitch]="selectedMenuValueMapping[selectedMenuIndex]">
          <app-overview-tab *ngSwitchCase="'overview'"></app-overview-tab>
          <app-users-tab
            *ngSwitchCase="'users'"
            [users]="users"
            [sites]="sites"
            [adminUsers]="adminUsers"
            [memberUsers]="memberUsers"
          ></app-users-tab>
          <app-opening-hours-tab *ngSwitchCase="'opening_hours'" [sites]="sites"></app-opening-hours-tab>
          <app-oauth-client-tab *ngSwitchCase="'oauth_client'"></app-oauth-client-tab>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
