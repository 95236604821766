<div class="auth-overlay" *ngIf="isLoading$ | async; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>

<ng-template #notLoading>
  <div>
    <nav class="navigation">
      <ul class="o-breadcrumb">
        <li class="o-breadcrumb__item">
          <a routerLink="/infrastructure" class="is-link"> <mat-icon class="is-icon">dashboard</mat-icon></a>
        </li>
        <li class="o-breadcrumb__item">
          <a routerLink="/sites" class="is-link">Sites</a>
        </li>

        <ng-container *ngIf="isCamerasRoute; else noChildRoute">
          <li class="o-breadcrumb__item">
            <a [routerLink]="['/sites', siteDetailsService.site.id]" class="is-link">{{
              siteDetailsService.site.name
            }}</a>
          </li>

          <li class="o-breadcrumb__item">Cameras</li>
        </ng-container>
        <ng-template #noChildRoute>
          <li class="o-breadcrumb__item">{{ siteDetailsService.site.name }}</li>
        </ng-template>
      </ul>
    </nav>
    <div class="o-view is-full-height">
      <main class="o-view__main">
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
</ng-template>
