import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import * as ApexCharts from 'apexcharts';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-maps-space-view',
  templateUrl: './maps-space-view.component.html',
  styleUrl: './maps-space-view.component.scss',
})
export class MapsSpaceViewComponent implements OnInit {
  isDailyData = true;
  private chart: ElementRef;
  @ViewChild('chart') set content(content: ElementRef) {
    if (content && !this.chart) {
      this.chart = content;
      this.setChartData();
    }
  }
  constructor(
    public dialogRef: MatDialogRef<MapsSpaceViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) {}

  ngOnInit() {
    this.isDailyData = this.data.chartData.hasOwnProperty('occupancy');
  }

  setChartData() {
    if (!this.chart) {
      return;
    }

    this.removeAllChildNodes(this.chart.nativeElement);
    this.drawCharts();
  }

  private removeAllChildNodes(element: HTMLElement) {
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  private drawCharts(): void {
    var chartOptions;
    if (this.isDailyData) {
      chartOptions = this.getTodayChartOptions();
    } else {
      chartOptions = this.getChartOptions();
    }
    const chart = new ApexCharts(this.chart.nativeElement, chartOptions);
    chart.render();
  }

  private getChartOptions(): ApexCharts.ApexOptions {
    return {
      chart: {
        toolbar: { show: false },
        width: '98%',
        // height: this.viewHeight,
        zoom: { enabled: false },
      },
      series: [
        {
          name: 'Max occupancy',
          type: 'line',
          data: this.data.chartData.weekOccupancy['occupancyMax'],
        },
        {
          name: 'Avg occupancy',
          type: 'column',
          data: this.data.chartData.weekOccupancy['occupancyAvg'],
        },
      ],
      colors: [
        function ({ value, seriesIndex, dataPointIndex, w }) {
          switch (seriesIndex) {
            case 0: {
              return 'var(--color-turquoise)';
            }
            case 1: {
              return dataPointIndex == 7 ? 'var(--color-violet--light)' : 'var(--color-violet)';
            }
          }
          return '';
        },
      ],
      xaxis: {
        categories: this.data.chartData.weekOccupancy.xaxis,
        labels: {
          formatter: function (value, timestamp, opts) {
            if (!value) {
              return '';
            }
            return moment(value).isSame(new Date(), 'day') ? 'Today' : formatDate(new Date(value), 'EEE', 'en_US');
          },
        },
      },

      yaxis: [
        {
          seriesName: 'Avg occupancy',
          labels: {
            formatter: (value) => String(value),
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          title: {
            text: 'Max occupancy / Avg occupancy ',
          },
        },
      ],
      grid: {
        show: true,
      },
      stroke: { width: 2, curve: 'monotoneCubic' },
      markers: { size: 4 },
      legend: { show: false },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          borderRadiusApplication: 'end',
          columnWidth: '90%',
        },
      },
    };
  }

  private getTodayChartOptions(): ApexCharts.ApexOptions {
    const occupancy = this.data.chartData.occupancy ? this.data.chartData.occupancy : 0;
    const occupancyPercentage = this.data.monitor.capacity ? Number((occupancy / this.data.monitor.capacity) * 100) : 0;
    const series = [occupancyPercentage];

    const colors =
      occupancyPercentage < 75
        ? ['var(--color-secondary)']
        : occupancyPercentage < 90
          ? ['var(--color-orange)']
          : ['var(--color-red)'];
    colors.push('#c9ccd2');

    return {
      series,
      chart: {
        type: 'radialBar',
        offsetY: -10,
      },
      colors,
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: '11px',
              color: '#4d5050',
              offsetY: 80,
            },
            value: {
              fontSize: '33px',
              color: '#373d3f',
              formatter: function (val) {
                return String(occupancy);
              },
            },
          },
        },
      },
      stroke: {
        width: 0,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      labels: [this.atString()],
    };
  }

  atString(): string {
    return this.data.chartData.time ? moment(this.data.chartData.time).format('lll') : '';
  }

  viewMonitor(): void {
    this.router.navigate([`monitors/occupancy-monitor/${this.data.monitor.id}`]).then(() => this.close());
  }

  close(): void {
    this.dialogRef.close();
  }
}
