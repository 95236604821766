import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { filter, take } from 'rxjs';
import { Site } from 'src/app/api';
import { PeopleCountMonitorStatus } from 'src/app/model/monitorStatus';
import { ReportsService } from 'src/app/services/reports.service';
import { MOBILE_BREAKPOINT, LOAD_NEXT_MONITORS_QUANTITY } from 'src/app/constants';

@Component({
  selector: 'app-people-count-all-monitors-dialog',
  templateUrl: './people-count-all-monitors-dialog.component.html',
  styleUrls: ['./people-count-all-monitors-dialog.component.scss'],
})
export class PeopleCountAllMonitorsDialogComponent implements OnInit, AfterViewInit {
  peopleCountMonitors: PeopleCountMonitorStatus[] = [];
  filteredPeopleCountMonitorCards: PeopleCountMonitorStatus[] = [];
  filteredPeopleCountMonitorCharts: PeopleCountMonitorStatus[] = [];
  sitesMap: { [_: string]: Site } = {};

  isLoaded = false;
  searchString = '';
  peopleCountMonitorDataWeeklyTimeEndDate: Date;

  selection = new SelectionModel<string>(true, []);
  isMobile: boolean;

  activePeopleCountMonitorsLimit = LOAD_NEXT_MONITORS_QUANTITY;
  activePeopleCountMonitorsLimitMobile = LOAD_NEXT_MONITORS_QUANTITY;

  @ViewChild('monitorDetailsCardsList') monitorDetailsCardsList?: ElementRef;
  @ViewChild('monitorCardsList') monitorCardsList?: ElementRef;

  showLeftScroll = false;
  showRightScroll = false;

  constructor(
    public reportsService: ReportsService,
    private ref: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.sitesMap = this.reportsService.sitesMap;
    this.peopleCountMonitors = this.reportsService.peopleCountMonitors;

    if (this.reportsService.peopleCountMonitorDataWeeklyTime) {
      this.peopleCountMonitorDataWeeklyTimeEndDate = new Date(
        this.reportsService.peopleCountMonitorDataWeeklyTime.getTime() + 6 * 24 * 60 * 60 * 1000,
      );
    }

    this.selection.changed.subscribe(() => this.filterMonitors());

    this.breakpointObserver.observe([MOBILE_BREAKPOINT]).subscribe(() => this.breakpointChanged());
  }

  ngAfterViewInit(): void {
    this.reportsService.isLoadingPeopleCountOrderData
      .pipe(
        filter((loading) => !loading),
        take(1),
      )
      .subscribe(() => {
        this.peopleCountMonitors.slice(0, 3).forEach((monitor) => this.selection.select(monitor.id));
        this.ref.detectChanges();
        if (this.monitorDetailsCardsList && !this.isMobile) {
          this.onMonitorDetailsCardsListScroll();
        }
      });
  }

  private breakpointChanged(): void {
    this.isMobile = this.breakpointObserver.isMatched(MOBILE_BREAKPOINT);
    this.filterMonitors();
  }

  filterMonitors(searchString?: string): void {
    if (searchString !== undefined) {
      this.searchString = searchString.toLowerCase();
    }
    const filteredMonitors = this.peopleCountMonitors.filter(
      (m) =>
        m.id.includes(this.searchString) ||
        m.name?.toLowerCase().includes(this.searchString) ||
        this.sitesMap[m.siteId]?.name.toLowerCase().includes(this.searchString),
    );
    if (!this.isMobile) {
      this.filteredPeopleCountMonitorCards = filteredMonitors.slice(0, this.activePeopleCountMonitorsLimit);
      this.filteredPeopleCountMonitorCharts = this.filteredPeopleCountMonitorCards.filter((m) =>
        this.selection.isSelected(m.id),
      );
    } else {
      this.filteredPeopleCountMonitorCharts = filteredMonitors.slice(0, this.activePeopleCountMonitorsLimitMobile);
    }
  }

  onMonitorDetailsCardsListScroll(): void {
    if (!this.monitorDetailsCardsList?.nativeElement) return;
    const element = this.monitorDetailsCardsList.nativeElement;
    const isScrolledToBottom = Math.abs(element.scrollWidth - element.scrollLeft - element.clientWidth) <= 2;
    const isAllCamerasLoaded = this.activePeopleCountMonitorsLimit >= this.peopleCountMonitors.length;
    this.showLeftScroll = element.scrollLeft > 0;
    this.showRightScroll = !isScrolledToBottom || !isAllCamerasLoaded;
    if (isScrolledToBottom && !isAllCamerasLoaded) {
      this.activePeopleCountMonitorsLimit += LOAD_NEXT_MONITORS_QUANTITY;
      this.filterMonitors();
    }
  }

  scroll(direction: 'left' | 'right'): void {
    if (!this.monitorDetailsCardsList?.nativeElement) return;

    const offset = this.monitorDetailsCardsList.nativeElement.clientWidth * 0.85;
    this.monitorDetailsCardsList.nativeElement.scrollTo({
      left: this.monitorDetailsCardsList.nativeElement.scrollLeft + (direction === 'right' ? offset : -offset),
      behavior: 'smooth',
    });
  }

  onMonitorCardsListScroll(): void {
    if (!this.isMobile || !this.monitorCardsList?.nativeElement) return;
    const element = this.monitorCardsList.nativeElement;
    const isScrolledToBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) <= 2;
    const isAllCamerasLoaded = this.activePeopleCountMonitorsLimitMobile >= this.peopleCountMonitors.length;

    if (isScrolledToBottom && !isAllCamerasLoaded) {
      this.activePeopleCountMonitorsLimitMobile += LOAD_NEXT_MONITORS_QUANTITY;
      this.filterMonitors();
    }
  }

  updatePeopleCountMonitorDataWeeklyTime(): void {
    this.peopleCountMonitorDataWeeklyTimeEndDate = new Date(
      this.reportsService.peopleCountMonitorDataWeeklyTime.getTime() + 6 * 24 * 60 * 60 * 1000,
    );

    this.toggleTimingPeopleCount(false);
    this.ref.detectChanges();
  }

  toggleTimingPeopleCount(value: boolean): void {
    this.reportsService.isPeopleCountDaily = value;
    this.reportsService.getPeopleCountMonitorOrderData();
  }

  isAllSelected(): boolean {
    return this.selection.selected.length === this.peopleCountMonitors.length;
  }

  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.selection.select(...this.peopleCountMonitors.map((m) => m.id));
  }

  getSelectedIds(): string {
    return encodeURIComponent(this.selection.selected.join(','));
  }

  close(): void {
    history.back();
  }
}
