<div class="c-container">
  <div class="is-title">
    <div class="c-title">
      <div class="c-title__text">
        <mat-icon class="is-icon">dynamic_form</mat-icon>
        Control room quickstart wizard.
      </div>
      <mat-icon class="c-title__right-icon" (click)="closeWizard.emit()">close</mat-icon>
    </div>
  </div>
  <div class="is-main">
    <div class="c-main">
      <div class="c-main__title">
        <div class="is-primary-text">Get started with everything you need to start illuminating your spaces.</div>
        <div class="is-secondary-text">
          <mat-icon>schedule</mat-icon>
          25 minutes.
        </div>
      </div>
      <div class="c-main__progress">
        <a class="is-progress" [routerLink]="['/sites']" *ngIf="addedSite; else addSite">
          <div class="is-progress__title">
            <ng-container [ngTemplateOutlet]="addedResource" [ngTemplateOutletContext]="{ added: addedSite }">
            </ng-container>
            <div>Add a site</div>
          </div>
          <mat-icon class="is-progress__right-icon"> widgets </mat-icon>
        </a>
        <ng-template #addSite>
          <a class="is-progress" href="/infrastructure#add-first-site">
            <div class="is-progress__title">
              <ng-container [ngTemplateOutlet]="addedResource" [ngTemplateOutletContext]="{ added: addedSite }">
              </ng-container>
              <div>Add a site</div>
            </div>
            <mat-icon class="is-progress__right-icon"> widgets </mat-icon>
          </a>
        </ng-template>
        <a class="is-progress" [routerLink]="['/settings']">
          <div class="is-progress__title">
            <ng-container [ngTemplateOutlet]="addedResource" [ngTemplateOutletContext]="{ added: addedUser }">
            </ng-container>
            <div>Invite users</div>
          </div>
          <mat-icon class="is-progress__right-icon"> admin_panel_settings </mat-icon>
        </a>
        <a class="is-progress" [ngClass]="{ disabled: !addedSite }" href="/infrastructure#add-first-camera">
          <div class="is-progress__title">
            <ng-container [ngTemplateOutlet]="addedResource" [ngTemplateOutletContext]="{ added: addedCamera }">
            </ng-container>
            <div>Setup a camera</div>
          </div>
          <mat-icon class="is-progress__right-icon"> widgets </mat-icon>
        </a>
        <a class="is-progress" [ngClass]="{ disabled: !addedSite }" href="/infrastructure#add-first-monitor">
          <div class="is-progress__title">
            <mat-icon class="is-icon">radio_button_unchecked</mat-icon>
            <div>Create a monitor</div>
          </div>
          <mat-icon class="is-progress__right-icon"> widgets </mat-icon>
        </a>
      </div>
    </div>
    <div class="c-image">
      <img src="/assets/images/new-homepage/wizard.png" alt="" />
    </div>
  </div>
</div>

<ng-template #addedResource let-added="added">
  <mat-icon class="is-icon">{{ added ? 'check_circle' : 'radio_button_unchecked' }}</mat-icon>
</ng-template>
