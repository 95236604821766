<div class="auth-overlay" *ngIf="isLoading$ | async; else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading users...</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<ng-template #notLoading>
  <nav class="navigation">
    <ul class="o-breadcrumb">
      <li class="o-breadcrumb__item">
        <a routerLink="/infrastructure" class="is-link">
          <mat-icon class="is-icon">dashboard</mat-icon>
        </a>
      </li>
      <li class="o-breadcrumb__item">Users</li>
    </ul>
  </nav>
  <div class="o-view c-users">
    <div class="c-users__title">
      <div class="o-heading--pica">
        <h2>Users</h2>
        <ng-container *ngIf="organisation; else noOrg">
          <h3>{{ organisation.name }} - {{ users.length }} users</h3>
        </ng-container>
        <ng-template #noOrg>
          <h3>Showing {{ filteredUsers.length }} of {{ users.length }} users</h3>
        </ng-template>
      </div>
      <div class="is-action o-button has-outline" (click)="openAddEditUserDialog()" *ngIf="isSupport || isAdmin">
        <mat-icon class="is-icon-left">person_add</mat-icon>
        ADD USER
      </div>
    </div>
    <div class="c-users__filter">
      <app-filter placeholder="Filter users" (search)="runSearch($event)" class="o-search__input"></app-filter>
      <div class="o-toggle__row">
        <div class="is-legend">
          <mat-icon>people_alt</mat-icon>
          Roles
        </div>
        <div class="[ search-check is-check ] o-toggle__item">
          <input type="checkbox" id="admin-check" [(ngModel)]="showAdmin" (change)="filterUsers()" name="showAdmin" />
          <label for="admin-check">Admin</label>
        </div>
        <div class="[ search-check is-check ] o-toggle__item">
          <input
            type="checkbox"
            id="members-check"
            [(ngModel)]="showMember"
            (change)="filterUsers()"
            name="showMember"
          />
          <label for="members-check">Members</label>
        </div>
      </div>
    </div>
    <div class="c-users__users-list" [class.c-users__users-list--admin]="isAdmin">
      <div *ngIf="isAdmin" class="c-users-info-panel">
        <div class="c-users-info-panel__entry">
          <mat-icon class="c-panel-entry-icon">recent_actors</mat-icon>
          <div class="c-panel-entry-text">
            <div class="c-panel-entry-title c-panel-entry-title--all-users">
              Current users: {{ adminUsers + memberUsers }}
            </div>
            <div class="c-panel-entry-subtitle" *ngIf="adminUsersSeatsAvailable || memberUsersSeatsAvailable">
              Limit:
              {{ memberUsersSeatsAvailable }}
              {{ memberUsersSeatsAvailable | pluraliseWord: 'user' }}
              <ng-container *ngIf="adminUsersSeatsAvailable"
                >, including up to {{ adminUsersSeatsAvailable }}
                {{ adminUsersSeatsAvailable | pluraliseWord: 'admin' }}.</ng-container
              >
            </div>
          </div>
        </div>
        <div class="c-users-info-panel__entry">
          <mat-icon class="c-panel-entry-icon">manage_accounts</mat-icon>
          <div class="c-panel-entry-text">
            <div class="c-panel-entry-title">
              Admins: {{ adminUsers }}
              <ng-container
                *ngIf="adminUsers < adminUsersSeatsAvailable && adminUsers + memberUsers < memberUsersSeatsAvailable"
              >
                <span class="c-seats-label">
                  <ng-container
                    *ngIf="
                      [adminUsersSeatsAvailable - adminUsers, memberUsersSeatsAvailable - (adminUsers + memberUsers)]
                        | minMax as result
                    "
                  >
                    {{ result[0] }} admin {{ result[0] | pluraliseWord: 'seat' }} available
                  </ng-container>
                </span>
              </ng-container>
              <ng-container
                *ngIf="adminUsers >= adminUsersSeatsAvailable || adminUsers + memberUsers >= memberUsersSeatsAvailable"
              >
                <span class="c-seats-label">All seats allocated</span></ng-container
              >
            </div>
            <ng-container *ngIf="adminUsers >= adminUsersSeatsAvailable">
              <div
                class="c-panel-entry-limit-text"
                (click)="openIncreaseUserLimitDialog(adminUsersSeatsAvailable, true)"
              >
                INCREASE ADMIN USER LIMIT
                <mat-icon class="is-icon">info</mat-icon>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="c-users-info-panel__entry">
          <mat-icon class="c-panel-entry-icon">person</mat-icon>
          <div class="c-panel-entry-text">
            <div class="c-panel-entry-title">
              Members: {{ memberUsers }}
              <ng-container *ngIf="memberUsers + adminUsers < memberUsersSeatsAvailable">
                <span class="c-seats-label"
                  >{{ memberUsersSeatsAvailable - (memberUsers + adminUsers) }}
                  {{ memberUsersSeatsAvailable - (memberUsers + adminUsers) | pluraliseWord: 'seat' }} available
                </span>
              </ng-container>
              <ng-container *ngIf="memberUsers + adminUsers >= memberUsersSeatsAvailable"
                ><span class="c-seats-label">All seats allocated</span></ng-container
              >
            </div>
            <ng-container *ngIf="memberUsers >= memberUsersSeatsAvailable">
              <div class="c-panel-entry-limit-text" (click)="openIncreaseUserLimitDialog(memberUsersSeatsAvailable)">
                INCREASE USER LIMIT
                <mat-icon>info</mat-icon>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <ng-container *ngFor="let user of filteredUsers">
        <div
          class="o-user-card"
          [ngClass]="{
            'is-active': organisationsMap[user.organisationId]?.state === 'active',
            'is-disabled': organisationsMap[user.organisationId]?.state === 'disabled',
          }"
        >
          <div class="o-user-card__title">
            <div class="is-avatar">
              <mat-icon>person</mat-icon>
            </div>
            <div class="is-title-role">
              <h2>
                {{ user.name }}
              </h2>
              <h3>
                {{ formatUserRoles(user) }}
              </h3>
            </div>
            <div class="o-user-card__actions">
              <mat-icon
                class="is-action"
                (click)="openAddEditUserDialog(user)"
                matTooltip="Edit user"
                [matTooltipPosition]="'above'"
                >edit
              </mat-icon>
              <mat-icon
                class="is-action"
                (click)="impersonate(user)"
                *ngIf="canImpersonate"
                matTooltip="Log in as user"
                [matTooltipPosition]="'above'"
                >login
              </mat-icon>
            </div>
          </div>
          <div class="o-user-card__body">
            <div class="o-user-card__body-text" *ngIf="isSupport">
              <h2 class="is-org-name">
                {{ organisationsMap[user.organisationId].name }}
                <span *ngIf="organisationsMap[user.organisationId].state === 'disabled'" class="is-org-disabled"
                  >(DISABLED)</span
                >
              </h2>
            </div>
            <a>{{ user.email }}</a>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="!showAdmin && !showMember">
      <div class="c-users__users-list">
        <p>Please select a role to see associated users...</p>
      </div>
    </ng-container>
  </div>
</ng-template>
