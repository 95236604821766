<div class="auth-overlay" *ngIf="(isLoading$ | async) || (auth.isLoading$ | async); else notLoading">
  <div fxFill fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1.5625rem">
    <p style="font-size: 2rem; font-weight: 500">Loading</p>
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>

<ng-template #notLoading>
  <div class="app-container">
    <div class="c-notification">
      <div class="o-banner-alert is-error u-flush--bottom" *ngIf="showOfflineCamerasWithNoAlertsBanner">
        <div class="is-badge u-hide-mobile u-hide-tablet">
          <span class="is-reminder-button" *ngIf="reminderOfflineCamerasWithNoAlertsBanner">REMINDER</span>
          <mat-icon class="is-icon-left"> no_photography </mat-icon>
          Cameras offline
        </div>
        <div class="is-message has-flex">
          <ng-container *ngIf="!reminderOfflineCamerasWithNoAlertsBanner; else alertsReminder">
            You have
            <span class="alert-main-info">
              {{ offlineCamerasWithNoAlerts.length }}
              {{ offlineCamerasWithNoAlerts.length | pluraliseWord: 'camera' }}
              offline
            </span>
            that do not have alerts set up
          </ng-container>
          <ng-template #alertsReminder>
            <span class="is-reminder-text">
              {{ offlineCamerasWithNoAlerts.length }}
              {{ offlineCamerasWithNoAlerts.length | pluraliseWord: 'camera' }}

              {{ offlineCamerasWithNoAlerts.length === 1 ? 'has' : 'have' }} been offline for over 14 days and do not
              have alerts set up
            </span>
          </ng-template>

          <a class="o-button is-small c-flush-button" (click)="openFindOutMoreCameraAlerts()">
            Find out more
            <mat-icon class="is-icon-right">arrow_forward</mat-icon>
          </a>
        </div>
        <a
          class="is-action"
          (click)="
            closeBanner(
              'first_show_cameras_alert_date',
              'dont_show_cameras_alert',
              'showOfflineCamerasWithNoAlertsBanner'
            )
          "
        >
          <mat-icon>close</mat-icon>
        </a>
      </div>
      <div class="o-banner-alert is-error u-flush--bottom" *ngIf="showOfflineCamerasWithNoSummaryBanner">
        <div class="is-badge u-hide-mobile u-hide-tablet">
          <span class="is-reminder-button" *ngIf="reminderOfflineCamerasWithNoSummaryBanner">REMINDER</span>
          <mat-icon class="is-icon-left"> emergency </mat-icon>
          Cameras health
        </div>
        <div class="is-message has-flex">
          <ng-container *ngIf="summaryAlerts.length; else hasNoSummaries">
            <ng-container *ngIf="!reminderOfflineCamerasWithNoSummaryBanner; else summariesReminder">
              You have {{ issueCamerasWithNoSummaries.length }}
              {{ issueCamerasWithNoSummaries.length | pluraliseWord: 'camera' }} offline that
              {{ issueCamerasWithNoSummaries.length | isAre }} not included in your health summaries
            </ng-container>
            <ng-template #summariesReminder>
              <span class="is-reminder-text">
                {{ issueCamerasWithNoSummaries.length }}
                {{ issueCamerasWithNoSummaries.length | pluraliseWord: 'camera' }}
                not included in your health summaries
                {{ issueCamerasWithNoSummaries.length === 1 ? 'has' : 'have' }} been offline for over 14 days
              </span>
            </ng-template>

            <a class="o-button is-small c-flush-button" (click)="openFindOutMoreCameraSummaries()">
              Find out more
              <mat-icon class="is-icon-right">arrow_forward</mat-icon>
            </a>
          </ng-container>
          <ng-template #hasNoSummaries>
            Set up a camera health summary to keep up-to-date with downtime issues across your fleet
            <a class="o-button is-small c-flush-button" (click)="openSetupInstructionsForCameraSummaries()">
              Find out more
              <mat-icon class="is-icon-right">arrow_forward</mat-icon>
            </a>
          </ng-template>
        </div>
        <a
          class="is-action"
          (click)="
            closeBanner(
              'first_show_summaries_alert_date',
              'dont_show_cameras_summaries',
              'showOfflineCamerasWithNoSummaryBanner'
            )
          "
        >
          <mat-icon>close</mat-icon>
        </a>
      </div>
    </div>

    <header class="c-header">
      <a routerLink="/" class="c-header__logo">
        <img class="is-logo" src="/assets/images/logo_hoxtonai.svg" alt="Hoxton AI" />
      </a>
      <div class="c-header__actions" *ngIf="auth.isAuthenticated$ | async">
        <div class="c-header__impersonation" *ngIf="impersonated">
          <h2 class="is-primary-text">Logged in as</h2>
          <h3 class="is-secondary-text">{{ loggedInAs.name }}</h3>
        </div>
        <ng-container *ngIf="user">
          <a class="is-account" [matMenuTriggerFor]="menu">
            <ng-container *ngIf="!impersonated; else impersonationIcon">
              <img style="border-radius: 50%" ngSrc="{{ user.picture }}" width="40" height="40" alt="" />
            </ng-container>
            <ng-template #impersonationIcon>
              <mat-icon>people</mat-icon>
            </ng-template>
          </a>
          <mat-menu #menu="matMenu" class="user-menu">
            <div mat-menu-item class="disabled">
              <label>{{ user.name }}</label>
            </div>
            <button mat-menu-item [matMenuTriggerFor]="theme">
              <span>Theme <small class="c-beta">BETA</small></span>
            </button>
            <button mat-menu-item (click)="logout()" class="is-logout">
              <mat-icon>exit_to_app</mat-icon>
              <span>Logout</span>
            </button>
          </mat-menu>
          <mat-menu #theme="matMenu" class="user-menu">
            <button
              mat-menu-item
              (click)="changeTheme('light')"
              [ngClass]="{ 'is-selected': selectedTheme === 'light' }"
            >
              <mat-icon>light_mode</mat-icon>
              <span>light</span>
            </button>
            <button mat-menu-item (click)="changeTheme('dark')" [ngClass]="{ 'is-selected': selectedTheme === 'dark' }">
              <mat-icon>dark_mode</mat-icon>
              <span>dark</span>
            </button>
            <button
              mat-menu-item
              (click)="changeTheme('system')"
              [ngClass]="{ 'is-selected': selectedTheme === 'system' }"
            >
              <mat-icon>settings_suggest</mat-icon>
              <span>system</span>
            </button>
          </mat-menu>
        </ng-container>
        <a class="o-button is-primary is-small" (click)="openNavigationRightSide()">
          Menu
          <mat-icon class="is-icon-right has-no-animation">dehaze</mat-icon>
        </a>
      </div>
    </header>
    <main class="c-main">
      <router-outlet></router-outlet>
    </main>
  </div>
</ng-template>
