import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { GetObjectCommand, ListObjectsV2Command, S3Client } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { DownloadFileService } from 'src/app/services/download-file.service';

interface FeedbackEntry {
  id: string;
  time: string;
  language: string;
  transcript: string;
  audioUrl: string;
}

@Component({
  selector: 'app-download-feedbacks',
  templateUrl: './download-feedbacks.component.html',
  styleUrl: './download-feedbacks.component.scss',
})
export class DownloadFeedbacksComponent implements OnInit {
  gettingData;
  loading = false;

  feedbackControls = new FormGroup({
    uuid: new FormControl(''),
    from: new FormControl(new Date()),
    to: new FormControl(new Date()),
  });

  tableDataSource = new MatTableDataSource<FeedbackEntry>([]);
  tableHeaderNames = ['select', 'time', 'language', 'transcript', 'audio'];
  selection = new SelectionModel<string>(true, []);

  private s3: S3Client;
  private bucketName = 'mercury.hoxton.ai';
  @ViewChild('tablePaginator') set paginator(pager: MatPaginator) {
    if (pager) {
      this.tableDataSource.paginator = pager;
    }
  }
  constructor(private df: DownloadFileService) {}

  ngOnInit() {
    this.s3 = new S3Client({
      region: 'eu-west-1',
      credentials: {
        accessKeyId: 'AKIA44UGFX4KGB5GTXFV',
        secretAccessKey: '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',
      },
    });
  }

  async getFeedback() {
    this.gettingData = true;

    try {
      const uuidRaw = this.feedbackControls.value.uuid || '';
      const deviceId = uuidRaw.trim().replace(/-/g, '');
      const prefix = `orpheus/audio/device=${deviceId}/`;

      const listCommand = new ListObjectsV2Command({
        Bucket: this.bucketName,
        Prefix: prefix,
      });

      const response = await this.s3.send(listCommand);
      const objects = (response.Contents || []).filter((obj) => obj.Key?.endsWith('_transcript.json'));
      const from = new Date(this.feedbackControls.value.from || '');
      const to = new Date(this.feedbackControls.value.to || '');

      const entries: FeedbackEntry[] = [];

      for (const obj of objects) {
        if (!obj.Key) continue;

        const modified = new Date(obj.LastModified || '');
        if (modified < from || modified > to) continue;

        const transcriptData = await this.fetchJsonFile(obj.Key);
        const audioKey = obj.Key.replace('_transcript.json', '.webm');
        const audioUrl = await this.getSignedUrl(audioKey);

        const timeMatch = obj.Key.match(/(\d{4}-\d{2}-\d{2}T[\d:.]+)Z/);
        const time = timeMatch ? timeMatch[1] : 'Unknown';
        const id = obj.ETag;
        entries.push({
          id,
          time,
          language: transcriptData.language || 'N/A',
          transcript: transcriptData.text || 'N/A',
          audioUrl,
        });
      }

      this.tableDataSource.data = entries;
    } catch (err) {
      console.error(err);
    } finally {
      this.gettingData = false;
    }
  }

  async fetchJsonFile(key: string): Promise<any> {
    const command = new GetObjectCommand({
      Bucket: this.bucketName,
      Key: key,
    });

    const response = await this.s3.send(command);
    const stream = response.Body as ReadableStream;
    const text = await new Response(stream).text();
    return JSON.parse(text);
  }

  async getSignedUrl(key: string): Promise<string> {
    const command = new GetObjectCommand({
      Bucket: this.bucketName,
      Key: key,
    });
    return getSignedUrl(this.s3, command, { expiresIn: 300 }); // 5 mins
  }

  runSearch(search: string): void {
    this.tableDataSource.filter = search.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.tableDataSource.data.forEach((row) => {
          this.selection.select(row.id);
        });
  }

  downloadCSV(): void {
    const selectedIds = this.selection.selected;
    const selectedData = this.tableDataSource.data.filter((row) => selectedIds.includes(row.id));

    if (!selectedData.length) return;

    const csvRows = selectedData.map((row) => {
      return {
        Time: row.time,
        Language: row.language,
        Transcript: row.transcript.replace(/[\r\n]+/g, ' '),
      };
    });
    this.df.downloadDataAsCSVFile(csvRows, 'feedback.csv', ['Time', 'Language', 'Transcript']);
  }
}
