<div class="c-options">
  <div class="c-options__uuid">
    <div class="c-options__title">
      <mat-icon class="is-icon">smart_display</mat-icon>
      <h2 class="is-text">Device</h2>
    </div>
    <mat-form-field [formGroup]="feedbackControls">
      <input matInput type="text" placeholder="Device UUID" autocomplete="off" formControlName="uuid" />
    </mat-form-field>
  </div>
  <div class="c-options__dates">
    <div class="c-options__title">
      <mat-icon class="is-icon">calendar_today</mat-icon>
      <h2 class="is-text">Date range</h2>
    </div>
    <div class="c-options__dates-field">
      <mat-form-field [formGroup]="feedbackControls" class="is-date-input" disabled>
        <mat-label>From start of</mat-label>
        <input matInput [matDatepicker]="pickerFrom" formControlName="from" readonly />
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field [formGroup]="feedbackControls" class="is-date-input">
        <mat-label>To end of</mat-label>
        <input matInput [matDatepicker]="pickerTo" [min]="feedbackControls.value.from" formControlName="to" readonly />
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="c-options__actions">
    <button
      class="o-button is-primary is-small"
      [class.spinner]="gettingData"
      (click)="getFeedback()"
      [disabled]="gettingData"
    >
      <mat-icon class="is-icon-left">file_download</mat-icon>
      get feedback
    </button>
  </div>

  <div class="c-options__download">
    <button class="o-button is-primary is-small" (click)="downloadCSV()">
      <mat-icon class="is-icon-left">file_download</mat-icon>
      Download feedback
    </button>
  </div>
  <div class="c-options__search">
    <div class="c-search__field">
      <app-filter placeholder="Filter monitors" (search)="runSearch($event)" class="o-search__input"></app-filter>
    </div>
  </div>
  <div class="c-options__pagination">
    <mat-paginator #tablePaginator [pageSizeOptions]="[20, 50, 100]" class="c-pagination"></mat-paginator>
  </div>
</div>

<table mat-table [dataSource]="tableDataSource" class="c-table" *ngIf="tableDataSource.data.length">
  <tr mat-header-row *matHeaderRowDef="tableHeaderNames" class="c-table__header"></tr>
  <tr mat-row *matRowDef="let row; columns: tableHeaderNames" class="c-table__row"></tr>

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef (click)="masterToggle()">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="masterToggle()"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row" (click)="selection.toggle(row.id)" class="check-column">
      <mat-checkbox [checked]="selection.isSelected(row.id)" [disabled]="row.disabled === 'true'"> </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef class="c-table__title">Time</th>
    <td mat-cell *matCellDef="let row">{{ row.time }}</td>
  </ng-container>

  <ng-container matColumnDef="language">
    <th mat-header-cell *matHeaderCellDef class="c-table__title">Language</th>
    <td mat-cell *matCellDef="let row">{{ row.language }}</td>
  </ng-container>

  <ng-container matColumnDef="transcript">
    <th mat-header-cell *matHeaderCellDef class="c-table__title">Transcript</th>
    <td mat-cell *matCellDef="let row">{{ row.transcript }}</td>
  </ng-container>

  <ng-container matColumnDef="audio">
    <th mat-header-cell *matHeaderCellDef class="c-table__title">Audio</th>
    <td mat-cell *matCellDef="let row">
      <a [href]="row.audioUrl" target="_blank" rel="noopener noreferrer">Play</a>
    </td>
  </ng-container>
</table>
