import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, finalize, forkJoin, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import {
  Alert,
  AlertNotificationGroupMapping,
  AlertNotificationGroupMappingService,
  AlertOccupancyMonitorMapping,
  AlertOccupancyMonitorMappingService,
  AlertService,
  OccupancyMonitor,
  OccupancyMonitorService,
  Site,
  SiteService,
} from 'src/app/api';
import { UserConfirmationComponent } from 'src/app/components/general/user-confirmation/user-confirmation.component';
import { AddMonitorAlertComponent } from 'src/app/components/notifications/add-monitor-alert/add-monitor-alert.component';
import { EditOccupancyAlertComponent } from 'src/app/components/notifications/edit-occupancy-alert/edit-occupancy-alert.component';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-monitor-alerts',
  templateUrl: './monitor-alerts.component.html',
  styleUrls: ['./monitor-alerts.component.scss'],
})
export class MonitorAlertsComponent implements OnInit, OnDestroy {
  isLoading$ = new BehaviorSubject<boolean>(true);
  occupancyAlertMonitors: { [_: string]: OccupancyMonitor[] } = {};
  alertNotificationGroupMappings: { [_: string]: AlertNotificationGroupMapping[] } = {};
  alertOccupancyMonitorMappings: { [_: string]: AlertOccupancyMonitorMapping[] } = {};
  occupancyAlerts: Alert[] = [];
  occupancyMonitors: OccupancyMonitor[] = [];
  sitesMap: { [_: string]: Site } = {};

  ngUnsubscribe = new Subject();
  constructor(
    private matDialog: MatDialog,
    private alertService: AlertService,
    private notificationsService: NotificationsService,
    private occupancyMonitorService: OccupancyMonitorService,
    private alertOccupancyMonitorMappingService: AlertOccupancyMonitorMappingService,
    private alertNotificationGroupMappingService: AlertNotificationGroupMappingService,
    private siteService: SiteService,
    private notifyService: NotifyService,
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.isLoading$.next(true);
    forkJoin([
      this.occupancyMonitorService.listOccupancyMonitors(undefined, undefined, undefined, 'active'),
      this.alertService.listAlerts('occupancy_alert', undefined, undefined, undefined, undefined, undefined, 'active'),
      this.alertOccupancyMonitorMappingService.listAlertOccupancyMonitorMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.alertNotificationGroupMappingService.listAlertNotificationGroupMappings(
        undefined,
        undefined,
        undefined,
        undefined,
        'active',
      ),
      this.siteService.listSites(undefined, undefined, 'active'),
    ])
      .pipe(
        finalize(() => {
          this.isLoading$.next(false);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([
          occupancyMonitors,
          occupancyAlerts,
          alertOccupancyMonitorMappings,
          alertNotificationGroupMappings,
          sites,
        ]) => {
          this.occupancyMonitors = occupancyMonitors;
          const occupancyMonitorsMap = {};
          occupancyMonitors.forEach((occupancyMonitor: OccupancyMonitor) => {
            occupancyMonitorsMap[occupancyMonitor.id] = occupancyMonitor;
          });

          sites.forEach((site) => (this.sitesMap[site.id] = site));

          this.occupancyAlerts = occupancyAlerts;
          occupancyAlerts.forEach((occupancyAlert: Alert) => {
            this.occupancyAlertMonitors[occupancyAlert.id] = [];
            this.alertOccupancyMonitorMappings[occupancyAlert.id] = [];
            this.alertNotificationGroupMappings[occupancyAlert.id] = [];
          });

          alertOccupancyMonitorMappings.forEach((alertOccupancyMonitorMapping: AlertOccupancyMonitorMapping) => {
            this.occupancyAlertMonitors[alertOccupancyMonitorMapping.alertId].push(
              occupancyMonitorsMap[alertOccupancyMonitorMapping.occupancyMonitorId],
            );
            this.alertOccupancyMonitorMappings[alertOccupancyMonitorMapping.alertId].push(alertOccupancyMonitorMapping);
          });

          occupancyAlerts.forEach((occupancyAlert: Alert) => {
            if (!occupancyAlert.siteId) {
              this.occupancyAlertMonitors[occupancyAlert.id] = this.occupancyMonitors.filter(
                (om) => this.sitesMap[om.siteId].organisationId === occupancyAlert.organisationId,
              );
            }
            if (occupancyAlert.siteId && !this.alertOccupancyMonitorMappings[occupancyAlert.id].length) {
              this.occupancyAlertMonitors[occupancyAlert.id] = this.occupancyMonitors.filter(
                (om) => om.siteId === occupancyAlert.siteId,
              );
            }
          });

          alertNotificationGroupMappings.forEach((alertNotificationGroupMapping: AlertNotificationGroupMapping) => {
            this.alertNotificationGroupMappings[alertNotificationGroupMapping.alertId]?.push(
              alertNotificationGroupMapping,
            );
          });
        },
        error: (error) => {
          this.notifyService.error(error);
        },
      });
  }

  openNewAlertDialog(): void {
    const dialogRef = this.matDialog.open(AddMonitorAlertComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        sites: this.notificationsService.sites,
        occupancyMonitors: this.occupancyMonitors,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  openEditOccupancyAlertDialog(occupancyAlert: Alert): void {
    const dialogRef = this.matDialog.open(EditOccupancyAlertComponent, {
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        occupancyAlert,
        occupancyAlertRuleMonitors: this.occupancyAlertMonitors[occupancyAlert.id],
        alertOccupancyMonitorMappings: this.alertOccupancyMonitorMappings[occupancyAlert.id],
        occupancyMonitors: this.occupancyMonitors.filter(
          (occupancyMonitor) => this.sitesMap[occupancyAlert.siteId]?.id === occupancyMonitor.siteId,
        ),
        site: this.sitesMap[occupancyAlert.siteId],
        alertNotificationGroupMappings: this.alertNotificationGroupMappings[occupancyAlert.id],
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'reload') {
          window.location.reload();
        }
      },
    });
  }

  deleteOccupancyAlert(occupancyAlert: Alert) {
    const message = `Are you sure you want to delete the occupancy alert "${occupancyAlert.name}"?`;
    const dialogRef = this.matDialog.open(UserConfirmationComponent, {
      data: { message, buttonText: 'delete', isDelete: true },
    });
    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === 'confirm') {
          const requests: Observable<any>[] = [];
          this.alertOccupancyMonitorMappings[occupancyAlert.id].forEach(
            (alertOccupancyMonitorMapping: AlertOccupancyMonitorMapping) => {
              requests.push(
                this.alertOccupancyMonitorMappingService.deleteAlertOccupancyMonitorMapping(
                  alertOccupancyMonitorMapping.id,
                ),
              );
            },
          );
          this.alertNotificationGroupMappings[occupancyAlert.id].forEach((alertNotificationGroupMapping) => {
            requests.push(
              this.alertNotificationGroupMappingService.deleteAlertNotificationGroupMapping(
                alertNotificationGroupMapping.id,
              ),
            );
          });

          if (requests.length) {
            forkJoin(requests)
              .pipe(
                switchMap((_) => this.alertService.deleteAlert(occupancyAlert.id)),
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe({
                next: (_) => {
                  this.getData();
                },
                error: (error) => {
                  this.notifyService.error(error);
                },
              });
          } else {
            this.alertService
              .deleteAlert(occupancyAlert.id)
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe({
                next: (_) => {
                  this.getData();
                },
                error: (error) => {
                  this.notifyService.error(error);
                },
              });
          }
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }
}
