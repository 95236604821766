<div class="o-router-full-page-wrapper">
  <router-outlet></router-outlet>
</div>

<ng-container *ngIf="cameras.length === 0; else siteCameras">
  <h1>No cameras are linked to this site</h1>
</ng-container>
<ng-template #siteCameras>
  <ng-container *ngIf="inUseCameras.length !== 0">
    <div class="o-heading--pica">
      <h2>Cameras used in monitors</h2>
    </div>
    <app-cameras-list [cameras]="inUseCameras" [sitesMap]="sitesMap" [keyToSort]="'status'"></app-cameras-list>
  </ng-container>
  <ng-container *ngIf="pendingCameras.length !== 0">
    <div class="o-heading--pica">
      <h2>Cameras not used in monitors</h2>
    </div>
    <app-cameras-list [cameras]="pendingCameras" [sitesMap]="sitesMap" [keyToSort]="'status'"></app-cameras-list>
  </ng-container>
</ng-template>
